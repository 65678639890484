import * as yup from "yup";

import { BuyerLeadAgencyField } from "../../library/form";
import FileUploadsField from "../../library/form/FileUploadsField";
import LabeledInputField from "../../library/form/LabeledInputField";
import type { FormFieldProps } from "../../library/form/types";
import { FileUploadSource } from "../../utils/enums";

export const FILE_UPLOADS_FIELDS = [
  {
    name: "contractFiles",
    component: FileUploadsField,
    validate: yup.array().min(1, "At least one contract file is required."),
    source: FileUploadSource.SUPPLIER_CONTRACT_UPLOAD_PAGE,
  },
];

export const CONTRACT_METADATA_FIELDS: FormFieldProps[] = [
  {
    name: "contractNumber",
    label: "Contract number",
    sublabel:
      "Contract numbers are typically found on the first page of the contract near the top",
    component: LabeledInputField,
    validate: yup.string(),
  },
  {
    name: "buyerLeadAgency",
    label: "Lead Agency",
    sublabel:
      "The public entity you originally did business with through this contract",
    component: BuyerLeadAgencyField,
    validate: yup.object().shape(
      {
        governmentAffiliationDisplayName: yup.string(),
      },
      [["governmentAgencyId", "governmentAffiliationDisplayName"]]
    ),
  },
  {
    name: "expirationDate",
    label: "Expiration date",
    component: LabeledInputField,
    type: "date",
    validate: yup.date(),
  },
  {
    name: "contractTitle",
    label: "Contract title",
    sublabel: "",
    component: LabeledInputField,
    validate: yup.string(),
  },
];
