import { createRoot } from "react-dom/client";

import ContractSearch from "../components/ContractSearch/ContractSearch";
import { getDOMAnchorById } from "../utils";
import { PageType, SearchSource } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

export default function ContractSearchPage({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: AuthPageProps) {
  return (
    <Page
      pageType={PageType.CONTRACT_SEARCH}
      searchSource={SearchSource.CONTRACTS_SERP_SEARCH}
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <ContractSearch />
    </Page>
  );
}

const container = getDOMAnchorById("custom-contract-search");
if (container) {
  const root = createRoot(container);
  root.render(<ContractSearchPage />);
}
