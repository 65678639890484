import clsx from "clsx";
import { useAtomValue } from "jotai";
import { QuoteRequestFormCard } from "../../components/QuoteRequest/QuoteRequestCard";
import QuoteRequestRepsOnline from "../../components/QuoteRequest/QuoteRequestRepsOnline";
import useLoginWall from "../../hooks/useLoginWall";
import { contractSearchParamsState, requestIDState } from "../../jotai/search";
import { isAuthenticatedState } from "../../jotai/user";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../library";
import { goToURL } from "../../utils";
import { elevationClass } from "../../utils/designTokens";
import { LoginWallTriggers } from "../../utils/enums";
import { trackQuoteRequestSidebarCtaClick } from "../../utils/tracking";

export default function QuotingServiceSidebar() {
  // Known limitation: frontend-only filters (e.g. expiration date)
  // will not be stored in Jotai state and will not be included in the URL
  const searchParams = useAtomValue(contractSearchParamsState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const requestID = useAtomValue(requestIDState);
  const requireLogin = useLoginWall();

  const urlParams = new URLSearchParams();
  Object.entries(searchParams)
    .filter(([, value]) => !!value)
    .forEach(([key, value]) => urlParams.append(key, value));

  if (isAuthenticated) {
    return <QuoteRequestFormCard />;
  }

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-5 w-full md:max-w-108 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader text-center"
      >
        Get 3 quotes in 48 hours
      </Typography>
      <Typography color="neutral.boldest.enabled">
        Tell us what you need and your concierge will connect you with qualified
        suppliers.
      </Typography>
      <Button
        className="analytics-quote-request-sidebar-cta"
        size={Button.sizes.LARGE}
        onClick={() => {
          requireLogin({
            title: "Create your account to get started",
            subtitle: "We'll email you the best suppliers for your request.",
            triggerType:
              LoginWallTriggers.CONTRACT_SEARCH_QUOTE_REQUEST_SIDEBAR,
            onComplete: () => {
              trackQuoteRequestSidebarCtaClick({
                searchQuery: searchParams.query,
                requestID,
              });
              goToURL(`/quote-request?${urlParams.toString()}`);
            },
          });
        }}
      >
        {isAuthenticated ? "Get started" : "Log in to get concierge support"}
      </Button>
      <QuoteRequestRepsOnline />
    </CardContainer>
  );
}
