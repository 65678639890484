import { type FormikHelpers, useFormikContext } from "formik";
import type { Getter, Setter } from "jotai";
import { useCallback } from "react";

import { useAtomCallback } from "jotai/utils";
import {
  ApiService,
  type CostCalculatorSubmission,
  type CostCalculatorSubmissionRequest,
} from "../../generated";
import {
  costCalculatorSubmissionAtom,
  costCalculatorSubmissionIsNewAtom,
} from "../../jotai/costCalculator";
import { trackCostCalculatorSubmission } from "../../utils/tracking";
import type { CostCalculatorFormValues } from "./constants";
import { staffHoursDefaults } from "./helpers";
import { useIsComplexPurchaseCallback } from "./useIsComplexPurchase";

export function useOnSubmitCostCalculatorCallback() {
  const isComplexPurchase = useIsComplexPurchaseCallback();
  const onSubmit = useCallback(
    async (
      get: Getter,
      set: Setter,
      values: CostCalculatorFormValues,
      { setFieldValue }: FormikHelpers<CostCalculatorFormValues>
    ) => {
      let piggybackDiscountPercent = values.piggybackDiscountMarkup / 100;
      if (!values.piggybackIsDiscount) piggybackDiscountPercent *= -1;

      const isComplex = isComplexPurchase({
        purchaseCategory: values.purchaseCategory,
        purchaseRequiresApproval: values.purchaseRequiresApproval,
        purchaseCost: values.purchaseCost,
      });
      const defaults = staffHoursDefaults(isComplex);
      if (!values.solicitationStaffHours) {
        setFieldValue(
          "solicitationStaffHours",
          defaults.solicitationStaffHours
        );
      }
      if (!values.piggybackStaffHours) {
        setFieldValue("piggybackStaffHours", defaults.piggybackStaffHours);
      }

      const payload: CostCalculatorSubmissionRequest = {
        purchaseDescription: values.purchaseDescription,
        purchaseCategory: values.purchaseCategory,
        purchaseRequiresApproval: values.purchaseRequiresApproval,
        purchaserRole:
          values.purchaserRole === "default" ? null : values.purchaserRole,
        purchaseCostCents: values.purchaseCost * 100,
        purchaserRoleSalaryCents: values.purchaserRoleSalary
          ? values.purchaserRoleSalary * 100
          : null,
        solicitationStaffHours:
          values.solicitationStaffHours || defaults.solicitationStaffHours,
        solicitationDiscountPercent: values.solicitationDiscount / 100,
        piggybackStaffHours:
          values.piggybackStaffHours || defaults.piggybackStaffHours,
        piggybackDiscountPercent,
      };

      const isNew = get(costCalculatorSubmissionIsNewAtom);
      const { id } = get(costCalculatorSubmissionAtom);
      try {
        let response: CostCalculatorSubmission;
        if (isNew) {
          response =
            await ApiService.apiV1CostCalculatorSubmissionsCreate(payload);
        } else {
          response = await ApiService.apiV1CostCalculatorSubmissionsUpdate(
            id,
            payload
          );
        }
        trackCostCalculatorSubmission(response);
        set(costCalculatorSubmissionAtom, response);
      } catch {}
    },
    [isComplexPurchase]
  );

  return useAtomCallback(onSubmit);
}

export function useOnBlurCostCalculatorCallback() {
  const context = useFormikContext<CostCalculatorFormValues>();
  const onBlur = useCallback(
    (get: Getter, _set: Setter, e: FocusEvent) => {
      context.handleBlur(e);

      const { id } = get(costCalculatorSubmissionAtom);
      // Only submit on blur after the first submission.
      if (id === -1) return;
      context.handleSubmit();
    },
    [context.handleBlur, context.handleSubmit]
  );

  return useAtomCallback(onBlur);
}

export function useOnChangeCostCalculatorCallback() {
  const context = useFormikContext<CostCalculatorFormValues>();
  const onChange = useCallback(
    (get: Getter, _set: Setter) => {
      const isNew = get(costCalculatorSubmissionIsNewAtom);
      // Only submit on blur after the first submission.
      if (isNew) return;
      context.handleSubmit();
    },
    [context.handleSubmit]
  );

  return useAtomCallback(onChange);
}
