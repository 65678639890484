import { useHydrateAtoms } from "jotai/utils";
import { createRoot } from "react-dom/client";
import WelcomePage from "../components/WelcomePage";
import type { SupplierAgreement } from "../components/WelcomePage/types";
import type { ApprovalStatusEnum, BuyerProfileTypeEnum } from "../generated";
import { buyerWelcomePageState } from "../jotai/welcome";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

interface WelcomeProps extends AuthPageProps {
  num_agency_contracts: Maybe<number>;
  num_team_members: Maybe<number>;
  recent_searches: Maybe<string[]>;
  first_name: Maybe<string>;
  buyer_type: Maybe<BuyerProfileTypeEnum>;
  expired_agreements: Maybe<SupplierAgreement[]>;
  email_verified: boolean;
  supplier_approval_status: ApprovalStatusEnum;
  num_supplier_active_shareable_contracts: Maybe<number>;
  supplier_logo_url: Maybe<string>;
  isSSR: boolean;
}

export default function Welcome({
  num_agency_contracts,
  num_team_members,
  recent_searches,
  expired_agreements,
  email_verified,
  supplier_approval_status,
  num_supplier_active_shareable_contracts,
  supplier_logo_url,
  profile_type,
  is_authenticated,
  active_agreements,
  context,
  isSSR = true,
}: WelcomeProps) {
  useHydrateAtoms(
    [
      [
        buyerWelcomePageState,
        {
          numContracts: num_agency_contracts || 0,
          numTeamMembers: num_team_members || 0,
          recentSearches: recent_searches || [],
        },
      ],
    ],
    { dangerouslyForceHydrate: isSSR }
  );

  return (
    <Page
      pageId=""
      pageType={PageType.PROFILE}
      searchSource="profile-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      isSSR={isSSR}
      context={context}
    >
      <WelcomePage
        expiredAgreements={expired_agreements}
        emailVerified={email_verified}
        supplierApprovalStatus={supplier_approval_status}
        numSupplierActiveShareableContracts={
          num_supplier_active_shareable_contracts
        }
        supplierLogoUrl={supplier_logo_url}
      />
    </Page>
  );
}

const container = getDOMAnchorById("welcome");
if (container) {
  const props = JSON.parse(container.dataset.welcomeData as string);
  const root = createRoot(container);
  root.render(<Welcome {...props} isSSR={false} />);
}
