import publicEntity from "../../img/home/statistics/public-entity.svg";

interface PublicEntityImageProps {
  className?: string;
}
export default function PublicEntityImage({
  className,
  ...rest
}: PublicEntityImageProps) {
  return (
    <img
      src={publicEntity}
      className={className}
      alt="Government building with a heart"
      {...rest}
    />
  );
}
