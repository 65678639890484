import cloudFiles from "../../../img/icons/contracts-cloud.svg";
import { Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";

interface AddContractHelpSuccessModalProps {
  hideModal: () => void;
}
export default function GetHelpSuccessModal({
  hideModal,
}: AddContractHelpSuccessModalProps) {
  return (
    <Modal
      title="Thanks for your interest!"
      hideModal={hideModal}
      modalSize={modalSizes.SMALL}
      showLogoHeader={false}
      className="analytics-get-help-success-modal"
    >
      <div className="flex flex-col items-center gap-8">
        <img
          src={cloudFiles}
          className="w-[192px] h-[112px]"
          alt="Uploading contracts"
        />
        <Typography>
          Adding contracts here will help you stay ahead of expiring contracts.
          Our team will reach back out to you to help you add contracts.
        </Typography>
      </div>
    </Modal>
  );
}
