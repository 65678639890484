import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { ApiService } from "../generated";
import { governmentAgencyIdState } from "./user";

export const buyerWelcomePageState = atom<{
  numContracts: number;
  numTeamMembers: number;
  recentSearches: string[];
}>({
  numContracts: 0,
  numTeamMembers: 0,
  recentSearches: [],
});

const asyncEntityAnalyticsAtom = atom(async (get) => {
  const governmentAgencyId = get(governmentAgencyIdState);
  if (!governmentAgencyId) {
    return {
      searchCount: 0,
      messageCount: 0,
      inviteCount: 0,
      userCount: 0,
      recentSearches: [],
    };
  }
  return ApiService.apiV1BuyerLeadAgenciesAnalyticsRetrieve(governmentAgencyId);
});

export const entityAnalyticsAtom = loadable(asyncEntityAnalyticsAtom);
