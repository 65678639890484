/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `FUTURE_PIGGYBACK` - Future Piggyback
 * * `RFP_OR_BID` - Rfp Or Bid
 * * `NONE_JUST_BROWSING` - None Just Browsing
 */
export enum PurchaseMethodEnum {
    FUTURE_PIGGYBACK = 'FUTURE_PIGGYBACK',
    RFP_OR_BID = 'RFP_OR_BID',
    NONE_JUST_BROWSING = 'NONE_JUST_BROWSING',
}
