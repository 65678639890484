import clsx from "clsx";
import { type ReactNode, type Ref, forwardRef } from "react";

import { bgColorClass, borderColorClass } from "../../utils/colors";
import { OUTLINE_FOCUS_CLASS } from "../../utils/constants";

export enum CardContainerVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  ACCENT_SECONDARY = "accent.secondary",
}

const variantStyles = {
  [CardContainerVariant.PRIMARY]: bgColorClass.brand.default.tertiary.enabled,
  [CardContainerVariant.SECONDARY]: clsx(
    "border border-solid bg-white",
    borderColorClass.neutral.default
  ),
  [CardContainerVariant.TERTIARY]: clsx(
    "border border-solid",
    borderColorClass.brand.subtle.enabled
  ),
  [CardContainerVariant.ACCENT_SECONDARY]: clsx(
    bgColorClass.accent.default.secondary.enabled
  ),
} as const;

interface CardContainerProps {
  className?: string;
  variant?: CardContainerVariant;
  children: ReactNode;
}

const CardContainer = forwardRef(
  (
    {
      className,
      variant = CardContainerVariant.PRIMARY,
      children,
    }: CardContainerProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "rounded-3 grid",
          variantStyles[variant],
          OUTLINE_FOCUS_CLASS,
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export default CardContainer;
