import { useAtom, useSetAtom } from "jotai";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import useCookie from "../../../../hooks/useCookie";
import { inferredEntitySignupState } from "../../../../jotai/signup";
import { userStateState, userTypeSignupState } from "../../../../jotai/user";
import { Typography } from "../../../../library";
import {
  handleError,
  patchUserState,
  postSocialSignup,
} from "../../../../utils/api";
import {
  COMMON_GOV_TLD_REGEX,
  GOV_EMAIL_REGEX,
  SIGNUP_ORIGIN_COOKIE_KEY,
} from "../../../../utils/constants";
import {
  LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../../../utils/enums";
import {
  changeHeapEventLoginStatus,
  trackHeapEvent,
  trackSignupFlowFailure,
  trackSignupSuccess,
} from "../../../../utils/tracking";
import type { WindowType } from "../../types";
import StepCircle from ".././StepCircle";
import useInferExistingEntityFromEmail from ".././useCheckExistingEntityFromEmail";
import PostSocialAuthBuyerForm from "./PostSocialAuthBuyerForm";
import PostSocialAuthSupplierForm from "./PostSocialAuthSupplierForm";

interface RedesignedPostSocialAuthFormProps {
  onComplete: ({
    analyticsClassName,
    successMessage,
    redirectUrl,
  }: {
    analyticsClassName: string;
    successMessage: string;
    redirectUrl: string;
  }) => void;
  provider?: string;
  userEmail?: string;
  parentWindow: WindowType;
}

export default function RedesignedPostSocialAuthForm({
  onComplete,
  provider,
  userEmail,
  parentWindow,
}: RedesignedPostSocialAuthFormProps) {
  const [userType, setUserType] = useAtom(userTypeSignupState);
  const [userState, setUserState] = useAtom(userStateState);
  const inferExistingEntityFromEmail = useInferExistingEntityFromEmail();
  const setInferredEntity = useSetAtom(inferredEntitySignupState);
  const [hasInferred, setHasInferred] = useState(false);

  const [signupOrigin] = useCookie(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.UNKNOWN
  );

  const isSupplierSignup = userType === loginSignupAccountTypes.SUPPLIER;

  useEffect(() => {
    if (!userEmail) return;
    if (hasInferred) return;
    // On first render, infer the entity from userEmail and set userType
    (async () => {
      const inferredEntity = await inferExistingEntityFromEmail(userEmail);
      setHasInferred(true);
      setInferredEntity(inferredEntity);

      let inferredUserType = null;
      if (inferredEntity?.supplierHandle) {
        inferredUserType = loginSignupAccountTypes.SUPPLIER;
      } else if (inferredEntity?.buyerLeadAgencyId) {
        inferredUserType = loginSignupAccountTypes.BUYER;
      } else {
        const isLikelyGovEmail =
          GOV_EMAIL_REGEX.test(userEmail) &&
          COMMON_GOV_TLD_REGEX.test(userEmail);
        inferredUserType = isLikelyGovEmail
          ? loginSignupAccountTypes.BUYER
          : loginSignupAccountTypes.SUPPLIER;
      }

      setUserType(inferredUserType);
    })();
  }, [
    userEmail,
    inferExistingEntityFromEmail,
    setInferredEntity,
    setUserType,
    hasInferred,
  ]);

  async function handleSignup(
    form: FormData,
    entitySelected: string,
    setFieldError?: (field: string, message: string) => void
  ): Promise<boolean> {
    const response = await postSocialSignup(form);

    if (
      handleError(response, { logToSentry: true, log400ErrorsToSentry: false })
    ) {
      trackHeapEvent("social-signup-failure");
      trackSignupFlowFailure({
        emailEntered: userEmail,
        loginType: provider,
        signupStep: loginSignupSteps.SIGNUP,
        error: "Social signup failed",
        loginExperience: parentWindow,
      });

      if (setFieldError && response.status === 400) {
        const error = await response.json();
        if (error?.phoneNumber) {
          setFieldError("phone", error.phoneNumber[0].message);
        }
      }
      return false;
    }
    changeHeapEventLoginStatus(true);
    trackSignupSuccess({
      accountType: isSupplierSignup
        ? loginSignupAccountTypes.SUPPLIER
        : loginSignupAccountTypes.BUYER,
      loginType: provider as unknown as LoginType,
      emailEntered: userEmail,
      entitySelected,
      signupOrigin,
    });
    Cookies.remove(SIGNUP_ORIGIN_COOKIE_KEY);
    // Our signup form says, "By clicking 'Create account,' you agree to the policy"
    const updatedState = { ...userState, hasAcceptedPrivacyPolicy: true };
    patchUserState(updatedState);
    setUserState(updatedState);
    const analyticsClassName = isSupplierSignup
      ? "analytics-supplier-signup-success"
      : "analytics-buyer-signup-success";
    const successMessage = isSupplierSignup
      ? "🎉 Account creation complete! You can now optimize your profile on Pavilion"
      : "🎉 Account creation complete! You can now view all contracts on Pavilion.";
    onComplete({
      analyticsClassName,
      successMessage,
      redirectUrl: response.url,
    });
    return true;
  }

  async function trackError(error: string) {
    trackSignupFlowFailure({
      emailEntered: userEmail,
      accountType: userType,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: error,
      loginExperience: parentWindow,
    });
  }
  // TODO: Use entrypoint to customize copy for 3rd step
  // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
  const stepFromEntrypoint = "View contract";
  const steps = ["Sign up", "Create account", stepFromEntrypoint];
  return (
    <div className="grid gap-6">
      <div className="flex gap-3">
        {steps.map((text, index) => (
          <StepCircle key={text} text={text} index={index} />
        ))}
      </div>
      <div className="grid gap-4">
        <div className="grid gap-1">
          <Typography
            variant="headline"
            size="sm"
            color="neutral.boldest.enabled"
            emphasis
          >
            Let's set up Pavilion for you
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Tell us about yourself so we can customize your experience.
          </Typography>
        </div>
        {isSupplierSignup ? (
          <PostSocialAuthSupplierForm
            handleSignup={handleSignup}
            trackError={trackError}
          />
        ) : (
          <PostSocialAuthBuyerForm
            handleSignup={handleSignup}
            trackError={trackError}
          />
        )}
      </div>
    </div>
  );
}
