/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `RESEARCHING` - Researching
 * * `BUYING` - Buying
 */
export enum SearchIntentEnum {
    RESEARCHING = 'RESEARCHING',
    BUYING = 'BUYING',
}
