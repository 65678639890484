import Cookies from "js-cookie";
import naturalCompare from "natural-compare-lite";
import { parse } from "qs";
import * as yup from "yup";

import _upperFirst from "lodash/upperFirst";
import type { SemanticRecommendedContract } from "../generated";
import { ContractFileTypeEnum } from "../generated";
import type { ContractFile, DjangoChoices } from "../shared/types";
import {
  BLOCKED_EMAIL_DOMAINS,
  EMAIL_REGEX,
  SUFFIX_ALLOWLIST,
  WALKTHROUGH_LINK,
} from "./constants";
import type { ProfileTypes } from "./enums";
import { isMobileDevice } from "./mobile";

export function getCSRFToken() {
  return Cookies.get("csrftoken");
}

export function isIframe() {
  return hasWindow() && window.location !== window.parent.location;
}

export function goToURL(
  url: URL | string,
  // biome-ignore lint/suspicious/noExplicitAny: We want to allow passing in any type as a search param.
  params: Record<string, any> = {},
  openOption = true
) {
  const urlWithParams = new URL(url, window.location.origin);
  Object.entries(params).forEach(([paramName, paramValue]) => {
    urlWithParams.searchParams.append(paramName, paramValue);
  });

  const openInNewTab = (!isMobileDevice() && openOption) || isIframe();

  if (openInNewTab) {
    window.open(urlWithParams);
  } else {
    window.location.assign(urlWithParams);
  }
}

export function getWalkthroughLink(utmCampaign: string, utmMedium: string) {
  return `${WALKTHROUGH_LINK}?utm_source=website&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}`;
}

export function parseJSONPasswordError(passwordError: string) {
  let errorMessage = "";
  try {
    const passwordErrors = (
      JSON.parse(passwordError) as { message: string }[]
    ).map((error) => error.message);

    errorMessage =
      passwordErrors && passwordErrors.length > 0
        ? passwordErrors.join(",")
        : "";
  } catch {
    errorMessage = "";
  }
  return errorMessage;
}

export function getParams(queryString?: string) {
  // TODO: we can tear out qs in favor of URLSearchParams but this will require
  //  a refactor from `getParams()["paramKey"]` to `getParams().get("paramKey")`
  //  https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams

  // window is undefined during SSR on server
  if (hasWindow()) {
    const query = queryString || window.location.search;
    return parse(query, { ignoreQueryPrefix: true });
  }
  return {};
}

export function getParam(key: string, defaultValue = "") {
  // window is undefined during SSR on server
  if (hasWindow()) {
    return (
      new URL(window.location.toString()).searchParams.get(key) || defaultValue
    );
  }
  return defaultValue;
}

export function getRequestID() {
  return getParam("requestID", "");
}

export function getUrlZip() {
  return getParam("zip", "");
}

/**
 * Use replaceState so that navigation to and away from a modal do not
 * create new breadcrumbs in a user's history.
 */
export function setParamNoHistory(key: string, value: null | string) {
  if ("URLSearchParams" in window) {
    const searchParams = new URLSearchParams(window.location.search);
    if (value === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    const newRelativePathQuery = `${
      window.location.pathname
    }?${searchParams.toString()}`;
    history.replaceState(null, "", newRelativePathQuery);
  }
}

export function isDevelopment() {
  return process.env.ENVIRONMENT === "development";
}

export function isProduction() {
  return process.env.ENVIRONMENT === "production";
}

export const DEBUG = process.env.DEBUG === "true"; // Turn this on if you want more traces and reporting

// biome-ignore lint/suspicious/noExplicitAny: Allow arbitrary debug values.
export function debugLog(...args: any[]) {
  if (!isProduction()) {
    // biome-ignore lint/suspicious/noConsoleLog: <explanation>
    console.log("(DEBUG)", ...args);
  }
}

// biome-ignore lint/suspicious/noExplicitAny: Allow arbitrary warning values.
export function debugWarn(...args: any[]) {
  if (!isProduction()) {
    console.warn("(DEBUG)", ...args);
  }
}

export function hasAuthenticationOnLoad() {
  return getDatasetValue("isAuthenticated") === "True";
}

export function onPostSocialAuthOnLoad() {
  return getDatasetValue("onPostSocialAuth") === "True";
}

export function profileTypeOnLoad() {
  const profileType = getDatasetValue("profileType");
  if (profileType === "None") return null;
  return profileType as ProfileTypes;
}

export function activeAgreementsOnLoad(): string[] {
  const activeAgreements = getDatasetValue("activeAgreements");
  if (activeAgreements === "None") return [];
  return JSON.parse(activeAgreements || "[]");
}

export function getDatasetValue(key: string) {
  if (typeof document === "undefined") return null;
  const body = document.querySelector("body");
  return body?.dataset[key];
}

export function userIsImpersonated() {
  return getDatasetValue("userIsImpersonated") === "true";
}

export function getMicrosoftLoginLink() {
  return getDatasetValue("msLogin");
}

export function getGoogleLoginLink() {
  return getDatasetValue("googleLogin");
}

function hasWhitelistedTld(email: Maybe<string>) {
  if (!email) {
    return false;
  }
  const lowercaseEmail = String(email).toLowerCase();
  return SUFFIX_ALLOWLIST.some((suffix: string) => {
    return lowercaseEmail.endsWith(suffix);
  });
}

yup.addMethod<yup.StringSchema>(yup.string, "hasWhitelistedTld", function () {
  return this.test(
    "has-whitelisted-tld",
    "Email is not a valid top level domain",
    (value) => hasWhitelistedTld(value)
  );
});

export function validateEmail(email: string, shouldValidateWhiteList = false) {
  const lowercaseEmail = String(email).toLowerCase();
  if (shouldValidateWhiteList) {
    try {
      yup.string().email().hasWhitelistedTld().validateSync(email);
    } catch {
      return false;
    }
  }

  return (
    EMAIL_REGEX.test(lowercaseEmail) &&
    !BLOCKED_EMAIL_DOMAINS.some((domain) => lowercaseEmail.includes(domain))
  );
}

export const NO_MODAL = {};

// It's impossible to truly set a cookie to never expire, so we chose an arbitrarily long date.
// Because of the Y2038 bug, we want to avoid setting the date past 19 January 2038
export const COOKIE_NO_EXPIRE = 365 * 10;

export function getDOMAnchorById(id: string) {
  // document is undefined during SSR on server
  if (hasWindow()) {
    return document.getElementById(id);
  }
  return false;
}

export function getDOMAnchorsByClass(className: string) {
  // document is undefined during SSR on server
  if (hasWindow()) {
    return document.getElementsByClassName(className);
  }
  return false;
}

export function hasWindow() {
  return typeof window !== "undefined";
}

export function sortFilesByType(files: Maybe<ContractFile[]>) {
  if (!files) {
    return [];
  }

  const idealOrder = [
    ContractFileTypeEnum.CONTRACT,
    ContractFileTypeEnum.PRICING,
    ContractFileTypeEnum.BID_SOLICITATION,
    ContractFileTypeEnum.BID_TABULATION,
    ContractFileTypeEnum.AMENDMENT,
    ContractFileTypeEnum.RENEWAL,
    ContractFileTypeEnum.AWARD_SUMMARY,
    ContractFileTypeEnum.SUPPLIER_RESPONSE,
    ContractFileTypeEnum.SUPPLIER,
    ContractFileTypeEnum.INSURANCE,
    ContractFileTypeEnum.OTHER,
  ];
  const filesForSort = [...files];
  return filesForSort.sort((a, b) => {
    if (a.type === b.type) {
      if (!b.url || !a.url) return naturalCompare(a.name, b.name);
      const bIsPdf = b.url.endsWith("pdf");
      const aIsPdf = a.url.endsWith("pdf");
      // Float pdf docs to the top so that the initial page view ideally has a file you can see
      if (bIsPdf && aIsPdf) return naturalCompare(a.name, b.name);
      if (bIsPdf) return 1;
      return -1;
    }
    let indexOfA = idealOrder.indexOf(a.type);
    if (indexOfA === -1) indexOfA = idealOrder.length;
    let indexOfB = idealOrder.indexOf(b.type);
    if (indexOfB === -1) indexOfB = idealOrder.length;
    return indexOfA - indexOfB;
  });
}

// This is used on the solicitation page to sort the results based on the percent_match from the backend
// It sorts first by percent, then if equal, alphabetically by the secondKey
export function sortListByPercentMatchAndKey(
  list: SemanticRecommendedContract[],
  secondKey?: keyof SemanticRecommendedContract
) {
  return list.sort((a, b) => {
    return (
      b.percentMatch - a.percentMatch ||
      (secondKey &&
        (a[secondKey] as string).localeCompare(b[secondKey] as string)) ||
      0
    );
  });
}

/**
 * Wrapper class for access to window.localStorage and window.sessionStorage.
 * Automatically handles serialization and deserialization. Falls back to a
 * simple in-memory store if browser storage is not available.
 */
export class BrowserStorage {
  storageAvailable: boolean;
  storage: Storage | null;
  // biome-ignore lint/suspicious/noExplicitAny: Allow storing any type on the fallback object
  fallbackStorage: Record<string, any>;
  skipSerializationKeys: Set<string>;
  constructor(storage: Storage | null) {
    this.storageAvailable = storageIsAvailable(storage);
    this.storage = storage;
    this.fallbackStorage = {};

    // We skip serialization and deserialization for backwards compatibility,
    // as there is existing data for these keys in users' browsers where the
    // data is not serialized. We should not add keys to this set.
    this.skipSerializationKeys = new Set(["widgetSearchSource", "splitKey"]);
  }

  get(key: string) {
    let value = null;
    if (!this.storage || !this.storageAvailable) {
      if (key in this.fallbackStorage) {
        value = this.fallbackStorage[key];
      }
    } else {
      value = this.storage.getItem(key);
    }
    if (!this.skipSerializationKeys.has(key)) {
      value = JSON.parse(value);
    } else {
      // Due to legacy bugs with localStorage keys where values were not serialized,
      // it's possible the string "undefined" may have gotten saved.
      if (value === "undefined") {
        value = null;
      }
    }
    return value;
  }

  /**
   * Supports setting all values, except undefined (as that is an
   * invalid value for JSON serialization).
   */
  // biome-ignore lint/suspicious/noExplicitAny: Allow storing any type on the fallback object
  set(key: string, value: any) {
    if (value !== undefined) {
      if (!this.skipSerializationKeys.has(key)) {
        // biome-ignore lint/style/noParameterAssign: We want to make the parameter an object as well.
        value = JSON.stringify(value);
      }
      if (!this.storage || !this.storageAvailable) {
        this.fallbackStorage[key] = value;
      } else {
        this.storage.setItem(key, value);
      }
    }
  }

  remove(key: string) {
    if (!this.storage || !this.storageAvailable) {
      delete this.fallbackStorage[key];
    } else {
      this.storage.removeItem(key);
    }
  }

  /**
   * Clears the underlying storage of _all_ keys if possible.
   */
  clear() {
    if (this.storage && this.storageAvailable) {
      this.storage.clear();
    }
    this.fallbackStorage = {};
  }
}

export const browserLocalStorage = new BrowserStorage(
  localStorageIsAvailable() ? window.localStorage : null
);
export const browserSessionStorage = new BrowserStorage(
  sessionStorageIsAvailable() ? window.sessionStorage : null
);

export function isScrolledIntoView(elem: Maybe<HTMLElement>) {
  if (typeof window !== "undefined" && elem) {
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop + window.innerHeight;

    const elemTop = Math.round(
      elem.getBoundingClientRect().top + document.documentElement.scrollTop
    );
    const elemBottom = elemTop + elem.offsetHeight;
    return elemTop <= docViewBottom && elemBottom >= docViewTop;
  }
  return false;
}

function storageIsAvailable(storage: Storage | null): boolean {
  if (!storage) return false;
  // Browser storage is unavailable
  // - during SSR on server
  // - in some Incognito/private experiences
  // - on some older browsers
  //
  // Feature test from Modernizr.js
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
  const test = "test";
  try {
    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch {
    return false;
  }
}

function localStorageIsAvailable() {
  try {
    return storageIsAvailable(window.localStorage);
  } catch {
    return false;
  }
}

function sessionStorageIsAvailable() {
  try {
    return storageIsAvailable(window.sessionStorage);
  } catch {
    return false;
  }
}

export function sortStringsWithQuery(offerings: string[], query?: string) {
  return offerings.sort((a, b) =>
    query
      ? Number(b.toLowerCase().includes(query.toLowerCase())) -
        Number(a.toLowerCase().includes(query.toLowerCase()))
      : 0
  );
}

export function getSentenceCase(str: string) {
  // Don't capitalize if some letters in the first word are already capitalized.
  if (str.match(/^\b\w*[A-Z]\w*\b/)) return str;

  // Capitalize the first letter of the string
  return _upperFirst(str);
}

export function choicesToOptions(choices: DjangoChoices) {
  return choices.map(([value, label]) => ({ key: value, value, label }));
}
