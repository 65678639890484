import { LabelVariant } from "../../../library";
import {
  LabeledInputField,
  PHONE_FIELDS,
  PasswordInputField,
  SUPPLIER_ROLE_FIELDS,
} from "../../../library/form";
import SupplierInputField from "../../../library/form/SupplierInputField";
import type {
  FieldLabelProps,
  FormFieldProps,
} from "../../../library/form/types";
import { EMAIL_REGEX } from "../../../utils/constants";
import yup from "../../../utils/yupPhone";
import { BLA_FIELDS, PASSWORD_FIELDS, PASSWORD_VALIDATION } from "../constants";
import { BuyerRoleRadioGroupField } from "./BuyerRoleRadioGroupField";

const LABEL_PROPS: FieldLabelProps = {
  labelSize: "sm",
  labelStyle: LabelVariant.FLOATING,
  labelTextVariant: "body",
  labelEmphasis: false,
};

export const REDESIGNED_PASSWORD_FIELD: FormFieldProps = {
  ...PASSWORD_FIELDS[0],
  ...LABEL_PROPS,
  label: "Password",
  size: "sm",
  validationIcons: true,
  validationMessage: "Password contains 8 characters",
};

export const REDESIGNED_EMAIL_FIELD: FormFieldProps = {
  ...LABEL_PROPS,
  size: "sm",
  component: LabeledInputField,
  validationIcons: true,
  validationMessage: "Valid email confirmed",
  name: "email",
  label: "Work email address",
  className: "analytics-email-signup-modal-email-input",
  dataTestId: "email-input",
  autoComplete: "email",
  validate: yup
    .string()
    .matches(EMAIL_REGEX, "A valid email is required.")
    .hasWhitelistedTld()
    .required("Email is required."),
};

export const REDESIGNED_BLA_FIELD: FormFieldProps = {
  ...BLA_FIELDS[0],
  ...LABEL_PROPS,
  label: "Public entity",
  size: "sm",
  validationIcons: true,
};

export const REDESIGNED_SUPPLIER_FIELD: FormFieldProps = {
  ...LABEL_PROPS,
  size: "sm",
  component: SupplierInputField,
  validationIcons: true,
  name: "supplier",
  placeholder: null,
  validate: yup.object().shape(
    {
      supplier: yup.object().shape({
        handle: yup.string().nullable(),
        displayName: yup.string().when("handle", {
          is: (val: string | undefined) => !val,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    },
    [["handle", "displayName"]]
  ),
  dataTestId: "business-input",
  className: "analytics-signup-modal-business-input",
};

export const REDESIGNED_NAME_FIELD: FormFieldProps = {
  ...LABEL_PROPS,
  size: "sm",
  component: LabeledInputField,
  name: "name",
  label: "What's your full name?",
  className: "analytics-email-signup-modal-full-name-input",
  dataTestId: "full-name-input",
  validationIcons: true,
  validate: yup
    .string()
    .trim()
    .max(150, "Your name cannot exceed 150 characters")
    .required("Name is required."),
  validationMessage: "Completed",
};

export const REDESIGNED_BUYER_ROLE_FIELD: FormFieldProps = {
  ...LABEL_PROPS,
  size: "sm",
  component: BuyerRoleRadioGroupField,
  name: "buyerRole",
  label: "What's your role?",
  validate: yup.string().required("Role is required."),
};

export const REDESIGNED_PHONE_FIELD: FormFieldProps = {
  ...PHONE_FIELDS[0],
  ...LABEL_PROPS,
  size: "sm",
  placeholder: "",
  validationIcons: true,
  validationMessage: "Valid phone number",
};

export const REDESIGNED_SUPPLIER_ROLE_FIELD: FormFieldProps = {
  ...SUPPLIER_ROLE_FIELDS[0],
  ...LABEL_PROPS,
  label: "What's your role?",
  size: "sm",
  className: "w-80 !max-h-40",
  validationIcons: true,
};

export const REDESIGNED_LOGIN_PASSWORD_FIELD: FormFieldProps = {
  ...LABEL_PROPS,
  size: "sm",
  component: PasswordInputField,
  name: "password",
  label: "Password",
  placeholder: null,
  validate: PASSWORD_VALIDATION,
  className: "analytics-email-login-modal-password-input",
  dataTestId: "password-input",
  autoComplete: "password",
};

export const REDESIGNED_LOGIN_EMAIL_FIELD: FormFieldProps = {
  ...LABEL_PROPS,
  size: "sm",
  component: LabeledInputField,
  name: "email",
  label: "Work email address",
  className: "analytics-email-login-modal-email-input",
  dataTestId: "email-input",
  autoComplete: "email",
  validate: yup
    .string()
    .matches(EMAIL_REGEX, "Please enter a valid email.")
    .required("Email is required."),
};

export const MINIMUM_STATE_ENTITY_COUNT = 20;
