import { type Getter, type Setter, atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  AccountsService,
  type InferredEntityFromEmail,
  type SignupDetails,
} from "../generated";
import type { BuyerLeadAgencyFieldValue } from "../library/form/BuyerLeadAgencyField";
import type { SupplierInputFieldValue } from "../library/form/SupplierInputField";
import { handleError } from "../utils/generatedApi";
import { isAuthenticatedState } from "./user";

export const inferredEntitySignupState = atom<InferredEntityFromEmail | null>(
  null
);

export const blaFieldSignupState = atom<BuyerLeadAgencyFieldValue | null>(null);

export const supplierFieldSignupState = atom<SupplierInputFieldValue | null>(
  null
);

export const signupDetailsState = atom<SignupDetails | null>(null);

export function initializeSignupDetailsCallback() {
  const initialize = useCallback(async (get: Getter, set: Setter) => {
    const isAuthenticated = get(isAuthenticatedState);
    const signupDetails = get(signupDetailsState);
    if (isAuthenticated || signupDetails) return;

    try {
      const signupDetails =
        await AccountsService.accountsSignupDetailsRetrieve();
      set(signupDetailsState, signupDetails);
    } catch (err) {
      handleError(err);
    }
  }, []);

  return useAtomCallback(initialize);
}
