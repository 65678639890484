import { createRoot } from "react-dom/client";

import BuyerSignupWelcomePage from "../../components/Account/BuyerSignupWelcome";
import { getDOMAnchorById } from "../../utils";
import { PageType } from "../../utils/enums";
import Page from "../Page/Page";

interface BuyerSignupWelcomeProps {
  bla_name: string;
  isSSR: boolean;
}
export default function BuyerSignupWelcome({
  bla_name,
  isSSR,
}: BuyerSignupWelcomeProps) {
  return (
    <Page
      pageId=""
      pageType={PageType.ACCOUNT}
      showHeader={false}
      isAuthenticated={false}
      profileType={null}
      activeAgreements={null}
      isSSR={isSSR}
    >
      <BuyerSignupWelcomePage blaName={bla_name} />
    </Page>
  );
}

const container = getDOMAnchorById("buyer-signup-welcome-container");
if (container) {
  const props = JSON.parse(container.dataset.welcomeData || "");
  const root = createRoot(container);
  root.render(<BuyerSignupWelcome {...props} />);
}
