import clsx from "clsx";
import type { ReactNode } from "react";
import Typography from "../Typography";

export default function HeadlineMetric({
  value,
  children,
  className,
  loading = false,
}: {
  value: number;
  children: ReactNode;
  className?: string;
  loading?: boolean;
}) {
  return (
    <div className={clsx("flex flex-col gap-2", className)}>
      <Typography
        variant="headline"
        size="lg"
        emphasis
        color="brand.boldest.enabled"
        className={clsx({ "h-8 w-full": loading })}
      >
        {loading ? (
          <div className="rounded-lg bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse h-full w-1/3" />
        ) : (
          value.toLocaleString()
        )}
      </Typography>
      <Typography color="neutral.boldest.enabled">{children}</Typography>
    </div>
  );
}
