import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import useRequestID from "../../hooks/useRequestID";

import {
  type MessageSupplierData,
  messageSupplierStoreAtom,
} from "../../jotai/messageSupplier";
import { userInitializedState, userZipState } from "../../jotai/user";
import { Button, Typography } from "../../library";
import { CTAType } from "../../shared/ContractBase/types";
import { getParam, goToURL } from "../../utils";
import { pageNavigationSourceTypes } from "../../utils/enums";
import {
  getContractPath,
  getMessageSupplierOnSolicitationUrl,
} from "../../utils/format";
import {
  trackMessageSupplierRecommendedContractTitleClick,
  trackMessageSupplierRecommendedProSuppliersShown,
  trackMessageSupplierRecommendedSupplierNext,
  trackMessageSupplierRecommendedToggle,
} from "../../utils/tracking";
import type { TrackContractClickWithinSolicitationCarouselFn } from "../SolicitationPage/types";

import { useSetAtom } from "jotai";
import {
  ApiService,
  type RecommendedContractRequest,
  type SemanticRecommendedContract,
} from "../../generated";
import { handleError } from "../../utils/generatedApi";
import ContractCardContainer from "../ContractCardContainer";
import {
  getEntityContractFromSimilarContract,
  getMessageSupplierDataFromSimilarContract,
  getPromotedSuppliersDataFromContract,
  getPromotedSuppliersDataFromMessageSupplierData,
} from "./helpers";

interface SimilarContractSuppliersProps {
  supplierId: number;
  supplierHandle: string;
  contractId: string;
  solicitationId: string;
  onRender?: (solicitations: SemanticRecommendedContract[]) => void;
  trackContractClickWithinSolicitation?: TrackContractClickWithinSolicitationCarouselFn; // TODO
}
const NUM_COLS = 3;
export default function SimilarContractSuppliers({
  supplierHandle,
  supplierId,
  contractId,
  solicitationId,
}: SimilarContractSuppliersProps): JSX.Element | null {
  const userZip = useAtomValue(userZipState);
  const setMessageSupplierStore = useSetAtom(messageSupplierStoreAtom);
  const isInitialized = useAtomValue(userInitializedState);
  const [recommendedContracts, setRecommendedContracts] = useState<
    SemanticRecommendedContract[]
  >([]);
  const [numRowsVisible, setNumRowsVisible] = useState(1);
  const [selectedContractIds, setSelectedContractIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const requestID = useRequestID();
  const query = getParam("query");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once after the user has been initialized.
  useEffect(() => {
    if (!isInitialized) return;
    const body: RecommendedContractRequest = {
      docid: contractId,
      dedupeSuppliers: true,
    };
    if (userZip) body.zip = userZip;
    setIsLoading(true);
    (async () => {
      // We allow users to message the same supplier twice (for two different contracts),
      // so don't need to filter by messageSuplierState.messagedSuppliers here
      try {
        const data =
          await ApiService.apiV1RecommendationsSemanticContractCreate(body);

        if (data.results) {
          setRecommendedContracts(data.results);
          // Pre-select the first row of contracts (assuming "xl" window width)
          setSelectedContractIds(
            data.results
              .map((contract: SemanticRecommendedContract) => contract.docid)
              .slice(0, NUM_COLS)
          );
        }
      } catch (err) {
        handleError(err);
      }
      setIsLoading(false);
    })();
  }, [contractId, isInitialized]);

  useEffect(() => {
    if (!recommendedContracts) {
      return;
    }

    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromContract(recommendedContracts);

    if (promotedSupplierCount > 0) {
      trackMessageSupplierRecommendedProSuppliersShown({
        supplierId,
        supplierHandle,
        contractId,
        solicitationId,
        promotedSupplierCount,
        promotedSupplierIds,
        promotedSupplierHandles,
      });
    }
  }, [
    recommendedContracts,
    contractId,
    solicitationId,
    supplierHandle,
    supplierId,
  ]);

  function trackToggle({
    contract,
    untoggle,
  }: {
    contract: SemanticRecommendedContract;
    untoggle: boolean;
  }) {
    trackMessageSupplierRecommendedToggle({
      untoggle,
      supplierId,
      supplierHandle,
      contractId,
      toggledSupplierId: contract.supplierId,
      toggledSupplierHandle: contract.supplierHandle,
      toggledContractId: contract.docid,
      solicitationId,
      isPromoted: contract.proTreatment || false,
    });
  }

  function handleClickContractTitle({
    contract,
    url,
  }: {
    contract: SemanticRecommendedContract;
    url: string | URL;
  }) {
    trackMessageSupplierRecommendedContractTitleClick({
      clickedSupplierId: contract.supplierId,
      clickedSupplierHandle: contract.supplierHandle,
      clickedContractId: contract.docid,
      solicitationId,
      isPromoted: contract.proTreatment || false,
    });
    goToURL(url);
  }

  function getContractContainer(similarContract: SemanticRecommendedContract) {
    const entityContract =
      getEntityContractFromSimilarContract(similarContract);
    const url = entityContract.url
      ? entityContract.url
      : getContractPath({
          solicitationId: entityContract.solicitationId,
          contractId: entityContract.docid,
          query,
          pageNavigationSource:
            pageNavigationSourceTypes.MESSAGE_SUPPLIER_SUCCESS_PAGE,
          requestID,
        });
    return (
      <ContractCardContainer
        cardAnalyticsClass="analytics-message-supplier-similar-contract"
        saveCTAClass="analytics-messagerecommendationpage-select-contract"
        key={similarContract.docid}
        onAdd={(id: string) => {
          trackToggle({ contract: similarContract, untoggle: false });
          setSelectedContractIds((prev: string[]) => [...prev, id]);
        }}
        onRemove={(id: string) => {
          trackToggle({ contract: similarContract, untoggle: true });
          setSelectedContractIds((prev: string[]) =>
            prev.filter((p) => p !== id)
          );
        }}
        onClickTitle={() =>
          handleClickContractTitle({ contract: similarContract, url })
        }
        contract={entityContract}
        ctaType={CTAType.CHECKBOX}
        selected={selectedContractIds.includes(similarContract.docid)}
      />
    );
  }

  const onSelectSuppliersToMessage = () => {
    const selectedSuppliers = selectedContractIds
      .map((id) => {
        const contract = recommendedContracts.find((s) => s.docid === id);
        // since selectedContractIds only comes from `solicitations`,
        // contract is guaranteed to exist
        return contract && getMessageSupplierDataFromSimilarContract(contract);
      })
      .filter((v) => !!v) as MessageSupplierData[];
    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromMessageSupplierData(selectedSuppliers);

    const savedSupplierHandles = selectedSuppliers.map(
      ({ supplier }) => supplier.handle
    );

    trackMessageSupplierRecommendedSupplierNext({
      supplierId,
      contractId,
      solicitationId,
      savedSupplierIds: selectedSuppliers.map(({ supplier }) => supplier.id),
      savedSupplierHandles,
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    });
    setMessageSupplierStore((prev) => {
      const recommendedSuppliers = recommendedContracts.map((contract) =>
        getMessageSupplierDataFromSimilarContract(contract)
      );
      return {
        ...prev,
        [contractId]: {
          ...prev[contractId],
          rootDocid: contractId,
          recommendedSuppliers,
          recommendedSuppliersToMessage: selectedSuppliers,
          selectedContractIds,
        },
      };
    });
    const query = getParam("query", undefined);
    const url = getMessageSupplierOnSolicitationUrl({
      solicitationId,
      contractId,
      zip: userZip,
      query,
      requestID,
    });
    url.searchParams.append("savedSuppliers", savedSupplierHandles.join(","));
    url.searchParams.append(
      "allSuppliers",
      recommendedContracts.map(({ supplierHandle }) => supplierHandle).join(",")
    );
    // Must keep session in the same tab to preserve session storage
    window.open(url, "_parent");
  };

  return (
    <div className="flex flex-col">
      <div className="w-full md:flex flex flex-col">
        <Typography
          emphasis
          size="md"
          variant="headline"
          color="brand.default.secondary.enabled"
          className="mb-4"
        >
          Message suppliers who have similar contracts
        </Typography>
        <div className="grid gap-1 mb-6">
          <Typography>
            Your need is confirmed in scope in these suppliers{"'"} contracts.
            96% of buyers get a response when they contact multiple suppliers.
          </Typography>
        </div>
      </div>
      {isLoading ? (
        <Typography>Loading recommendations...</Typography>
      ) : (
        recommendedContracts.length === 0 && (
          <Typography>We couldn't find any similar contracts. </Typography>
        )
      )}
      {recommendedContracts.length > 0 && (
        <>
          <div className="grid gap-6 xl:grid-cols-3 lg:grid-cols-2">
            {recommendedContracts
              .slice(0, NUM_COLS * numRowsVisible)
              .map((contract) => getContractContainer(contract))}
          </div>
          {numRowsVisible * NUM_COLS < recommendedContracts.length && (
            <Button
              className="analytics-message-supplier-view-more-recommended mt-4 self-end"
              size={Button.sizes.SMALL}
              theme={Button.themes.TERTIARY_DARK}
              onClick={() => setNumRowsVisible((prev) => prev + 1)}
            >
              View more
            </Button>
          )}
          <Button
            className="analytics-message-supplier-similar-contract-submit mt-6 mb-2 self-end"
            disabled={selectedContractIds.length === 0}
            onClick={onSelectSuppliersToMessage}
          >
            Message {selectedContractIds.length} more{" "}
            {pluralize("supplier", selectedContractIds.length)}
          </Button>
          <Typography
            variant="meta"
            color="neutral.bold.enabled"
            className="text-right"
          >
            You will be able to edit your message.
          </Typography>
        </>
      )}
    </div>
  );
}
