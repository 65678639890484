import { TagVariants } from "../shared/Tag";

export const supplierTagOrderBase: TagVariants[] = [
  TagVariants.PRO_SUPPLIER,
  TagVariants.RECENT_RESPONSE,
  TagVariants.VERIFIED_CONTACT,
  TagVariants.BEST_PRICE_GUARANTEE,
  TagVariants.YEARS_IN_BUSINESS,
  TagVariants.MAX_DELIVERY_TIME,
  TagVariants.SERVICE_PROVIDED,
  TagVariants.FREE_CONSULTATION,
  TagVariants.MADE_IN_USA,
  TagVariants.FAMILY_OWNED,
  TagVariants.GOVERNMENT_DISCOUNT,
];

export const supplierTagOrderDefault: TagVariants[] = [
  TagVariants.LOCAL_SUPPLIER,
  ...supplierTagOrderBase,
];

export const supplierTagOrderSERP: TagVariants[] = [
  TagVariants.LOCAL_AUTHORIZED_RESELLER,
  TagVariants.AUTHORIZED_RESELLER,
  TagVariants.SOCIAL_PROOF,
  TagVariants.LOCAL_TO_CITY,
  ...supplierTagOrderBase,
];

export function getSupplierTagOrder(): TagVariants[] {
  return supplierTagOrderSERP;
}
