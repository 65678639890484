import _groupBy from "lodash/groupBy";

import type { UserSupplierContact } from "../../../../generated";

export function getContactDisplayName(
  contact: UserSupplierContact,
  supplierName: string,
  index?: number
) {
  const isPerson = contact.firstName || contact.lastName;
  if (isPerson)
    return [contact.firstName, contact.lastName].filter((s) => s).join(" ");
  if (contact.title) return contact.title;
  if (index) return `Phone Contact ${index + 1}`;
  return supplierName;
}

export function groupContactsByTitle(
  contacts: UserSupplierContact[]
): Record<string, UserSupplierContact[]> {
  return _groupBy(
    contacts.filter(({ title }) => title),
    "title"
  );
}
