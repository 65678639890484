import { useField } from "formik";
import { useEffect } from "react";
import CostCalculatorFormCard from "./CostCalculatorFormCard";
import { type FieldHookProps, useStaffTimeFields } from "./constants";
import { staffHoursDefaults } from "./helpers";
import useIsComplexPurchase from "./useIsComplexPurchase";

export default function StaffTimeCard(props: FieldHookProps) {
  const fields = useStaffTimeFields(props);
  const isComplex = useIsComplexPurchase();
  const [, solicitationStaffHours, { setValue: setSolicitationStaffHours }] =
    useField("solicitationStaffHours");
  const [, piggybackStaffHours, { setValue: setPiggybackStaffHours }] =
    useField("piggybackStaffHours");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only update fields in response to complexity changing.
  useEffect(() => {
    const defaults = staffHoursDefaults(isComplex);
    if (!solicitationStaffHours.touched || !solicitationStaffHours.value) {
      setSolicitationStaffHours(defaults.solicitationStaffHours);
    }
    if (!piggybackStaffHours.touched || !piggybackStaffHours.value) {
      setPiggybackStaffHours(defaults.piggybackStaffHours);
    }
  }, [isComplex]);

  return (
    <CostCalculatorFormCard
      title="Staff time required - optional"
      subtitle="Staff time is a key cost driver for a procurement. You can set expected hours manually or skip this section to use NCPP's estimates from research with 39 public entities. NCPP factors in time from procurement, legal, management, finance, and end users. NCPP adds time for complex purchases."
      fields={fields}
    />
  );
}
