import { type ReactNode, useEffect, useState } from "react";

import {
  LabeledInput,
  LabeledInputVariants,
  PasswordInput,
  Typography,
} from "../../library";
import { handleError, postLogin } from "../../utils/api";
import { LoginType, loginSignupSteps } from "../../utils/enums";
import { SocialProvider, SocialProviders } from "../../utils/social";
import {
  LoginOrigin,
  trackLoginFailure,
  trackLoginSuccess,
  trackSignupFlowFailure,
} from "../../utils/tracking";

import RedesignedPushToLoginForm from "./RedesignedSignupBase/RedesignedPushToLoginForm";
import SocialLoginButton from "./SocialLoginButton";
import SubmitButton from "./SubmitButton";
import type { WindowType } from "./types";

const btnClassName = "analytics-push-to-login-modal-cta w-full";

interface PushToLoginFormProps {
  onComplete: () => void;
  parentWindow: WindowType;
  socialLoginSource: string;
  hasRedesign?: boolean;
}

export default function PushToLoginForm({
  onComplete,
  parentWindow,
  socialLoginSource,
  hasRedesign,
}: PushToLoginFormProps) {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCTALoading, setIsCTALoading] = useState(false);
  const isSocialLogin = SocialProviders.includes(
    socialLoginSource as SocialProvider
  );
  const initialEmail = isSocialLogin ? "" : socialLoginSource;
  // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about changes to socialProviders
  useEffect(() => {
    trackSignupFlowFailure({
      emailEntered: initialEmail,
      loginType: isSocialLogin ? socialLoginSource : LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: "Existing Pavilion account",
      loginExperience: parentWindow,
    });
  }, [socialLoginSource]);

  async function handleLogin() {
    setIsCTALoading(true);
    const form = new FormData();
    form.append("login", socialLoginSource || "");
    form.append("password", password);
    const response = await postLogin(form);
    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      setIsCTALoading(false);
      trackLoginFailure({
        emailEntered: initialEmail,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
      });
      setErrorMessage("Sorry, that password does not match this account.");
      trackSignupFlowFailure({
        emailEntered: initialEmail,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Incorrect password",
        loginExperience: parentWindow,
      });
      return;
    }
    setIsCTALoading(false);
    trackLoginSuccess({
      emailEntered: initialEmail,
      loginType: LoginType.PAVILION,
      origin: LoginOrigin.PAVILION,
    });
    onComplete();
  }

  if (hasRedesign) {
    return (
      <RedesignedPushToLoginForm
        onComplete={onComplete}
        handleLogin={handleLogin}
        parentWindow={parentWindow}
        socialLoginSource={socialLoginSource}
        isSocialLogin={isSocialLogin}
        initialEmail={initialEmail}
        errorMessage={errorMessage}
        isCTALoading={isCTALoading}
      />
    );
  }

  let subtitle: string;
  let form: ReactNode;

  if (socialLoginSource === SocialProvider.microsoft) {
    subtitle =
      "Looks like you already have a Pavilion account through Microsoft. Please log in with Microsoft to access your account.";
    form = (
      <SocialLoginButton
        className="analytics-email-signup-modal-microsoft button-exists peer"
        provider={SocialProvider.microsoft}
        onComplete={onComplete}
        parentWindow={parentWindow}
      />
    );
  } else if (socialLoginSource === SocialProvider.google) {
    subtitle =
      "Looks like you already have a Pavilion account through Google. Please log in with Google to access your account.";
    form = (
      <SocialLoginButton
        className="analytics-email-signup-modal-google button-exists peer"
        provider={SocialProvider.google}
        onComplete={onComplete}
        parentWindow={parentWindow}
      />
    );
  } else {
    subtitle =
      "Looks like you already have a Pavilion account. Please enter your password to log in to your account.";
    form = (
      <div className="flex flex-col gap-4">
        <LabeledInput
          type="email"
          name="email"
          label="Work email address"
          size="md"
          required
          readOnly
          initialVariant={LabeledInputVariants.SUCCESS}
          value={initialEmail}
          className="analytics-email-login-modal-email-input w-full"
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
          errorMessage={errorMessage}
          label="Enter your password"
          showForgotPassword
          className="grid gap-2"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
          autoFocus
        />
        <SubmitButton
          isCTALoading={isCTALoading}
          handleSubmit={handleLogin}
          ctaText="Log in"
          btnClassName={btnClassName}
        />
      </div>
    );
  }

  return (
    <div className="grid gap-8">
      <div className="bg-cp-cyan-100 p-4 rounded-2xl mt-0">
        <Typography color="brand.default.secondary.enabled" size="sm-md">
          {subtitle}
        </Typography>
      </div>
      {form}
    </div>
  );
}
