import type { FormikValues } from "formik";
import pluralize from "pluralize";
import * as yup from "yup";

import useShowModal from "../hooks/useShowModal";
import { ChipInputField, FormWrapper, TextareaField } from "../library/form";
import Modal from "../shared/Modal/Modal";
import { type INVITE_TEAM_SOURCE, modals } from "../utils/enums";
import { trackClickInviteTeam, trackInviteTeamSubmit } from "../utils/tracking";

import { useCallback } from "react";
import { ApiService } from "../generated";
import { handleError } from "../utils/generatedApi";
import { useSubmitAndSave } from "./constants";

interface InviteTeamModalProps {
  hideModal: () => void;
  inviteSource: INVITE_TEAM_SOURCE;
  afterInvite?: () => void;
}

const FIELDS = [
  {
    name: "emails",
    label: "Email addresses",
    component: ChipInputField,
    placeholder: "Input email addresses",
    message: "Most people share with 3 of their colleagues",
    dataTestId: "invite-team-email-addresses",
    validate: yup
      .array()
      .min(1, "Enter at least one email address to invite your colleagues."),
  },
  {
    name: "message",
    label: "Add a personal message (optional)",
    placeholder:
      "Let your teammates know why you're inviting them to join Pavilion",
    component: TextareaField,
    dataTestId: "invite-team-message",
  },
];

export default function InviteTeamModal({
  hideModal,
  inviteSource,
  afterInvite,
}: InviteTeamModalProps) {
  const showConfirmationModal = useShowModal(modals.CONFIRMATION);
  const [handleSubmit, loading] = useSubmitAndSave(
    () => hideModal,
    async (values: FormikValues) => {
      showConfirmationModal({
        title: `${pluralize("Invite", values.emails.length)} sent!`,
        ctaText: "Close",
        subtitle: `Thanks for sharing Pavilion! Your ${pluralize(
          "colleague",
          values.emails.length
        )}
        will receive an email with a personal invite link shortly.`,
      });
      trackInviteTeamSubmit(
        values.emails,
        values.message.substring(0, 150),
        inviteSource
      );
      try {
        await ApiService.apiV1ContactEmailInviteTeamCreate({
          emails: values.emails,
          message: values.message,
        });
      } catch (error) {
        handleError(error);
      } finally {
        afterInvite?.();
      }
    }
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Invite your team to Pavilion"
      subtitle="Invite your coworkers to Pavilion to find compliant contracts and collaborate on your contract search.
      We’ll email them a personal invite link."
    >
      <FormWrapper
        fields={FIELDS}
        initialValues={{
          emails: [],
          message:
            "Hello! I'm searching for cooperative and piggybackable contracts on Pavilion. Thought you would find Pavilion helpful too.",
        }}
        submitCta="Invite"
        disabled={loading}
        submitClassName="analytics-invite-team"
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}

export type ShowInviteTeamModalFn = (
  props: Omit<InviteTeamModalProps, "hideModal">
) => void;

export function useShowInviteTeamModal() {
  const showInviteTeamModal = useShowModal(modals.INVITE_TEAM);

  return useCallback<ShowInviteTeamModalFn>(
    (props) => {
      trackClickInviteTeam({
        source: props.inviteSource,
      });
      showInviteTeamModal(props as unknown as Record<string, unknown>);
    },
    [showInviteTeamModal]
  );
}
