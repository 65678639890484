import CostCalculatorFormCard from "./CostCalculatorFormCard";
import { type FieldHookProps, useDiscountMarkupFields } from "./constants";

export default function DiscountMarkupCard(props: FieldHookProps) {
  const fields = useDiscountMarkupFields(props);
  return (
    <CostCalculatorFormCard
      title="Discounts and markups - optional"
      fields={fields}
    />
  );
}
