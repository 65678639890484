import { DisclosureList, Link, Typography } from "../../../library";
import SupportEmailLink from "../../../shared/SupportEmailLink";

const faqs = [
  {
    title: "What is Pavilion?",
    text: (
      <>
        <Typography size="sm">
          Pavilion helps you find responsive suppliers on contracts you can use.
          We help you:
        </Typography>
        <Typography
          component="ul"
          size="sm"
          className="list-disc pl-4 grid gap-2"
        >
          <li>
            Search across 50,000 suppliers on 100,000+ contracts from
            cooperatives, states, and local entities
          </li>
          <li>Safeguard compliance with built-in checks</li>
          <li>
            Get quotes and fast supplier responses to keep the procurement
            moving
          </li>
        </Typography>
        <Typography size="sm">
          Best of all, it{"'"}s free for public entities to use Pavilion. You
          don{"'"}t need to procure us to use us!
        </Typography>
      </>
    ),
  },
  {
    title: "How do I use Pavilion?",
    text: (
      <>
        <Typography size="sm">
          Search for contracts and suppliers by simply typing in what you{"'"}re
          looking for. This can be a product, service, supplier, or even
          contract number. We{"'"}ll show you the strongest matches for your
          needs.
        </Typography>
        <Typography size="sm">
          Signing up for a free account lets you see full contract documents,
          email suppliers (
          <Link
            underline={false}
            emphasis={false}
            size="sm"
            href="/about/resources/find-suppliers-on-pavilion"
          >
            our team will even follow up for you!
          </Link>
          ), and access other time-saving features.
        </Typography>
        <Typography size="sm">
          Click{" "}
          <Link
            underline={false}
            emphasis={false}
            size="sm"
            href="/about/resources/pavilion-product-walkthrough"
          >
            here
          </Link>{" "}
          to walk through a 30 second interactive demo to learn the basics.
        </Typography>
      </>
    ),
  },
  {
    title: "How can I get additional help?",
    text: (
      <>
        <Typography size="sm">
          We{"'"}re dedicated to helping you succeed!{" "}
          <Link
            underline={false}
            emphasis={false}
            size="sm"
            href="/about/resources/pavilion-101-a-video-series-to-get-you-started"
          >
            Here
          </Link>{" "}
          is a collection of short, 60-second videos that shows how to use
          Pavilion, including how to narrow down your search with filters, view
          contracts, message suppliers, and get quotes. For more advanced
          features, browse our how-to guides{" "}
          <Link
            underline={false}
            emphasis={false}
            size="sm"
            href="/about/content-type/how-to-guide"
          >
            here
          </Link>
          .
        </Typography>
        <Typography size="sm">
          Have more questions (or want to get your questions answered by a real
          person)? Email <SupportEmailLink size="sm" />, and our team will take
          it from there.
        </Typography>
      </>
    ),
  },
  {
    title:
      "What determines what suppliers and contracts I’m seeing in search results?",
    text: (
      <>
        <Typography size="sm">
          Our algorithm is designed to show the contracts and businesses that
          are most relevant to your search, including scope, geographic
          constraints, compliance restrictions, supplier responsiveness, and
          other factors.
        </Typography>
        <Typography size="sm">
          Don{"'"}t see a supplier or contract that meets your needs? Email{" "}
          <SupportEmailLink size="sm" />, and our team can help you with your
          search.
        </Typography>
      </>
    ),
  },
  {
    title: "How do I know I can use a contract I find on Pavilion?",
    text: (
      <>
        <Typography size="sm">
          Cooperative procurement allows you to use a competitively solicited
          contract from another cooperative or entity – without having to run a
          new solicitation. By default, the contracts you see on Pavilion are
          active, cooperative, and competitively solicited. Each entity has its
          own compliance requirements for using cooperative contracts, so make
          sure you check your entity{"'"}s specific requirements!
        </Typography>
        <Typography size="sm">
          If you{"'"}re a procurement leader, you can customize your entity{"'"}
          s Pavilion experience to help your team and end users adhere to your
          entity{"'"}s policies. You can{" "}
          <Link
            underline={false}
            emphasis={false}
            size="sm"
            href="/about/resources/pre-approved-contract-sources"
          >
            set pre-approved contract sources
          </Link>
          , add built-in reminders of your entity{"'"}s policies, and more.
          Reach out to{" "}
          <SupportEmailLink size="sm" email="partnerships@withpavilion.com" />{" "}
          to get set up!
        </Typography>
      </>
    ),
  },
  {
    title: "What cooperatives and entities do you have contracts for?",
    text: (
      <>
        <Typography size="sm">
          Pavilion aggregates contracts from 400+ cooperatives, state, and local
          contracts, including:
        </Typography>
        <Typography
          component="ul"
          size="sm"
          className="list-disc pl-4 grid gap-2"
        >
          <li>
            Local agencies: Orange County, City of Austin, Maricopa County, City
            of Mesa, Tarrant County, Fort Lauderdale, Miami Dade, Chesterfield
            County, etc.
          </li>
          <li>
            States: Pennsylvania, California, Texas, Florida, Virginia,
            Massachusetts, Washington, Ohio, New York, etc.
          </li>
          <li>
            National purchasing cooperatives: Buyboard, Sourcewell, TIPS,
            HGACBuy, Equalis, Choice Partners, GoodBuy, etc.
          </li>
          <li>
            Regional purchasing cooperatives: Region 19 ESC, GPPCS (AZ), VASCUPP
            (VA), Region 7 ESC, Region 6 ESC, etc.
          </li>
          <li>
            General Services Administration (GSA) multiple award schedule
            contracts available to local entities
          </li>
        </Typography>
      </>
    ),
  },
  {
    title: "How can I add my entity’s contracts to Pavilion?",
    text: (
      <>
        <Typography size="sm">
          By adding your entity{"'"}s contracts to Pavilion, you make sure
          nobody misses a contract they should use. There are 3 ways to add your
          entity{"'"}s contracts to Pavilion:
        </Typography>
        <Typography
          component="ul"
          size="sm"
          className="list-decimal pl-4 grid gap-2"
        >
          <li>
            <span className="font-semibold">
              If your contracts are already online:
            </span>{" "}
            send the link to your contracts to{" "}
            <SupportEmailLink size="sm" email="contracts@withpavilion.com" />.
            We{"'"}ll take it from there!
          </li>
          <li>
            <span className="font-semibold">
              If your contracts are in your e-procurement solution
            </span>
          </li>
          <ul className="list-disc pl-8 grid gap-2">
            <li>
              For OpenGov customers, your public contracts can be automatically
              added and synced to Pavilion. See instructions{" "}
              <Link
                underline={false}
                emphasis={false}
                size="sm"
                href="/about/resources/publish-opengov-contracts"
              >
                here
              </Link>
              .
            </li>
            <li>
              For Bonfire customers, Pavilion can add contracts from your
              "Public Contracts" module. See instructions{" "}
              <Link
                underline={false}
                emphasis={false}
                size="sm"
                href="/about/guides/publish-your-contracts-to-pavilion-using-bonfire"
              >
                here
              </Link>
              .
            </li>
            <li>
              For other e-procurement solutions, reach out to{" "}
              <SupportEmailLink size="sm" email="contracts@withpavilion.com" />{" "}
              for more details.
            </li>
          </ul>
          <li>
            <span className="font-semibold">
              If your contracts are stored in your internal systems (shared
              drive, Sharepoint, etc),
            </span>{" "}
            email{" "}
            <SupportEmailLink size="sm" email="contracts@withpavilion.com" />,
            and our data experts will walk through the process with you.
          </li>
        </Typography>
      </>
    ),
  },
];

export default function BuyerFAQ() {
  return (
    <div className="analytics-buyer-faq grid gap-3 md:gap-10">
      <Typography
        variant="headline"
        size="sm"
        color="brand.boldest.enabled"
        emphasis
      >
        Frequently asked questions
      </Typography>
      <div className="grid gap-4">
        <DisclosureList
          items={faqs}
          ctaClassname="text-left analytics-buyer-faq-cta"
        />
      </div>
    </div>
  );
}
