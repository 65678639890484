/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ALREADY_SELECTED` - Already Selected
 * * `OPEN_TO_ALTERNATIVES` - Open To Alternatives
 * * `NO_SUPPLIERS_IN_MIND` - No Suppliers In Mind
 */
export enum SupplierFlexibilityEnum {
    ALREADY_SELECTED = 'ALREADY_SELECTED',
    OPEN_TO_ALTERNATIVES = 'OPEN_TO_ALTERNATIVES',
    NO_SUPPLIERS_IN_MIND = 'NO_SUPPLIERS_IN_MIND',
}
