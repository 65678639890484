import { GenericCard, Typography } from "../library";
import Modal, { modalSizes } from "../shared/Modal/Modal";

import indefinite from "indefinite";
import listify from "listify";
import pluralize from "pluralize";
import MoneyPileLarge from "../../img/icons/cardboard-boxes-stack.svg";
import MoneyPileSmall from "../../img/icons/cardboard-boxes.svg";
import EnclosedTrailer from "../../img/icons/enclosed-trailer.svg";
import OpenTrailer from "../../img/icons/open-trailer.svg";
import Rocket from "../../img/icons/rocket.svg";
import SearchNoResults from "../../img/icons/search-no-results.svg";
import { type ProQualifications, QualificationTypeEnum } from "../generated";
import { useAddActiveTooltip } from "../jotai/page";
import { getRequestID } from "../utils";
import { postBuyerQualificationSession } from "../utils/api";

interface ProQualificationModalProps {
  hideModal: () => void;
  supplierName: string;
  supplierId: number;
  proQualifications?: ProQualifications;
  solicitationId: Maybe<string>;
}

// This modal imports an svg and should only be lazily loaded.
export default function ProQualificationModal({
  hideModal,
  supplierName,
  supplierId,
  proQualifications,
  solicitationId,
}: ProQualificationModalProps) {
  const addActiveTooltip = useAddActiveTooltip();

  function createQualificationSession(
    meetsCriteria: boolean,
    qualificationType: QualificationTypeEnum
  ) {
    postBuyerQualificationSession({
      supplierName,
      supplierId,
      solicitationId,
      minOrderSize: proQualifications?.minOrderSize,
      qualifiedProducts: proQualifications?.qualifiedProducts,
      qualifiedBrands: proQualifications?.qualifiedBrands,
      meetsCriteria,
      qualificationType,
      searchLogId: getRequestID(),
      daysActive: 1,
    });
    if (!meetsCriteria) {
      addActiveTooltip("otherSuppliers");
    }
    hideModal();
  }

  if (proQualifications?.minOrderSize) {
    return (
      <MinOrderSizeModal
        minOrderSize={proQualifications.minOrderSize}
        supplierName={supplierName}
        createQualificationSession={createQualificationSession}
      />
    );
  }

  if (
    proQualifications?.qualifiedProducts?.length &&
    proQualifications?.qualifiedBrands?.length
  ) {
    return (
      <ScopeModal
        qualifiedProducts={proQualifications.qualifiedProducts}
        qualifiedBrands={proQualifications.qualifiedBrands || []}
        supplierName={supplierName}
        supplierId={supplierId}
        createQualificationSession={createQualificationSession}
        hideModal={hideModal}
      />
    );
  }

  hideModal();
  return null;
}

function MinOrderSizeModal({
  minOrderSize,
  supplierName,
  createQualificationSession,
}: {
  minOrderSize: number;
  supplierName: string;
  createQualificationSession: (
    meetsThreshold: boolean,
    qualificationType: QualificationTypeEnum
  ) => void;
}) {
  const orderSizeText = minOrderSize.toLocaleString();
  return (
    <Modal
      // Assume dismissing the modal means the buyer does not meet the threshold.
      hideModal={() =>
        createQualificationSession(false, QualificationTypeEnum.MIN_ORDER_SIZE)
      }
      title={`Is your purchase above \$${orderSizeText}?`}
      subtitle={`${supplierName} has a minimum order requirement.`}
      contentClassName="grid gap-10"
      modalSize={modalSizes.DEFAULT}
    >
      <div className="grid grid-cols-2 gap-6 p-2">
        <GenericCard
          responsive
          dataTestId="order-size-above-threshold"
          onClick={() =>
            createQualificationSession(
              true,
              QualificationTypeEnum.MIN_ORDER_SIZE
            )
          }
        >
          <div className="flex flex-col gap-y-4 text-center">
            <img src={MoneyPileLarge} className="mx-auto h-[100px]" />
            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="neutral.boldest.enabled"
            >
              Yes, my purchase is above ${orderSizeText}
            </Typography>
          </div>
        </GenericCard>
        <GenericCard
          responsive
          dataTestId="order-size-below-threshold"
          onClick={() =>
            createQualificationSession(
              false,
              QualificationTypeEnum.MIN_ORDER_SIZE
            )
          }
        >
          <div className="flex flex-col gap-y-4 text-center">
            <img src={MoneyPileSmall} className="mx-auto h-[100px]" />
            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="neutral.boldest.enabled"
            >
              No, my purchase is below ${orderSizeText}
            </Typography>
          </div>
        </GenericCard>
      </div>
    </Modal>
  );
}

function ScopeModal({
  qualifiedProducts,
  qualifiedBrands,
  supplierName,
  supplierId,
  createQualificationSession,
  hideModal,
}: {
  qualifiedProducts: string[];
  qualifiedBrands: string[];
  supplierName: string;
  supplierId: number;
  createQualificationSession: (
    meetsThreshold: boolean,
    qualificationType: QualificationTypeEnum
  ) => void;
  hideModal: () => void;
}) {
  const qualifiedProduct = qualifiedProducts[0];
  const qualifiedBrandsText = listify(qualifiedBrands);

  // Only show the trailer icons for American Cargo Group - otherwise, use default icons.
  const showTrailerIcons = supplierId === 385970;

  return (
    <Modal
      // Take no action if the modal is dismissed. We will have tracking from the close-modal tracking.
      hideModal={hideModal}
      title={`Are you looking to purchase ${indefinite(qualifiedProduct)}?`}
      subtitle={`${supplierName} only sells ${pluralize(qualifiedProduct)} from brands ${qualifiedBrandsText}.`}
      contentClassName="grid gap-10"
      modalSize={modalSizes.DEFAULT}
    >
      <div className="grid grid-cols-2 gap-6 p-2">
        <GenericCard
          responsive
          dataTestId="item-in-supplier-scope"
          onClick={() =>
            createQualificationSession(
              true,
              QualificationTypeEnum.SUPPLIER_SCOPE
            )
          }
        >
          <div className="flex flex-col gap-y-4 text-center">
            {showTrailerIcons ? (
              <img src={EnclosedTrailer} className="mx-auto" />
            ) : (
              <img src={Rocket} className="mx-auto h-[100px]" />
            )}

            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="neutral.boldest.enabled"
            >
              Yes, I need to purchase {indefinite(qualifiedProduct)}
            </Typography>
            <Typography variant="body" size="sm">
              Show me this contract.
            </Typography>
          </div>
        </GenericCard>
        <GenericCard
          responsive
          dataTestId="item-not-in-supplier-scope"
          onClick={() =>
            createQualificationSession(
              false,
              QualificationTypeEnum.SUPPLIER_SCOPE
            )
          }
        >
          <div className="flex flex-col gap-y-4 text-center">
            {showTrailerIcons ? (
              <img src={OpenTrailer} className="mx-auto" />
            ) : (
              <img src={SearchNoResults} className="mx-auto h-[100px]" />
            )}
            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="neutral.boldest.enabled"
            >
              No, I need to purchase another kind of item
            </Typography>
            <Typography variant="body" size="sm">
              Direct me to an alternative supplier I can use.
            </Typography>
          </div>
        </GenericCard>
      </div>
    </Modal>
  );
}
