import clsx from "clsx";
import { useAtomValue } from "jotai";
import type { QuoteRequestRequest } from "../../generated/models/QuoteRequestRequest";
import useShowModal from "../../hooks/useShowModal";
import { contractSearchParamsState, requestIDState } from "../../jotai/search";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../library";
import { FormWrapper } from "../../library/form";
import Tag, { TagVariants } from "../../shared/Tag";
import { elevationClass } from "../../utils/designTokens";
import { modals } from "../../utils/enums";
import {
  trackQuoteRequestSidebarCtaClick,
  trackQuoteRequestWelcomePageCtaClick,
} from "../../utils/tracking";
import QuoteRequestRepsOnline from "./QuoteRequestRepsOnline";
import { CARD_FORM_QUESTIONS } from "./constants";

export function QuoteRequestFormCard() {
  const showQuoteRequest = useShowModal(modals.QUOTE_REQUEST);
  const searchParams = useAtomValue(contractSearchParamsState);
  const requestID = useAtomValue(requestIDState);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-5 w-full md:max-w-108 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader"
      >
        Get 3 quotes in 48 hours
      </Typography>
      <Typography color="neutral.boldest.enabled">
        Tell us what you need and your concierge will connect you with qualified
        suppliers.
      </Typography>
      <FormWrapper
        fields={CARD_FORM_QUESTIONS}
        initialValues={{
          description: "",
        }}
        onSubmit={({
          description,
        }: Pick<QuoteRequestRequest, "description">) => {
          trackQuoteRequestSidebarCtaClick({
            searchQuery: searchParams.query,
            requestID,
          });
          showQuoteRequest({ description });
        }}
        submitCta="Get concierge support"
        submitClassName="!w-full analytics-quote-request-sidebar-cta"
      />
      <QuoteRequestRepsOnline />
    </CardContainer>
  );
}

export function QuoteRequestFormBanner() {
  const showQuoteRequest = useShowModal(modals.QUOTE_REQUEST);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className="p-6 grid-flow-col gap-6 rounded-6"
    >
      <div className="grid gap-4">
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
        >
          Get 3 quotes in 48 hours
        </Typography>
        <Typography size="sm" color="neutral.boldest.enabled">
          Tell us what you need and your concierge will connect you with
          qualified suppliers.
        </Typography>
        <Button
          className="w-fit"
          theme={ButtonThemes.SECONDARY_DARK}
          size={ButtonSizes.SMALL}
          onClick={() => {
            trackQuoteRequestWelcomePageCtaClick();
            showQuoteRequest({});
          }}
        >
          Get concierge support
        </Button>
      </div>
      <QuoteRequestRepsOnline size="sm" />
    </CardContainer>
  );
}
