import ncpp from "../../../img/ncpp.jpg";
import { Typography } from "../../library";

export default function Header() {
  return (
    <div className="grid gap-6 md:grid-cols-3 md:gap-10">
      <div className="md:col-span-2 grid gap-2">
        <Typography
          variant="display"
          size="md"
          color="neutral.subtlest.enabled"
        >
          Cost to Procure Calculator
        </Typography>
        <Typography color="neutral.subtlest.enabled">
          Make data-driven sourcing decisions with the Cost to Procure
          Calculator from Pavilion and National Cooperative Procurement Partners
          (NCPP).
        </Typography>
        <Typography color="neutral.subtlest.enabled">
          This calculator helps you quickly estimate the cost of running a
          solicitation vs. a piggyback purchase. Use NCPP's cost data or enter
          your numbers for personalized results.
        </Typography>
      </div>
      <div className="hidden md:col-span-1 md:flex items-center justify-center w-full">
        <img src={ncpp} className="w-[200px] rounded-3" />
      </div>
    </div>
  );
}
