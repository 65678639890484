import type { FormikHelpers, FormikValues } from "formik";
import { useState } from "react";

import contactSavedIcon from "../../../img/icons/contact-saved.svg";
import { AccountsService, type UserSupplierContact } from "../../generated";
import { Link, Typography } from "../../library";
import { InlineFormWrapper } from "../../library/form";
import { PASSWORD_FIELDS } from "../../modals/SignupSteps/constants";
import type { WindowType } from "../../modals/SignupSteps/types";
import useSubmitSupplierInfo from "../../modals/SignupSteps/useSubmitSupplierInfo";
import { handleError } from "../../utils/generatedApi";

interface SupplierContactSavedFormProps {
  contact: UserSupplierContact;
  parentWindow: WindowType;
  supplierHandle: string;
  supplierDisplayName: string;
  sharedLinkId: string;
  onComplete: () => void;
}
export default function SupplierContactSavedForm({
  contact,
  parentWindow,
  supplierHandle,
  supplierDisplayName,
  onComplete,
}: SupplierContactSavedFormProps) {
  const [userExists, setUserExists] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, formErrorMessage, submitSupplierInfo] = useSubmitSupplierInfo({
    email: contact.email,
    onComplete,
    parentWindow,
  });

  async function handleSubmit(
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) {
    setFormLoading(true);
    // Check if supplier already has account
    try {
      const userExistsResponse =
        await AccountsService.accountsCheckExistingUserCreate({
          email: contact.email || "",
        });
      if (userExistsResponse.existingUser) {
        setUserExists(true);
        setFormLoading(false);
        return;
      }
    } catch (err) {
      handleError(err);
      setFormLoading(false);
      return;
    }
    // Create new supplier account
    submitSupplierInfo(
      {
        supplier: {
          handle: supplierHandle,
          displayName: supplierDisplayName,
        },
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        password: values.password,
      },
      helpers
    );
    setFormLoading(false);
  }

  return (
    <div className="flex flex-col gap-6 w-full max-w-96 mx-auto">
      <Typography
        variant="headline"
        size="sm"
        color="brand.default.secondary.enabled"
        emphasis
        className="text-center"
      >
        Contact info updated!
      </Typography>
      <img src={contactSavedIcon} className="mx-auto col-span-1" />

      <Typography
        variant="headline"
        size="xs"
        color="neutral.boldest.enabled"
        emphasis
        className="text-center"
      >
        Create an account and claim your profile
      </Typography>
      <Typography>
        Claiming your profile lets you add links to your site and highlight your
        business to government buyers.
      </Typography>
      <InlineFormWrapper
        fields={[PASSWORD_FIELDS]}
        initialValues={{
          password: "",
        }}
        submitCta="Claim profile"
        onSubmit={handleSubmit}
        submitClassName="analytics-submit-confirmed-supplier-create-account"
        disabled={formLoading || userExists}
      />
      {userExists && (
        <Typography size="sm" color="destructive.default.primary.enabled">
          Looks like you already have a Pavilion account with the email{" "}
          {contact.email}. You can log into your account{" "}
          <Link size="sm" href="https://www.withpavilion.com/accounts/login">
            here
          </Link>
          .
        </Typography>
      )}
      {formErrorMessage && (
        <Typography size="sm" color="destructive.default.primary.enabled">
          {formErrorMessage}
        </Typography>
      )}
    </div>
  );
}
