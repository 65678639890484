import { useEffect, useState } from "react";
import { ApiService, type Project } from "../../../generated";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import { goToURL } from "../../../utils";
import { handleError } from "../../../utils/generatedApi";
import {
  LoadingProjectCard,
  MinimalProjectCard,
} from "../../Project/ProjectCard";
import NoProjectsPlaceholder from "./NoProjectsPlaceholder";

function ProjectsList() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setProjects(await ApiService.apiV1ProjectsList(true));
      } catch (e) {
        handleError(e);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-2 gap-4">
        <LoadingProjectCard />
        <LoadingProjectCard />
      </div>
    );
  }

  if (!projects.length) {
    return <NoProjectsPlaceholder />;
  }

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-2 gap-4">
        {projects.slice(0, 2).map((project) => (
          <MinimalProjectCard key={project.id} {...project} />
        ))}
      </div>
      <Button
        className="w-fit"
        theme={ButtonThemes.SECONDARY_DARK}
        size={ButtonSizes.SMALL}
        onClick={() => goToURL("/projects")}
      >
        View all projects
      </Button>
    </div>
  );
}

export default function ProjectsCard() {
  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className="analytics-projects-card p-6 w-full rounded-6"
    >
      <div className="grid gap-6">
        <Typography
          variant="headline"
          size="xs"
          color="brand.boldest.enabled"
          emphasis
        >
          Review saved contracts and suppliers
        </Typography>
        <ProjectsList />
      </div>
    </CardContainer>
  );
}
