import { atom } from "jotai";
import type {
  CostCalculatorSubmission,
  PurchaseCategoryEnum,
  PurchaserRoleEnum,
} from "../generated";
import type { DropDownPickerOption } from "../library/Dropdown/DropdownPicker";

export const purchaseCategoryOptionsAtom = atom<
  DropDownPickerOption<PurchaseCategoryEnum>[]
>([]);

export const purchaserRoleOptionsAtom = atom<
  DropDownPickerOption<PurchaserRoleEnum>[]
>([]);

export const complexPurchaseCategoriesAtom = atom<PurchaseCategoryEnum[]>([]);

export const complexPurchaseThresholdAtom = atom<number>(0);

export const DEFAULT_COST_CALCULATOR_SUBMISSION = {
  id: -1,
  solicitationDeliverableCostCents: 0,
  solicitationStaffCostCents: 0,
  piggybackDeliverableCostCents: 0,
  piggybackStaffCostCents: 0,
};

export const costCalculatorSubmissionAtom = atom<
  Pick<
    CostCalculatorSubmission,
    | "id"
    | "solicitationDeliverableCostCents"
    | "solicitationStaffCostCents"
    | "piggybackDeliverableCostCents"
    | "piggybackStaffCostCents"
  >
>({
  ...DEFAULT_COST_CALCULATOR_SUBMISSION,
});

export const costCalculatorSubmissionIsNewAtom = atom(
  (get) => get(costCalculatorSubmissionAtom).id === -1
);
