import clsx from "clsx";

import searchVolumeMap from "../../../../img/welcome/searchVolumeMap.png";
import { Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";

export default function SearchSessions({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        "analytics-search-sessions flex flex-col gap-6 p-6 rounded-8 justify-between",
        bgColorClass.brand.subtlest.enabled,
        className
      )}
    >
      <Typography variant="headline" size="xs" color="brand.boldest.enabled">
        Search sessions
      </Typography>
      {/*TODO: Replace image with transparent background version*/}
      <img alt="search volume density map" src={searchVolumeMap} />
      <div className="flex flex-col gap-2 p-4 rounded-4 bg-white">
        <Typography variant="display" size="xl" color="brand.bold.enabled">
          20,000
        </Typography>
        <Typography size="xl">searches per month</Typography>
      </div>
    </div>
  );
}
