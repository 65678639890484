import paperPlaneSparkles from "../../img/welcome/paperPlaneSparkles.svg";
import { Button, type ButtonThemes, Typography } from "../library";
import type { TypographySize } from "../library/Typography/types";
import Modal, { modalSizes } from "../shared/Modal/Modal";

type SecondaryCtaProps = {
  ctaText: string;
  onClick: () => void;
  buttonVariant?: ButtonThemes;
};

interface ConfirmationModalProps {
  hideModal: () => void;
  title?: string;
  titleSize?: TypographySize;
  subtitle?: string;
  ctaText?: string;
  hidePlaneSparkles?: boolean;
  secondaryCtaProps?: SecondaryCtaProps;
}

// This modal imports an svg and should only be lazily loaded.
export default function ConfirmationModal({
  hideModal,
  title,
  titleSize,
  subtitle,
  ctaText,
  hidePlaneSparkles,
  secondaryCtaProps,
}: ConfirmationModalProps) {
  return (
    <Modal
      hideModal={hideModal}
      title={title}
      contentClassName="grid gap-10"
      titleSize={titleSize}
      modalSize={modalSizes.SMALL}
    >
      <div className="flex flex-col gap-6">
        {!hidePlaneSparkles && (
          <img
            src={paperPlaneSparkles}
            className="w-[18.6875rem] self-center"
          />
        )}
        <Typography>{subtitle}</Typography>
      </div>
      <div className="grid gap-6">
        <Button className="w-full" onClick={hideModal} dataTestId="modal-cta">
          {ctaText}
        </Button>
        {secondaryCtaProps && (
          <Button
            className="w-full"
            onClick={secondaryCtaProps.onClick}
            theme={
              secondaryCtaProps.buttonVariant ?? Button.themes.SECONDARY_DARK
            }
          >
            {secondaryCtaProps.ctaText}
          </Button>
        )}
      </div>
    </Modal>
  );
}
