import ArrowBackwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useAtomValue } from "jotai";
import { buyerProfileState } from "../../jotai/user";
import { Button, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import PublicEntityImage from "../../shared/PublicEntityImage";
import ShareableContractImage from "../../shared/ShareableContractImage";

interface BuyerSignupWelcomeModalProps {
  hideModal: () => void;
  onComplete: () => void;
}

export default function BuyerSignupWelcomeModal({
  hideModal,
  onComplete,
}: BuyerSignupWelcomeModalProps) {
  const buyerProfile = useAtomValue(buyerProfileState);
  const blaName = buyerProfile.governmentAffiliationDisplayName;

  return (
    <Modal
      hideModal={() => {
        onComplete();
        hideModal();
      }}
      isBlocking={false}
      modalSize={modalSizes.DEFAULT}
      className="bg-gradient-to-bl from-violet-200 via-white to-violet-200"
    >
      <Typography
        variant="display"
        size="md"
        color="brand.default.secondary.enabled"
        className="text-center"
      >
        Welcome to Pavilion!
      </Typography>
      <div className="flex grid grid-cols-2 gap-4 mt-4">
        <div className="col-span-1">
          <div className="flex">
            <PublicEntityImage className="w-28 h-28 mx-auto col-span-1" />
          </div>
          <Typography
            variant="meta"
            color="neutral.bolder.enabled"
            className="text-center"
          >
            Over 100,000 shareable contracts
            {blaName?.length ? ` from entities like ${blaName}` : ""}.
          </Typography>
        </div>
        <div className="col-span-1">
          <div className="flex">
            <ShareableContractImage className="w-28 h-28 mx-auto col-span-1" />
          </div>
          <Typography
            variant="meta"
            color="neutral.bolder.enabled"
            className="text-center"
          >
            Over 400+ national, state, and local contract sources for
            commodities, services and software.
          </Typography>
        </div>
      </div>

      <div className="flex justify-center mt-8">
        <Button
          theme={Button.themes.SECONDARY_DARK}
          onClick={() => {
            window.location.href = "/welcome";
          }}
          dataTestId="go-to-profile-button"
          className="mr-4 analytics-buyer-signup-welcome-modal-start-search"
        >
          Start a new search
        </Button>
        <Button
          theme={Button.themes.PRIMARY_DARK}
          onClick={onComplete}
          dataTestId="continue-button"
          className="analytics-buyer-signup-welcome-modal-continue"
          badgeProps={{
            Icon: ArrowBackwardRoundedIcon,
            reverse: true,
          }}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
}
