import { createRoot } from "react-dom/client";

import OurProductPage from "../components/About/OurProductPage";
import { getDOMAnchorById } from "../utils";

import Page, { type AuthPageProps } from "./Page/Page";

export default function OurProduct({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: AuthPageProps) {
  return (
    <Page
      searchSource="our-product-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <OurProductPage />
    </Page>
  );
}

const container = getDOMAnchorById("our-product");
if (container) {
  const root = createRoot(container);
  root.render(<OurProduct />);
}
