// account-inactive-container
import { createRoot } from "react-dom/client";

import { Typography } from "../../library";
import { getDOMAnchorById } from "../../utils";
import { SUPPORT_EMAIL_ADDRESS } from "../../utils/constants";
import { PageType } from "../../utils/enums";
import { generateHrefForEmail } from "../../utils/format";
import Page from "../Page/Page";

export default function InactiveAccount() {
  return (
    <Page
      pageId=""
      pageType={PageType.ACCOUNT}
      showHeader
      searchSource="inactive-account-search-bar"
      isAuthenticated={false}
      profileType={null}
      activeAgreements={null}
      isSSR={false}
    >
      <div className="cp-page-container mt-20">
        <Typography className="mb-6" variant="headline" size="lg">
          Inactive account
        </Typography>
        <Typography className="mb-6">
          Our records show that your account is inactive. If this was a mistake,
          contact{" "}
          <a href={generateHrefForEmail(SUPPORT_EMAIL_ADDRESS)}>
            {SUPPORT_EMAIL_ADDRESS}
          </a>{" "}
          for additional help.
        </Typography>
      </div>
    </Page>
  );
}

const container = getDOMAnchorById("account-inactive-container");
if (container) {
  const root = createRoot(container);
  root.render(<InactiveAccount />);
}
