import { useState } from "react";

import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { getParams } from "../../utils";
import {
  PopupType,
  accountModals,
  modalTriggerURLParams,
} from "../../utils/enums";

import { useSetAtom } from "jotai";
import { redirectOnComplete } from "../../components/Account/helpers";
import useShowModal from "../../hooks/useShowModal";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import { sessionPopupState } from "../../jotai/page";
import { isFeatureEnabled } from "../../utils/split";
import CompleteBuyerAccountForm from "./CompleteBuyerAccountForm";
import RedesignedSignupBase, { SignupThemes } from "./RedesignedSignupBase";
import RedesignedBuyerSignupForm from "./RedesignedSignupBase/RedesignedBuyerSignupForm";
import { type InferredBLA, WindowType } from "./types";

interface BuyerSignupModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  inferredBLA?: Maybe<InferredBLA>;
  inviteBLA?: Maybe<InferredBLA>;
}

function getTitle(invite: string | undefined, showConfirmation: boolean) {
  if (showConfirmation) {
    return {
      title: "Are you a supplier?",
      subtitle:
        "It seems like you may be a supplier! With a supplier account, you can provide key information about your business to help us match your contracts with relevant searches.",
    };
  }
  return {
    title: invite ? "Complete your profile" : "Welcome to Pavilion!",
    subtitle:
      "We just need a little bit more information before we can create your account. This allows us to give you a tailored experience on Pavilion.",
  };
}

export default function BuyerSignupModal({
  hideModal,
  onComplete,
  goBack,
  isBlocking = false,
  inferredBLA = null,
  inviteBLA = null,
}: BuyerSignupModalProps) {
  const showWelcomeModal = useShowModal(accountModals.BUYER_SIGNUP_WELCOME);
  const invite = getParams()[modalTriggerURLParams.INVITE]?.toString();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { title, subtitle } = getTitle(invite, showConfirmation);
  const setSessionPopupState = useSetAtom(sessionPopupState);
  const showSignupSurvey = useShowSignupSurvey();
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");

  function goToBuyerSignupWelcome() {
    showWelcomeModal({
      onComplete,
    });
  }

  // Once user has completed the signup step, their account is created.
  // Hence, this modal should be blocking and user should be forced to trigger a refresh via "Skip"
  function goToBuyerSurveyModal() {
    showSignupSurvey({ onComplete: goToBuyerSignupWelcome });
  }

  if (hasRedesign) {
    // TODO: Show different success message depending on entrypoint:
    // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
    const successMessage =
      "🎉 Account creation complete! You can now view all contracts on Pavilion.";
    return (
      <Modal
        hideModal={hideModal}
        modalSize={modalSizes.LARGE}
        className="analytics-signup-modal overflow-hidden"
        isBlocking={isBlocking}
        goBack={goBack}
        ignorePadding
      >
        <RedesignedSignupBase
          theme={SignupThemes.WEBSITE}
          accountModal={accountModals.BUYER_SIGN_UP}
        >
          <RedesignedBuyerSignupForm
            inviteBLA={inviteBLA}
            onComplete={(redirectUrl: string) => {
              hideModal();
              setSessionPopupState({
                analyticsClassName: "analytics-buyer-signup-success",
                name: PopupType.SUCCESS,
                children: successMessage,
                show: true,
              });
              redirectOnComplete(redirectUrl);
            }}
            parentWindow={WindowType.Modal}
          />
        </RedesignedSignupBase>
      </Modal>
    );
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-signup-modal"
      // Allow scrolling on smaller screens so user can select dropdown items
      contentClassName="no-scrollbar"
      title={title}
      subtitle={subtitle}
      modalSize={showConfirmation ? modalSizes.DEFAULT : modalSizes.SMALL}
      showLogoHeader={false}
      goBack={goBack}
    >
      <CompleteBuyerAccountForm
        hideModal={hideModal}
        onComplete={goToBuyerSurveyModal}
        inferredBLA={inferredBLA}
        onChangeConfirmation={setShowConfirmation}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
