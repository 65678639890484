import useShowModal from "../../../hooks/useShowModal";
import { Button, ButtonSizes } from "../../../library";
import { modals } from "../../../utils/enums";

export default function AddPiggybackedContractsButton() {
  const showModal = useShowModal(modals.RECENTLY_VIEWED_CONTRACTS_MODAL);

  function addContracts() {
    showModal({});
  }

  return (
    <Button
      size={ButtonSizes.SMALL}
      className="w-fit"
      onClick={addContracts}
      dataTestId="add-contracts-button"
    >
      Add Contracts
    </Button>
  );
}
