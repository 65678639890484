import { useAtomValue } from "jotai";
import cloudFiles from "../../../../img/icons/contracts-cloud.svg";
import useShowModal from "../../../hooks/useShowModal";
import { recentlyViewedContractsState } from "../../../jotai/entityContracts";
import { userEmailState } from "../../../jotai/user";
import { Bullets, Button, ButtonSizes, Typography } from "../../../library";
import { modals } from "../../../utils/enums";
import { trackGetHelpPiggybacking } from "../../../utils/tracking";
import AddContractsButton from "./AddPiggybackedContractsButton";

export default function NoPiggybackedContractsInfo() {
  const { contracts } = useAtomValue(recentlyViewedContractsState);
  const userEmail = useAtomValue(userEmailState);
  const showGetHelpSuccessModal = useShowModal(modals.GET_HELP_SUCCESS_MODAL);

  function handleGetHelp() {
    trackGetHelpPiggybacking({ userEmail });
    showGetHelpSuccessModal({});
  }

  return (
    <div className="flex flex-col md:flex-row gap-x-16 gap-y-3 items-center md:items-start p-6 mb-[4.5rem] rounded-4 shadow-1 bg-gradient-to-r from-cp-cyan-100 to-cp-violet-300">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <Typography variant="headline" size="sm" emphasis>
            Add contracts your entity is piggybacking on
          </Typography>
          <div className="flex flex-col">
            <Typography color="neutral.boldest.enabled">
              Pavilion helps you manage contracts your entity has piggybacked
              on, so you can:
            </Typography>
            <Bullets
              columns={1}
              capitalize={false}
              itemsList={[
                "Help your team purchase from these contracts again",
                "Get ahead of expiring contracts",
                "Manage contract due diligence materials in one place",
              ]}
            />
          </div>
        </div>
        {contracts.length ? (
          <AddContractsButton />
        ) : (
          <Button
            size={ButtonSizes.SMALL}
            onClick={handleGetHelp}
            className="w-fit"
            dataTestId="get-help-cta"
          >
            I'm interested
          </Button>
        )}
      </div>
      <img
        src={cloudFiles}
        alt="Contract documents"
        className="w-[212px] h-[124px] hidden md:block"
      />
    </div>
  );
}
