import { useAtomValue, useSetAtom } from "jotai";

import { userTypeSignupState } from "../../jotai/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import {
  PopupType,
  accountModals,
  loginSignupAccountTypes,
} from "../../utils/enums";

import { redirectOnComplete } from "../../components/Account/helpers";
import useShowModal from "../../hooks/useShowModal";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import { sessionPopupState } from "../../jotai/page";
import { isFeatureEnabled } from "../../utils/split";
import PostSocialAuthForm from "./PostSocialAuthForm";
import RedesignedSignupBase, { SignupThemes } from "./RedesignedSignupBase";
import RedesignedPostSocialAuthForm from "./RedesignedSignupBase/PostSocialAuth/RedesignedPostSocialAuthForm";
import { WindowType } from "./types";

const subtitle = {
  buyer:
    "What public agency do you work for? This allows us to give you a tailored experience on Pavilion.",
  supplier:
    "What business do you work for? This allows us to give you a tailored experience on Pavilion.",
};

interface PostSocialAuthModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  provider?: string;
  userEmail?: string;
  firstName?: string;
}
export default function PostSocialAuthModal({
  hideModal,
  onComplete,
  isBlocking = false,
  firstName,
  provider,
  userEmail,
}: PostSocialAuthModalProps) {
  const userType = useAtomValue(userTypeSignupState);
  const setSessionPopupState = useSetAtom(sessionPopupState);
  const showWelcomeModal = useShowModal(accountModals.BUYER_SIGNUP_WELCOME);
  const showSignupSurvey = useShowSignupSurvey();
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");
  const isSupplier = userType === loginSignupAccountTypes.SUPPLIER;
  function goToBuyerSignupWelcome() {
    showWelcomeModal({
      onComplete,
    });
  }

  // Once user has completed the signup step, their account is created.
  // Hence, this modal should be blocking and user should be forced to trigger a refresh via "Skip"
  function onCompleteBuyerSignup() {
    showSignupSurvey({ onComplete: goToBuyerSignupWelcome });
  }

  function onCompleteSupplierSignup(redirectUrl: string) {
    showSignupSurvey({ onComplete: () => redirectOnComplete(redirectUrl) });
  }

  function onCompleteSignup({
    analyticsClassName,
    successMessage,
    redirectUrl,
  }: {
    analyticsClassName: string;
    successMessage: string;
    redirectUrl: string;
  }) {
    hideModal();
    setSessionPopupState({
      analyticsClassName,
      name: PopupType.SUCCESS,
      children: successMessage,
      show: true,
    });
    redirectOnComplete(redirectUrl);
  }

  if (hasRedesign) {
    return (
      <Modal
        hideModal={hideModal}
        isBlocking={isBlocking}
        className="analytics-login-modal account overflow-hidden"
        contentClassName="no-scrollbar"
        formID="analytics-login-modal-submit"
        modalSize={modalSizes.LARGE}
        ignorePadding
      >
        <RedesignedSignupBase
          theme={isSupplier ? SignupThemes.BUILDING : SignupThemes.WEBSITE}
          headline={isSupplier ? "Win more government business—for free" : ""}
          text={
            isSupplier
              ? "10,000 public sector buyers find suppliers on Pavilion.\n\nGet in front of public agencies looking to buy and get leads that close, fast—all for free."
              : ""
          }
          accountModal={accountModals.SIGNUP_POST_SOCIAL_AUTH}
        >
          <RedesignedPostSocialAuthForm
            userEmail={userEmail}
            provider={provider}
            onComplete={onCompleteSignup}
            parentWindow={WindowType.Modal}
          />
        </RedesignedSignupBase>
      </Modal>
    );
  }

  return (
    <Modal
      isBlocking={isBlocking}
      hideModal={hideModal}
      className="analytics-post-sociallogin-modal inline-flex items-center"
      title={firstName ? `Welcome, ${firstName}!` : "Welcome!"}
      subtitle={subtitle[userType]}
      modalSize={modalSizes.SMALL}
      showLogoHeader
    >
      <PostSocialAuthForm
        onComplete={
          userType === "buyer"
            ? onCompleteBuyerSignup
            : onCompleteSupplierSignup
        }
        provider={provider}
        userEmail={userEmail}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
