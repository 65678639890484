export enum MessageSupplierSource {
  CONTRACT = "CONTRACT",
  SUPPLIER = "SUPPLIER",
  RECOMMENDATION = "RECOMMENDATION",
  PROJECT = "PROJECT",
  BUYER_OPT_IN = "BUYER_OPT_IN",
  SUPPLIER_SERP = "SUPPLIER_SERP",
  CONTRACT_SERP = "CONTRACT_SERP",
  SUPPLIER_MULTIQUOTE = "SUPPLIER_MULTIQUOTE",
}
