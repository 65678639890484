import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useMemo } from "react";

import { BuyerProfileTypeEnum } from "../../../generated";
import { buyerProfileState, userState } from "../../../jotai/user";
import { buyerWelcomePageState } from "../../../jotai/welcome";
import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  PageSection,
  Typography,
} from "../../../library";
import { ContractIcon } from "../../../shared/CustomIcons/ContractIcon";
import SearchBarCard from "../../../shared/SearchBarCard";
import { recommendedSearchesByAgencyType } from "../../../shared/SuggestedSearches";
import { bgColorClass, textColorClass } from "../../../utils/colors";
import { SUPPORT_EMAIL_ADDRESS } from "../../../utils/constants";
import { QuoteRequestFormBanner } from "../../QuoteRequest/QuoteRequestCard";
import BuyerFAQ from "./BuyerFAQ";
import PartnershipUpsellCard from "./PartnershipUpsellCard";
import ProjectsCard from "./ProjectsCard";
import QuickLinks from "./QuickLinks";
import { useRegionalManager } from "./useRegionalManager";

function BuyerSummary() {
  const { firstName } = useAtomValue(userState);
  const { governmentAffiliationDisplayName } = useAtomValue(buyerProfileState);
  const { numContracts, numTeamMembers } = useAtomValue(buyerWelcomePageState);

  return (
    <>
      <Typography
        variant="display"
        className="sm:text-cp-display-md text-cp-display-sm"
        color="brand.boldest.enabled"
      >
        Welcome{firstName ? `, ${firstName}` : ""}
      </Typography>
      <div className="flex flex-wrap gap-y-1 gap-x-2 lg:mb-4">
        <Badge
          size="sm-md"
          Icon={AccountBalanceOutlined}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {governmentAffiliationDisplayName}
          </Typography>
        </Badge>
        <div>·</div>
        <Badge
          size="sm-md"
          Icon={ContractIcon}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {numContracts} {pluralize("contract", numContracts)}
          </Typography>
        </Badge>
        <div>·</div>
        <Badge
          size="sm-md"
          Icon={GroupRoundedIcon}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {numTeamMembers} team {pluralize("member", numTeamMembers)}
          </Typography>
        </Badge>
      </div>
      <QuickLinks />
    </>
  );
}

export default function BuyerProfilePage() {
  const { buyerProfileType, governmentAgency } =
    useAtomValue(buyerProfileState);
  const { recentSearches } = useAtomValue(buyerWelcomePageState);
  const suggestedSearches = useMemo(() => {
    if (recentSearches.length) return recentSearches;

    const agencyType = governmentAgency?.agencyType || "";
    const recommendedSearchKey =
      agencyType && agencyType in recommendedSearchesByAgencyType
        ? agencyType
        : "DEFAULT";
    return recommendedSearchesByAgencyType[recommendedSearchKey];
  }, [recentSearches, governmentAgency]);

  // TODO(sc-25697): This feature gating should happen on the backend, not here.
  const regionalManager = useRegionalManager();
  const isTargetRole =
    buyerProfileType === BuyerProfileTypeEnum.LEADERSHIP && !!regionalManager;

  return (
    <div>
      <PageSection>
        <div className="absolute -z-1 top-20 left-0 right-0 h-[14rem] bg-gradient-to-b from-cp-persimmon-50 to-cp-white-100" />
        <div className="py-10 grid gap-6 grid-cols-12">
          <div className="grid gap-3 col-span-full lg:col-span-4 h-fit">
            <BuyerSummary />
          </div>
          <div className="grid gap-6 col-span-full lg:col-span-8">
            <SearchBarCard
              suggestedSearchTerms={suggestedSearches}
              suggestedSearchTitle={
                recentSearches.length > 1
                  ? "Recent searches"
                  : "Example searches"
              }
            />
            {isTargetRole && <PartnershipUpsellCard />}
            <QuoteRequestFormBanner />
            {!isTargetRole && <ProjectsCard />}
          </div>
        </div>
      </PageSection>
      <PageSection className={bgColorClass.brand.subtlest.enabled}>
        <div className="grid py-10 gap-6 grid-cols-12">
          <div className="grid gap-6 col-span-full md:col-span-4 order-last md:order-first h-fit">
            <div className="grid gap-2">
              <Typography
                variant="headline"
                size="sm"
                color="brand.default.secondary.enabled"
                emphasis
              >
                Need Help?
              </Typography>
              <Typography size="sm" color="neutral.boldest.enabled">
                Schedule time to talk to our team.
              </Typography>
              <Button
                as={Link}
                className="analytics-buyer-profile-get-help w-fit"
                theme={ButtonThemes.SECONDARY_DARK}
                linkProps={{
                  href: `mailto:${SUPPORT_EMAIL_ADDRESS}`,
                  underline: false,
                }}
                size={ButtonSizes.SMALL}
              >
                Get help
              </Button>
            </div>
          </div>
          <div className="grid col-span-full md:col-span-8">
            <BuyerFAQ />
          </div>
        </div>
      </PageSection>
    </div>
  );
}
