import shoppingCart from "../../../img/icons/shoppingCart.svg";

interface ShoppingCartImageProps {
  className?: string;
}
export default function ShoppingCartImage({
  className,
  ...rest
}: ShoppingCartImageProps) {
  return (
    <img
      src={shoppingCart}
      alt="shopping cart"
      className={className}
      {...rest}
    />
  );
}
