import { useAtom } from "jotai";
import { useEffect } from "react";
import contactQuestion from "../../../img/icons/contact-question.png";
import contractsBell from "../../../img/icons/contracts-bell.svg";
import contractsHeart from "../../../img/icons/contracts-heart.svg";
import {
  ApiService,
  type BuyerIntentSurvey,
  SupplierFlexibilityEnum,
} from "../../generated";
import type { OnCompleteFn } from "../../hooks/useSearchIntentSurvey";
import { userStateState } from "../../jotai/user";
import { GenericCard, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { handleError } from "../../utils/generatedApi";
import { isFeatureEnabled } from "../../utils/split";
import { trackClickSearchIntentSupplierFlexibility } from "../../utils/tracking";

interface SearchIntentSupplierFlexibilityModalProps {
  hideModal: () => void;
  intentSurvey: BuyerIntentSurvey;
  onComplete: OnCompleteFn;
}

const supplierFlexibilities = [
  {
    flexibility: SupplierFlexibilityEnum.OPEN_TO_ALTERNATIVES,
    image: contractsHeart,
    text: "I've already selected a supplier",
    dataTestId: "flexibility-selected",
  },
  {
    flexibility: SupplierFlexibilityEnum.ALREADY_SELECTED,
    image: contractsBell,
    text: "I have a supplier in mind, but open to better options",
    dataTestId: "flexibility-open",
  },
  {
    flexibility: SupplierFlexibilityEnum.NO_SUPPLIERS_IN_MIND,
    image: contactQuestion,
    text: "No suppliers in mind yet",
    dataTestId: "flexibility-none-selected",
  },
];

export default function SearchIntentSupplierFlexibilityModal({
  hideModal,
  intentSurvey,
  onComplete,
}: SearchIntentSupplierFlexibilityModalProps) {
  const [userState, setUserState] = useAtom(userStateState);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run this once on mount.
  useEffect(() => {
    if (userState.showIntentSurvey) {
      // When viewed, update the last viewed date to avoid showing the modal frequently.
      (async () => {
        try {
          await ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", {
            lastViewedIntentSurveyAt: new Date().toISOString(),
          });
          setUserState({ ...userState, showIntentSurvey: false });
        } catch (e) {
          handleError(e);
        }
      })();
    }
  }, []);

  async function handleSubmit(supplierFlexibility: SupplierFlexibilityEnum) {
    trackClickSearchIntentSupplierFlexibility({
      supplierFlexibility,
    });
    try {
      await ApiService.apiV1IntentSurveysPartialUpdate(intentSurvey.id, {
        supplierFlexibility,
      });
    } catch (e) {
      handleError(e);
    }
    // No filters for non-researching intents
    onComplete({});
    hideModal();
  }

  if (isFeatureEnabled("elasticIntentSurveyReverse")) {
    supplierFlexibilities.reverse();
  }

  return (
    <Modal
      modalSize={modalSizes.LARGE}
      className="search-intent-supplier-flexibility-modal"
      title="Where are you in your supplier research?"
      subtitle="Help us improve the product for buyers everywhere."
      hideModal={() => {
        onComplete({});
        hideModal();
      }}
      // overflow-visible prevents the GenericCard dropshadow from being clipped
      contentClassName="grid grid-cols-3 gap-6 !overflow-visible"
    >
      {supplierFlexibilities.map(({ flexibility, dataTestId, image, text }) => (
        <GenericCard
          key={flexibility}
          responsive
          className="!p-4 flex flex-col gap-4 text-center items-center"
          onClick={() => handleSubmit(flexibility)}
          dataTestId={dataTestId}
        >
          <img src={image} className="w-fit h-24" />
          <Typography
            color="neutral.boldest.enabled"
            variant="headline"
            size="xs"
            emphasis
          >
            {text}
          </Typography>
        </GenericCard>
      ))}
    </Modal>
  );
}
