import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState } from "react";

import { useAtomValue } from "jotai";
import contractDocuments from "../../../../img/icons/contracts-bell.svg";
import {
  piggybackedContractsLoadingState,
  piggybackedContractsState,
} from "../../../jotai/entityContracts";
import { Badge, Typography } from "../../../library";
import LoadingHit from "../../../shared/SearchPage/SearchHit/LoadingHit";
import AddContractsButton from "./AddPiggybackedContractsButton";
import NoPiggybackedContractsInfo from "./NoPiggybackedContractsInfo";
import PiggybackedContractsTable from "./PiggybackedContractsTable";
import usePiggybackedContracts from "./usePiggybackedContracts";

export default function PiggybackedContractsSection() {
  const piggybackedContracts = useAtomValue(piggybackedContractsState);
  const isLoading = useAtomValue(piggybackedContractsLoadingState);
  const [showBanner, setShowBanner] = useState(true);
  // calling the hook also fetches the piggybacked contracts from the API
  const {
    removePiggybackedContract,
    addPiggybackSuccessPopupEntityPage,
    piggybackErrorPopup,
  } = usePiggybackedContracts();

  if (isLoading) return <LoadingHit />;

  if (!piggybackedContracts.length) return <NoPiggybackedContractsInfo />;

  return (
    <div className="flex flex-col items-end gap-10">
      {showBanner && (
        <div className="flex flex-col md:flex-row items-center md:items-start gap-x-16 gap-y-4 rounded-4 p-6 w-full shadow-1 bg-gradient-to-r from-cp-persimmon-80 to-cp-violet-300 relative">
          <div
            className="absolute top-6 right-6 cursor-pointer transition ease-in-out duration-100 hover:bg-cp-lapis-100 w-6 h-6"
            onClick={() => setShowBanner(false)}
          >
            <Badge
              Icon={CloseRoundedIcon}
              iconClass="text-cp-black-100 mr-[0px]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <Typography
              variant="headline"
              size="sm"
              emphasis
              color="brand.boldest.enabled"
            >
              Get ahead of expiring contracts
            </Typography>
            <Typography color="neutral.bolder.enabled">
              Email notifications are sent out 6 months before contracts expire.
              That’s more time for you to find or create other contract vehicles
              in advance to meet your needs.
            </Typography>
          </div>
          <img
            src={contractDocuments}
            alt="Contract documents"
            className="w-[112px] h-[89px]"
          />
        </div>
      )}
      <AddContractsButton />
      <div className="flex flex-col w-full overflow-x-scroll">
        <PiggybackedContractsTable
          tableRows={piggybackedContracts}
          removeContract={removePiggybackedContract}
          isLoading={isLoading}
        />
      </div>
      {addPiggybackSuccessPopupEntityPage}
      {piggybackErrorPopup}
    </div>
  );
}
