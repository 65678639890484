import { useField } from "formik";

import { useEffect } from "react";
import CostCalculatorFormCard from "./CostCalculatorFormCard";
import { type FieldHookProps, usePurchaserRoleFields } from "./constants";

export default function PurchaserRoleCard(props: FieldHookProps) {
  const fields = usePurchaserRoleFields(props);
  const [{ value: purchaserRole }] = useField("purchaserRole");
  const [, , { setValue: setPurchaserRoleSalary }] = useField<string | null>(
    "purchaserRoleSalary"
  );

  useEffect(() => {
    if (purchaserRole !== "default") return;
    setPurchaserRoleSalary(null);
  }, [purchaserRole, setPurchaserRoleSalary]);

  return (
    <CostCalculatorFormCard
      title="Staff salary rates - optional"
      subtitle="Staff salaries are another key cost factor. Public sector salaries vary by region and entity. You can skip this section to use NCPP's national salary estimates based on industry averages, or enter data for your role and we'll scale all of NCPP's salary estimates accordingly."
      fields={fields}
    />
  );
}
