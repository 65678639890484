import { Bullets, DisclosureList, Link, Typography } from "../../../library";
import SupportEmailLink from "../../../shared/SupportEmailLink";
import { getWalkthroughLink } from "../../../utils";

const faqs = [
  {
    title: "What is Pavilion?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Pavilion is purpose-built to help public servants work smarter by
        providing access⏤for free⏤to a marketplace of over 100,000 shareable
        contracts from local entities, states, and national purchasing
        cooperatives.
      </Typography>
    ),
  },
  {
    title: "How do I get started using Pavilion?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        <p className="pb-2">
          You can use Pavilion to <Link href="/">find shareable contracts</Link>{" "}
          by product, service, supplier, keywords, or even contract number. Our
          proprietary technology aggregates, organizes, and searches across a
          vast amount of relevant data to help you find the results that are
          most relevant for your entity’s needs. Since Pavilion is free, you can
          get started right away⏤no implementation or agreement required!
        </p>

        <p>
          If you’re looking for help getting started, or want to see how you can
          make the most of Pavilion’s free technology, please{" "}
          <Link href={getWalkthroughLink("faqcta", "website")}>
            schedule a walkthrough
          </Link>{" "}
          today!
        </p>
      </Typography>
    ),
  },
  {
    title: "Does Pavilion cost money to use?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        No! Pavilion is free to public entities and we intend to keep it that
        way, period. There is no fee to access contract data or use Pavilion.
      </Typography>
    ),
  },
  {
    title: "What kind of shareable contracts are discoverable on Pavilion?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Pavilion has the largest dataset of shareable public entity contracts
        nationwide, including:
        <Bullets
          columns={1}
          itemsList={[
            "Local contracts from public entities across the country such as City of Austin, TX; Orange County, CA; King County, WA; City of Tucson, AZ, and many, many more!",
            "Contracts from state entities",
            "Shareable contracts from top national purchasing cooperatives including Sourcewell, NASPO ValuePoint, OMNIA Partners, and more",
            "General Services Administration (GSA) multiple award schedule contracts available to local entities",
          ]}
        />
      </Typography>
    ),
  },
  {
    title:
      "Where does the data on Pavilion come from? How often is it updated?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        <p className="pb-2">
          In addition to collecting information that is publicly available from
          hundreds of public entities across the country, Pavilion partners with
          cooperative purchasing organizations and public entities to directly
          update the data on our platform.
        </p>

        <p className="pb-2">
          While all public purchasing data is a matter of public record, not all
          purchasing data is easy to find, well organized, or readily
          accessible. Pavilion leverages proprietary technology to aggregate,
          organize, and make shareable contract data searchable in a
          user-friendly way.
        </p>

        <p className="pb-2">
          Our contract data is refreshed on a regular cadence, so you&apos;ll
          always have up-to-date information. If you&apos;d like to see a new
          source added to Pavilion, please let us know.
        </p>
      </Typography>
    ),
  },
  {
    title: "How can I contribute my entity’s contracts?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        We’d love to add your contracts to Pavilion to make them searchable by
        other public entities! Please email <SupportEmailLink /> to get started.
      </Typography>
    ),
  },
  {
    title: "Can I add Pavilion’s search bar to my entity’s website?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        Yes! We’d be happy to add a custom search bar to your website to allow
        your users to find shareable contracts from your page. You can see
        examples of what this looks like on{" "}
        <Link href="https://cpo.ocgov.com/oc-procurement-alliance">
          Orange County, CA’s website
        </Link>{" "}
        and{" "}
        <Link href="https://www.esc18.net/354585_2">
          Region ESC 18’s website
        </Link>
        . To get started, please email <SupportEmailLink />.
      </Typography>
    ),
  },
  {
    title:
      "How is Pavilion free for public entities? How does Pavilion make money?",
    text: (
      <Typography color="brand.default.secondary.enabled">
        As a mission-driven technology company founded by former public servants
        and technologists, we decided to raise venture capital from private
        technology investors so we could focus on building the best tool for
        public entities ahead of monetization. In the future, we expect to
        monetize the supplier side of our marketplace, so we can keep our search
        open and totally accessible for public entities, always.
      </Typography>
    ),
  },
];

export default function BuyerFAQ() {
  return (
    <div className="flex flex-col gap-6">
      <Typography variant="headline" color="brand.default.secondary.enabled">
        Frequently Asked Questions
      </Typography>
      <DisclosureList items={faqs} />
    </div>
  );
}
