import { Banner, Typography } from "../../library";
import useIsComplexPurchase from "./useIsComplexPurchase";

export default function PurchaseTypeNotice() {
  const isComplex = useIsComplexPurchase();
  const title = isComplex
    ? "Based on your responses, this looks like a more complex purchase."
    : "Based on your responses, this looks like a simpler purchase.";
  const subtitle = isComplex
    ? "Complex purchases often require more procurement work and involve senior staff. We account for this with a higher time estimate. You can adjust time required below."
    : "Simpler purchases often require less procurement work and may not involve senior staff. We account for this with a lower staff time estimate. You can adjust staff time below.";

  return (
    <Banner className="grid gap-2 !p-4" color="accent.default.tertiary.enabled">
      <Typography color="neutral.boldest.enabled" emphasis size="sm">
        {title}
      </Typography>
      <Typography color="neutral.bold.enabled" size="sm">
        {subtitle}
      </Typography>
    </Banner>
  );
}
