import shareableContract from "../../img/welcome/shareable-contract.svg";

interface ShareableContractImageProps {
  className?: string;
}
export default function ShareableContractImage({
  className,
  ...rest
}: ShareableContractImageProps) {
  return (
    <img
      src={shareableContract}
      className={className}
      alt="Three people around a document icon"
      {...rest}
    />
  );
}
