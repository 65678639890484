import type { ReactNode } from "react";

import { Link } from "../library";
import type { TypographySize } from "../library/Typography/types";
import { SUPPORT_EMAIL_ADDRESS } from "../utils/constants";
import { generateHrefForEmail } from "../utils/format";

export default function SupportEmailLink({
  email = SUPPORT_EMAIL_ADDRESS,
  children,
  underline = false,
  ...rest
}: {
  email?: string;
  children?: ReactNode;
  underline?: boolean;
  className?: string;
  size?: TypographySize;
}) {
  return (
    <Link
      href={generateHrefForEmail(email)}
      underline={underline}
      newWindow={false}
      emphasis={false}
      {...rest}
    >
      {children || email}
    </Link>
  );
}
