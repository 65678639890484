import { useAtomValue } from "jotai";
import anne from "../../../../img/team-icons/anne.png";
import lucas from "../../../../img/team-icons/lucas.png";
import monica from "../../../../img/team-icons/monica.png";
import ryan from "../../../../img/team-icons/ryan.png";
import { userStateCodeState } from "../../../jotai/user";

type RegionalManager = {
  name: string;
  email: string;
  image?: string;
  scheduleWalkthroughLink: string;
  states: string[];
};

const regionalManagers: RegionalManager[] = [
  {
    name: "Anne",
    email: "anne@withpavilion.com",
    image: anne,
    scheduleWalkthroughLink:
      "https://hello.withpavilion.com/c/annewithpavilion-com/t/7",
    states: ["CA", "VA"],
  },
  {
    name: "Ryan",
    email: "ryan@withpavilion.com",
    image: ryan,
    scheduleWalkthroughLink: "https://calendly.com/ryanpavilion/45",
    states: ["TX"],
  },
  {
    name: "Monica",
    email: "monica@withpavilion.com",
    image: monica,
    scheduleWalkthroughLink: "https://hello.withpavilion.com/c/Monica_DiLullo",
    states: ["AZ"],
  },
  {
    name: "Lucas",
    email: "lucas@withpavilion.com",
    image: lucas,
    scheduleWalkthroughLink:
      "https://hello.withpavilion.com/c/lucaswithpavilion-com",
    states: ["FL"],
  },
];

export function useRegionalManager() {
  const stateCode = useAtomValue(userStateCodeState);
  return regionalManagers.find(
    (rm) => stateCode && rm.states.includes(stateCode)
  );
}
