import { Form, Formik } from "formik";

import DiscountMarkupCard from "./DiscountMarkupCard";
import PurchaseDetailsCard from "./PurchaseDetailsCard";
import PurchaserRoleCard from "./PurchaserRoleCard";
import ResultsCard from "./ResultsCard";
import StaffTimeCard from "./StaffTimeCard";
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from "./constants";
import {
  useOnBlurCostCalculatorCallback,
  useOnChangeCostCalculatorCallback,
  useOnSubmitCostCalculatorCallback,
} from "./hooks";

// Use this sub-form so the hooks have access to ForkmikContext
function CostCalculatorForm() {
  const onBlur = useOnBlurCostCalculatorCallback();
  const onChange = useOnChangeCostCalculatorCallback();

  return (
    <>
      <div className="grid gap-6 col-span-full lg:col-span-8">
        <PurchaseDetailsCard onBlur={onBlur} onChange={onChange} />
        <StaffTimeCard onBlur={onBlur} onChange={onChange} />
        <DiscountMarkupCard onBlur={onBlur} onChange={onChange} />
        <PurchaserRoleCard onBlur={onBlur} onChange={onChange} />
      </div>
      <ResultsCard />
    </>
  );
}

export default function CostCalculatorFormContainer() {
  const onSubmit = useOnSubmitCostCalculatorCallback();

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{
        ...DEFAULT_VALUES,
      }}
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      <Form className="grid gap-6 grid-cols-12">
        <CostCalculatorForm />
      </Form>
    </Formik>
  );
}
