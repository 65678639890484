import {
  Field,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import type { AnchorPropsWithSelection } from "@headlessui/react/dist/internal/floating";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";
import { Fragment, type ReactNode, useEffect, useState } from "react";

import { Badge, Label, Tooltip, Typography } from "../..";
import { borderColorClass } from "../../../utils/colors";
import { LabelVariant } from "../../Label";
import type { TypographyColor, TypographySize } from "../../Typography/types";
import type { FieldLabelProps } from "../../form/types";

export enum DropdownPickerVariants {
  DEFAULT = "default",
  SEARCHBAR = "searchbar",
  ERROR = "error",
  DISABLED = "disabled",
  SUCCESS = "success",
}

export interface DropDownPickerOption<T> {
  key: string;
  value: T;
  label: ReactNode;
  sublabel?: string;
  tooltipText?: string;
}

export interface DropdownPickerProps<T extends string | number | object | null>
  extends FieldLabelProps {
  initialValue: T;
  onChange: (value: T) => void;
  options: DropDownPickerOption<T>[];
  renderSelected?: (value: T) => ReactNode;
  className?: string;
  buttonClassName?: string;
  placeholder?: string;
  dataTestId?: string;
  variant?: DropdownPickerVariants;
  message?: string;
  // Set to true if you want the selected option to change with initialValue
  refreshWithInitialValue?: boolean;
  allowOverflow?: boolean;
  validationMessage?: string;
  validationIcons?: boolean;
  anchor?: AnchorPropsWithSelection;
  name?: string;
  size?: TypographySize;
}

export const VARIANTS: Record<
  DropdownPickerVariants,
  {
    buttonClass?: string;
    textColor?: TypographyColor;
  }
> = {
  [DropdownPickerVariants.DEFAULT]: {
    buttonClass:
      "border-cp-white-300 focus:border-cp-lapis-500 ui-open:border-cp-lapis-500 hover:shadow-1 bg-cp-white-100 ",
    textColor: "neutral.boldest.enabled",
  },
  [DropdownPickerVariants.SEARCHBAR]: {
    buttonClass:
      "border-cp-lapis-100 focus:border-cp-lapis-100 focus-visible:outline-1 focus-visible:outline-cp-lapis-500 text-cp-midnight-300 font-semibold text-cp-meta-md bg-cp-violet-100",
    textColor: "brand.bold.default",
  },
  [DropdownPickerVariants.ERROR]: {
    buttonClass: `${borderColorClass.feedback.bold.error} hover:bg-cp-neutral-palette-50`,
    textColor: "feedback.bold.error",
  },
  [DropdownPickerVariants.DISABLED]: {
    buttonClass:
      "bg-cp-white-200 border-cp-white-300 text-cp-black-100 pointer-events-none ",
    textColor: "neutral.boldest.enabled",
  },
  [DropdownPickerVariants.SUCCESS]: {
    buttonClass: `${borderColorClass.feedback.bold.success} hover:bg-cp-neutral-palette-50`,
    textColor: "feedback.bold.success",
  },
};

// If field is an empty string, this will render the dropdown picker with the list items as is
export function DropdownPicker<T extends string | number | object | null>({
  initialValue,
  onChange,
  options,
  renderSelected,
  className,
  label,
  sublabel,
  labelEmphasis = true,
  labelSize = "sm",
  labelStyle = LabelVariant.BORDER,
  labelTextVariant = "meta",
  buttonClassName,
  placeholder,
  dataTestId,
  variant,
  message,
  refreshWithInitialValue,
  allowOverflow,
  validationMessage,
  validationIcons,
  anchor,
  name,
  size = "md",
}: DropdownPickerProps<T>) {
  const nullOption = {
    key: "-1",
    value: null,
    label: placeholder || "",
  };
  const defaultOption = placeholder ? nullOption : options[0];

  const [selected, setSelected] = useState(
    options.find((o) => o.value === initialValue) || defaultOption
  );

  useEffect(() => {
    if (refreshWithInitialValue) {
      setSelected(
        options.find((o) => o.value === initialValue) || defaultOption
      );
    }
  }, [initialValue, options, defaultOption, refreshWithInitialValue]);

  function renderSelectedWithPlaceholder(option: {
    key: string;
    value: T | null;
    label: ReactNode;
  }) {
    if (!renderSelected) {
      return <Typography size={size}>{option.label}</Typography>;
    }

    return option.value ? renderSelected(option.value) : placeholder;
  }

  const { buttonClass, textColor } =
    VARIANTS[variant || DropdownPickerVariants.DEFAULT];

  let ValidationIcon = undefined;
  if (validationIcons) {
    if (variant === DropdownPickerVariants.ERROR) {
      ValidationIcon = ErrorRoundedIcon;
    } else if (variant === DropdownPickerVariants.SUCCESS) {
      ValidationIcon = CheckCircleRoundedIcon;
    }
  }
  if (validationMessage && variant === DropdownPickerVariants.SUCCESS) {
    message = validationMessage;
  }

  return (
    <Field as={Fragment}>
      <Listbox
        by="key"
        name={name}
        value={selected}
        onChange={(v) => {
          setSelected(v);
          if (v.value !== null) {
            onChange(v.value);
          }
        }}
        disabled={variant === DropdownPickerVariants.DISABLED}
      >
        <div className="relative w-full">
          {label && (
            <Label
              variant={labelStyle}
              size={labelSize}
              color={textColor}
              emphasis={labelEmphasis}
              textVariant={labelTextVariant}
              className="mb-1"
              htmlFor={name}
              sublabel={sublabel}
            >
              {label}
            </Label>
          )}
          <ListboxButton
            className={clsx(
              "analytics-dropdown-picker flex justify-between w-full py-3 px-4 text-left rounded-3 cursor-pointer border border-solid",
              buttonClass,
              buttonClassName
            )}
            data-testid={dataTestId}
          >
            {renderSelectedWithPlaceholder(selected)}
            <span className="flex items-center pointer-events-none self-center">
              <ExpandMoreRoundedIcon className="w-5 h-5 fill-cp-black-200 group-focus:fill-cp-lapis-500 ui-open:fill-cp-lapis-500" />
            </span>
          </ListboxButton>
          <ListboxOptions
            anchor={anchor}
            className={clsx(
              "absolute mt-1 z-3 bg-cp-white-100 rounded-md shadow-1 max-h-80 focus:outline-none",
              { "overflow-auto": !allowOverflow },
              className
            )}
            as="ul"
          >
            {options.map((option) => (
              <ListboxOption
                key={option.key}
                className="flex gap-1 cursor-pointer select-none py-3 px-4 hover:bg-cp-violet-100"
                value={option}
                data-testid={`${dataTestId}-${option.key}`}
                as="li"
              >
                <div className="h-5 w-5">
                  <CheckRoundedIcon className="hidden ui-selected:block h-5 w-5 fill-cp-midnight-100" />
                </div>
                <div className="flex">
                  <Typography
                    component="div"
                    size={size}
                    className="self-center"
                  >
                    {option.label}
                    {option.sublabel ? (
                      <Typography
                        className="mt-2"
                        color="neutral.default.tertiary.enabled"
                        variant="meta"
                      >
                        {option.sublabel}
                      </Typography>
                    ) : null}
                  </Typography>
                  {option.tooltipText ? (
                    <Tooltip
                      className="w-80"
                      info={option.tooltipText}
                      placement={"bottom-start"}
                    >
                      <Badge
                        Icon={InfoOutlinedIcon}
                        iconClass="text-cp-lapis-200"
                        size="md"
                        className="pl-2"
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </ListboxOption>
            ))}
          </ListboxOptions>
          {(message || ValidationIcon) && (
            <div className="mt-2 mr-2 flex gap-1 items-center empty:hidden">
              {ValidationIcon && (
                <Typography
                  component={ValidationIcon}
                  variant="body"
                  color={textColor}
                />
              )}
              {message && (
                <Typography size="sm" variant="meta" color={textColor}>
                  {message}
                </Typography>
              )}
            </div>
          )}
        </div>
      </Listbox>
    </Field>
  );
}

export default DropdownPicker;
