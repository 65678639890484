import { Field } from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import type { PurchaseCategoryEnum, PurchaserRoleEnum } from "../../generated";
import {
  purchaseCategoryOptionsAtom,
  purchaserRoleOptionsAtom,
} from "../../jotai/costCalculator";
import { LabelVariant, Link } from "../../library";
import { DropdownField, LabeledInputField } from "../../library/form";
import RadioButtonGroupField from "../../library/form/RadioButtonGroupField";
import type { FormFieldProps, Validate } from "../../library/form/types";
import yup from "../../utils/yupPhone";

const DEFAULT_PROPS = {
  labelSize: "sm",
  labelStyle: LabelVariant.FLOATING_BLOCK,
  labelTextVariant: "body",
  editable: true,
} as const;

const PURCHASE_DESCRIPTION_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  dataTestId: "purchase-description",
  component: LabeledInputField,
  name: "purchaseDescription",
  label: "What are you purchasing?",
  className: "w-full",
  placeholder: "e.g. forklift",
  validate: yup.string().required("Please describe your purchase."),
};

const PURCHASE_CATEGORY_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  dataTestId: "purchase-category",
  component: DropdownField,
  placeholder: "Select one...",
  name: "purchaseCategory",
  label: "What category is this purchase?",
  className: "!w-1/2 lg:!w-1/3",
  buttonClassName: "!w-1/2 lg:!w-1/3",
  refreshWithInitialValue: true,
  sublabel:
    "Construction and commodities with installation are typically more complex.",
  validate: yup.string().required("Please select a purchase category."),
};

const PURCHASE_COST_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  dataTestId: "purchase-cost",
  component: LabeledInputField,
  name: "purchaseCost",
  type: "number",
  label: "How much will this purchase cost?",
  sublabel: "Purchases over $1,000,000 are typically more complex.",
  placeholder: "e.g. 1000000",
  validate: yup
    .number()
    .min(1, "Purchases must be at least $1")
    .integer("Purchase cost must use whole numbers.")
    .required("Please enter a cost for this purchase."),
  leadingText: "$",
  inputClassName: "w-1/2 lg:w-1/3",
};

const PURCHASE_APPROVAL_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  component: RadioButtonGroupField,
  name: "purchaseRequiresApproval",
  label: "Will you need approval from senior management or elected officials?",
  sublabel:
    "Purchases requiring approval from leadership are typically more complex.",
  options: [
    { key: "true", value: true, label: "Yes" },
    { key: "false", value: false, label: "No" },
  ],
  inline: true,
  validate: yup
    .boolean()
    .required("Please select whether this purchase required approval."),
};

const PURCHASER_ROLE_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  component: DropdownField,
  placeholder: "Select one...",
  name: "purchaserRole",
  label: "What's your role?",
  refreshWithInitialValue: true,
  validate: yup.string().nullable(),
};

const PURCHASER_SALARY_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  component: LabeledInputField,
  name: "purchaserRoleSalary",
  type: "number",
  label:
    "What’s the average salary (excluding benefits) for someone in your role where you work?",
  placeholder: "e.g. 65000",
  validate: yup
    .number()
    .min(1, "Salaries must be at least $1")
    .integer("Salaries must be whole numbers.")
    .when("purchaserRole", {
      is: "default",
      then: (s) => s.nullable(),
      otherwise: (s) => s.required("Average salary is required."),
    }),

  leadingText: "$",
  inputClassName: "w-1/2 lg:w-1/3",
};

const SOLICITATION_HOURS_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  component: LabeledInputField,
  name: "solicitationStaffHours",
  type: "number",
  label: "Expected staff time for solicitation across all teams:",
  sublabel:
    "Includes all phases of a procurement including initial stages and publication, open solicitation, and award.",
  placeholder: "e.g. 139",
  validate: yup
    .number()
    .min(0.01, "Expected staff time must be a positive number.")
    .nullable(),
  className: "flex",
  inputClassName: "w-30",
  iconClassName: "right-8",
  trailingText: "hours",
};

const PIGGYBACK_HOURS_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  component: LabeledInputField,
  name: "piggybackStaffHours",
  type: "number",
  label: "Expected staff time for piggyback across all teams:",
  sublabel: "Includes negotiation and award phases of a procurement.",
  placeholder: "e.g. 43",
  validate: yup
    .number()
    .min(0.01, "Expected staff time must be a positive number.")
    .nullable(),
  inputClassName: "w-30",
  iconClassName: "right-8",
  trailingText: "hours",
};

const SOLICITATION_DISCOUNT_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  size: "sm",
  component: LabeledInputField,
  name: "solicitationDiscount",
  type: "number",
  label:
    "What discount vs. list price do you expect from running a solicitation, if any?",
  sublabel:
    "Discount from list price that you expect from running a solicitation.",
  placeholder: "e.g. 7.5",
  validate: yup
    .number()
    .min(0, "Discount percentage must be a positive number.")
    .nullable(),
  inputClassName: "w-30",
  iconClassName: "right-[0.5rem]",
  trailingText: "%",
};

const DISCOUNT_MARKUP_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  dataTestId: "discount-markup-toggle",
  size: "sm",
  component: RadioButtonGroupField,
  name: "piggybackIsDiscount",
  options: [
    { key: "true", value: true, label: "Discount" },
    { key: "false", value: false, label: "Markup" },
  ],
  inline: true,
  validate: yup.boolean().required("Please select either discount or markup."),
};

const PIGGYBACK_DISCOUNT_MARKUP_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  dataTestId: "piggyback-discount-markup",
  size: "sm",
  component: LabeledInputField,
  name: "piggybackDiscountMarkup",
  type: "number",
  label:
    "What discount or markup vs. list price do you expect from piggybacking, if any?",
  sublabel: (
    <>
      <Link size="sm" emphasis={false} underline={false}>
        Euna’s 2024 State of Public Procurement
      </Link>{" "}
      report found a 7.5% average discount on piggyback purchases.
    </>
  ),
  placeholder: "e.g. 7.5",
  validate: yup
    .number()
    .min(0, "Discount percentage must be a positive number.")
    .nullable(),
  className: "flex flex-wrap gap-x-4 items-center",
  inputClassName: "w-30",
  iconClassName: "right-[0.5rem]",
  trailingText: "%",
};

export type FieldHookProps = {
  onBlur: (e: FocusEvent) => void;
  onChange?: () => void;
};

export function usePurchaseDetailsFields({ onBlur, onChange }: FieldHookProps) {
  const purchaseCategoryOptions = useAtomValue(purchaseCategoryOptionsAtom);
  return useMemo(
    () => [
      { ...PURCHASE_DESCRIPTION_FIELD, onBlur },
      {
        ...PURCHASE_CATEGORY_FIELD,
        options: purchaseCategoryOptions,
        onChange,
      },
      { ...PURCHASE_COST_FIELD, onBlur },
      { ...PURCHASE_APPROVAL_FIELD, onChange },
    ],
    [purchaseCategoryOptions, onBlur, onChange]
  );
}

export function usePurchaserRoleFields({ onBlur, onChange }: FieldHookProps) {
  const purchaserRoleOptions = useAtomValue(purchaserRoleOptionsAtom);
  return useMemo(
    () => [
      {
        ...PURCHASER_ROLE_FIELD,
        options: [
          {
            key: "default",
            value: "default",
            label: "Use NCPP national salary estimates",
          },
          ...purchaserRoleOptions,
        ],
        onChange,
      },
      { ...PURCHASER_SALARY_FIELD, onBlur },
    ],
    [purchaserRoleOptions, onBlur, onChange]
  );
}

export function useStaffTimeFields({ onBlur }: FieldHookProps) {
  return useMemo(
    () => [
      { ...SOLICITATION_HOURS_FIELD, onBlur },
      { ...PIGGYBACK_HOURS_FIELD, onBlur },
    ],
    [onBlur]
  );
}

export function useDiscountMarkupFields({ onBlur, onChange }: FieldHookProps) {
  return useMemo(
    () => [
      { ...SOLICITATION_DISCOUNT_FIELD, onBlur },
      {
        ...PIGGYBACK_DISCOUNT_MARKUP_FIELD,
        onBlur,
        renderChildren: () => (
          <>
            <Field {...DISCOUNT_MARKUP_FIELD} onChange={onChange} />
            <div className="h-0 basis-full" />
          </>
        ),
      },
    ],
    [onBlur, onChange]
  );
}

export interface CostCalculatorFormValues {
  purchaseDescription: string;
  purchaseCategory: PurchaseCategoryEnum;
  purchaseCost: number;
  purchaseRequiresApproval: boolean;
  purchaserRole: "default" | PurchaserRoleEnum;
  purchaserRoleSalary: null | number;
  solicitationStaffHours: null | number;
  piggybackStaffHours: null | number;
  solicitationDiscount: number;
  piggybackDiscountMarkup: number;
  piggybackIsDiscount: boolean;
}

export const DEFAULT_VALUES = {
  purchaseDescription: null,
  purchaseCategory: null,
  purchaseCost: null,
  purchaseRequiresApproval: false,
  purchaserRole: "default",
  purchaserRoleSalary: null,
  solicitationStaffHours: 43,
  piggybackStaffHours: 5,
  solicitationDiscount: 0,
  piggybackDiscountMarkup: 0,
  piggybackIsDiscount: true,
} as unknown as CostCalculatorFormValues;

const ALL_FIELDS = [
  PURCHASE_DESCRIPTION_FIELD,
  PURCHASE_CATEGORY_FIELD,
  PURCHASE_COST_FIELD,
  PURCHASE_APPROVAL_FIELD,
  PURCHASER_ROLE_FIELD,
  PURCHASER_SALARY_FIELD,
  SOLICITATION_HOURS_FIELD,
  PIGGYBACK_HOURS_FIELD,
  SOLICITATION_DISCOUNT_FIELD,
  PIGGYBACK_DISCOUNT_MARKUP_FIELD,
  DISCOUNT_MARKUP_FIELD,
];

export const VALIDATION_SCHEMA = yup.object(
  ALL_FIELDS.reduce(
    (acc, field) => {
      if (field.validate) acc[field.name] = field.validate;
      return acc;
    },
    {} as Record<string, Validate>
  )
);
