import { createRoot } from "react-dom/client";

import ProjectsPage from "../components/Project/ProjectsPage";
import type { Search } from "../components/Project/types";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface ProjectProps extends AuthPageProps {
  searches: Search[];
}
export default function Projects({
  is_authenticated,
  profile_type,
  searches,
  active_agreements,
  context,
  isSSR,
}: ProjectProps) {
  return (
    <Page
      searchSource="projects-header-search-bar"
      isAuthenticated={is_authenticated}
      pageType={PageType.PROJECT}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <ProjectsPage searches={searches} />
    </Page>
  );
}

const container = getDOMAnchorById("projects-page");
if (container) {
  const root = createRoot(container);
  const data = JSON.parse(container.dataset.data || "");
  root.render(<Projects searches={data.searches} />);
}
