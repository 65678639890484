import { captureMessage } from "@sentry/browser";
import { type Getter, type Setter, atom } from "jotai";
import { useCallback } from "react";

import { useAtomCallback } from "jotai/utils";
import {
  ApiError,
  ApiService,
  type SupplierContactPhoneNumber,
} from "../../../generated";
import { isAuthenticatedState } from "../../../jotai/user";
import { handleError } from "../../../utils/generatedApi";

// An atom to store phones by contact id. Use promises rather than the
// resolved values so that we can prefetch and avoid duplicating requests.
const phonesByContactIdAtom = atom<
  Record<number, Promise<null | SupplierContactPhoneNumber>>
>({});

export default function useFetchContactNumberCallback() {
  const fetchContactNumber = useCallback(
    (get: Getter, set: Setter, supplierId: number, contactId: number) => {
      const isAuthenticated = get(isAuthenticatedState);
      if (!isAuthenticated) return null;

      // If the phone number has already been fetched, return the promise.
      const phonesByContactId = get(phonesByContactIdAtom);
      if (contactId in phonesByContactId) return phonesByContactId[contactId];

      const result = ApiService.apiV1SuppliersContactsRetrieve(
        contactId,
        supplierId
      )
        .then((v) => v.phoneNumber)
        .catch((err) => {
          if (err instanceof ApiError) {
            // This case should not happen, though this tracks if it does.
            if (err.status === 403) {
              captureMessage("Invalid user requesting contact information.");
            } else if (err.status === 404) {
              captureMessage(
                `No contact with id ${contactId} found for supplier ${supplierId}`
              );
            } else {
              handleError(err);
            }
          }
          return null;
        });
      set(phonesByContactIdAtom, (prev) => ({
        ...prev,
        [contactId]: result,
      }));
      return result;
    },
    []
  );

  return useAtomCallback(fetchContactNumber);
}
