import fileFolder from "../../../img/icons/file-folder.svg";

interface FileFolderIconProps {
  className?: string;
}
export function FileFolderIcon({ className }: FileFolderIconProps) {
  return (
    <img
      src={fileFolder}
      alt="file folder with a contract"
      className={className}
    />
  );
}
