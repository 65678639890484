import { createRoot } from "react-dom/client";

import CompleteBuyerSignupPage from "../../components/Account/CompleteBuyerSignup";
import type { InferredBLA } from "../../modals/SignupSteps/types";
import { getDOMAnchorById } from "../../utils";
import { PageType } from "../../utils/enums";
import Page from "../Page/Page";

interface CompleteBuyerSignupProps {
  email: string;
  inferred_bla: InferredBLA;
  isSSR: boolean;
}
export default function CompleteBuyerSignup({
  email,
  inferred_bla,
  isSSR,
}: CompleteBuyerSignupProps) {
  return (
    <Page
      pageId=""
      pageType={PageType.ACCOUNT}
      showHeader={false}
      isAuthenticated={false}
      profileType={null}
      activeAgreements={null}
      isSSR={isSSR}
    >
      <CompleteBuyerSignupPage email={email} inferredBLA={inferred_bla} />
    </Page>
  );
}

const container = getDOMAnchorById("complete-buyer-signup-container");
if (container) {
  const props = JSON.parse(container.dataset.signupData || "");
  const root = createRoot(container);
  root.render(<CompleteBuyerSignup {...props} />);
}
