import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import ContentNotFound from "../shared/ContentNotFound/ContentNotFound";
import type { ContentTypes } from "../shared/types";
import { getDOMAnchorById } from "../utils";
import { trackHeapEvent } from "../utils/tracking";

import Page, { type AuthPageProps } from "./Page/Page";

interface Page404Props extends AuthPageProps {
  content_type: ContentTypes;
}

export default function Page404({
  content_type,
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: Page404Props) {
  return (
    <Page
      searchSource="page404-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <ContentNotFound contentType={content_type} />
    </Page>
  );
}

const container = getDOMAnchorById("page-404");
if (container) {
  const root = createRoot(container);

  if (_get(container, "dataset.trackHeapTag")) {
    trackHeapEvent(container.dataset.trackHeapTag || "");
  }

  root.render(
    <Page404 content_type={container.dataset.contentType as ContentTypes} />
  );
}
