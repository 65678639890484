import type { FieldInputProps } from "formik";
import RadioButtonGroup, {
  type RadioButtonGroupOption,
} from "../Input/RadioButtonGroup";
import Label, { LabelVariant } from "../Label";
import type { CustomFormProps, FieldLabelProps } from "./types";

interface RadioButtonGroupFieldProps<T> extends FieldLabelProps {
  field: FieldInputProps<T>;
  form: CustomFormProps;
  label: string;
  inline: boolean;
  sublabel?: string;
  placeholder?: string;
  className?: string;
  dataTestId?: string;
  onChange?: (value: string | T) => void;
  options: RadioButtonGroupOption<T>[];
}

export default function RadioButtonGroupField<T>({
  field,
  form: { setFieldValue },
  options,
  inline,
  label,
  labelEmphasis = true,
  labelSize,
  labelStyle = LabelVariant.FLOATING,
  labelClassName,
  labelTextVariant,
  sublabel,
  onChange,
  className,
}: RadioButtonGroupFieldProps<T>) {
  return (
    <div className={className}>
      {label && (
        <Label
          htmlFor={field.name}
          variant={labelStyle}
          emphasis={labelEmphasis}
          className={labelClassName}
          size={labelSize}
          textVariant={labelTextVariant}
          sublabel={sublabel}
        >
          {label}
        </Label>
      )}
      <RadioButtonGroup
        value={field.value}
        options={options}
        inline={inline}
        onChange={(value) => {
          setFieldValue(field.name, value);
          onChange?.(value);
        }}
      />
    </div>
  );
}
