import { createRoot } from "react-dom/client";

import PostSocialAuthPage from "../../components/Account/PostSocialAuth";
import type { InferredBLA } from "../../modals/SignupSteps/types";
import { getDOMAnchorById } from "../../utils";
import { PageType } from "../../utils/enums";
import type { SocialProvider } from "../../utils/social";
import Page from "../Page/Page";

interface PostSocialAuthProps {
  first_name: string;
  social_provider: SocialProvider;
  email: string;
  inferred_bla: InferredBLA;
  isSSR: boolean;
}

export default function PostSocialAuth({
  first_name,
  social_provider,
  email,
  inferred_bla,
  isSSR,
}: PostSocialAuthProps) {
  return (
    <Page
      pageId=""
      profileType={null}
      activeAgreements={null}
      pageType={PageType.ACCOUNT}
      showHeader={false}
      isAuthenticated={false}
      isSSR={isSSR}
    >
      <PostSocialAuthPage
        firstName={first_name}
        provider={social_provider}
        userEmail={email}
        inferredBLA={inferred_bla}
      />
    </Page>
  );
}

const container = getDOMAnchorById("post-social-auth-container");
if (container) {
  const props = JSON.parse(container.dataset.socialData || "");
  const root = createRoot(container);
  root.render(<PostSocialAuth {...props} />);
}
