import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import HomePage from "../components/Home/Home";
import { getDOMAnchorById } from "../utils";

import { PageType } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

export interface Category {
  name: string;
  url: string;
}
interface HomeProps extends AuthPageProps {
  data: { categories: Category[] };
}

export default function Home({
  data,
  isSSR,
  context,
  is_authenticated,
  profile_type,
  active_agreements,
}: HomeProps) {
  return (
    <Page
      pageType={PageType.HOME_PAGE}
      context={context}
      isSSR={isSSR}
      searchSource="homepage-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <HomePage data={data} isSSR={isSSR || false} />
    </Page>
  );
}

const container = getDOMAnchorById("home-container");
if (_get(container, "dataset.home")) {
  const data = JSON.parse((container as HTMLElement).dataset.home as string);
  const root = createRoot(container as HTMLElement);
  root.render(<Home data={data} />);
}
