import clsx from "clsx";

interface LoadingHitProps {
  className?: string;
}
function LoadingHit({ className }: LoadingHitProps) {
  return (
    <div
      className={clsx(
        "rounded-2xl border border-solid border-cp-white-200 flex flex-col gap-3 border-cp-white-300 p-8",
        className
      )}
    >
      <div className="h-10 w-full flex flex-row gap-5">
        <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-full w-5/6" />
        <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-full w-1/6" />
      </div>
      <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine w-full h-20" />
    </div>
  );
}

export function LoadingSlimHit() {
  return (
    <div className="h-26 rounded-2xl border border-solid border-cp-white-200 flex flex-col gap-2 border-cp-white-300 p-4">
      <div className="h-6 w-full flex flex-row gap-4">
        <div className="rounded-lg bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse h-full w-5/6" />
        <div className="rounded-lg bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse h-full w-1/6 min-w-[120px]" />
      </div>
      <div className="rounded-lg bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse w-full h-10" />
    </div>
  );
}

export default LoadingHit;
