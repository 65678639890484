import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";

import {
  Badge,
  HeadlineMetric,
  ProgressBarWithValue,
  ProgressVariant,
  Tooltip,
  Typography,
} from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import { formatDate, parseDate } from "../../../utils/date";
import type { ViewAnalytics } from "../types";

export interface QEVTrackerProps {
  viewAnalytics: ViewAnalytics;
  isLoading: boolean;
}
export default function QEVTracker({
  viewAnalytics,
  isLoading,
}: QEVTrackerProps) {
  if (isLoading) {
    return null;
  }
  const { qualifiedEntityViews, viewsInPackage, dateViewsStart } =
    viewAnalytics;

  const tooltip = (
    <Typography size="sm" color="inverse">
      An entity view consists of multiple views from a single entity in a given
      month. An entity view is qualified if the entity is looking to buy what
      you sell in an area that you service.
      <br />
      <br />
      <i>
        Views start counting on{" "}
        {formatDate(parseDate(dateViewsStart), "MM/dd/yyyy")}. Updated monthly.
      </i>
    </Typography>
  );

  return (
    <div
      className={clsx(
        "flex flex-col gap-6 p-6 rounded-8",
        bgColorClass.brand.subtlest.enabled
      )}
    >
      <div className="grid grid-cols-2 gap-6 justify-between">
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            size="lg"
            emphasis
            color="brand.boldest.enabled"
          >
            {
              // Never show more qualified entity views than views in package.
              Math.min(qualifiedEntityViews, viewsInPackage)
            }
          </Typography>
          <Tooltip info={tooltip} className="max-w-88">
            <Badge
              as={Typography}
              Icon={InfoOutlinedIcon}
              iconClass="cursor-pointer"
              reverse
              size="sm-md"
            >
              Qualified entity views used
            </Badge>
          </Tooltip>
        </div>
        <HeadlineMetric value={viewsInPackage}>
          Qualified entity views purchased
        </HeadlineMetric>
      </div>
      <ProgressBarWithValue
        value={qualifiedEntityViews}
        size="md"
        max={viewsInPackage}
        variant={ProgressVariant.ENTITY_VIEWS}
        showValue
      />
    </div>
  );
}
