import type { FormikProps } from "formik";
import _isEqual from "lodash/isEqual";
import { type ChangeEvent, useEffect, useMemo, useState } from "react";
import Select, { type MultiValue } from "react-select";

import {
  Checkbox,
  RadioButtonGroup,
  type RadioButtonGroupOption,
  Typography,
} from "../../../library";
import { stateOptions } from "../../../utils/constants";

import { CONTINENTAL_US } from "./constants";
import type { SupplierServiceAreaValues } from "./types";

export interface SupplierServiceAreaFieldProps {
  field: {
    name: string;
    value: SupplierServiceAreaValues["serviceArea"];
    onChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  };
  form: FormikProps<{
    [x: string]: SupplierServiceAreaValues["serviceArea"];
  }>;
}

export function SupplierServiceAreaField({
  field,
  form: { setFieldValue, setFieldTouched, touched, submitForm },
}: SupplierServiceAreaFieldProps) {
  const [serviceAreaStates, setServiceAreaStates] = useState(
    field.value?.manualServiceAreaState
  );
  const [isContinental, setIsContinental] = useState(
    _isEqual(field.value?.manualServiceAreaState, CONTINENTAL_US)
  );

  const serviceAreaType = useMemo(() => {
    if (!field.value) return "state";
    if (
      field.value.manualServiceAreaNational ||
      _isEqual(field.value.manualServiceAreaState, CONTINENTAL_US)
    )
      return "national";
    return "state";
  }, [field.value]);

  const defaultStateSelection = useMemo(() => {
    return !serviceAreaStates
      ? []
      : stateOptions.filter((option) =>
          serviceAreaStates.includes(option.value)
        );
  }, [serviceAreaStates]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Submit when the value changes.
  useEffect(() => {
    if (!touched[field.name]) return;
    submitForm();
  }, [field.value]);

  const handleContinentalToggle = (newIsContinental: boolean) => {
    const manualServiceAreaState = newIsContinental ? CONTINENTAL_US : [];
    setServiceAreaStates(manualServiceAreaState);
    setFieldValue(
      field.name,
      {
        manualServiceAreaNational: !newIsContinental,
        manualServiceAreaState,
      },
      true
    );
    setFieldTouched(field.name, true);
    setIsContinental(newIsContinental);
  };

  const handleServiceAreaNationalToggle = (value: string) => {
    const isNational = value === "national";
    // This function is called every time any part of the radio group--including its children--is
    // clicked. In order to avoid clearing the selected states immediately after a state is picked
    // (which counts as a click), we no-op if the national status has not changed.
    if (isNational === field.value.manualServiceAreaNational) return;
    // In order to avoid clearing the continental status when the national service area button is
    // clicked (which sounds correct but looks wrong based on how this component shows in the UI),
    // we no-op if the user clicks the national service area button after checking the continental
    // US service area option. (They can still uncheck the continental US service area option.)
    if (isNational && isContinental) return;

    setServiceAreaStates([]);
    setIsContinental(false);
    if (isNational) {
      setFieldValue(
        field.name,
        {
          manualServiceAreaNational: true,
          manualServiceAreaState: [],
        },
        true
      );
    } else {
      setFieldValue(
        field.name,
        {
          manualServiceAreaNational: false,
          manualServiceAreaState: [],
        },
        true
      );
    }
    setFieldTouched(field.name, true);
  };

  const handleServiceAreaStates = (
    selectedOptions: MultiValue<{ value: string; label: string }>
  ) => {
    const newSelectedOptions = selectedOptions.map((option) => option.value);
    setServiceAreaStates(newSelectedOptions);
    setFieldValue(
      field.name,
      {
        manualServiceAreaNational: false,
        manualServiceAreaState: newSelectedOptions,
      },
      true
    );
    setFieldTouched(field.name, true);
  };

  return (
    <RadioButtonGroup
      value={serviceAreaType}
      options={getServiceAreaOptions({
        defaultStateSelection,
        handleContinentalToggle,
        handleServiceAreaStates,
        isContinental,
      })}
      onChange={(value) => {
        handleServiceAreaNationalToggle(value);
      }}
      labelClassName="mt-0"
    />
  );
}

function getServiceAreaOptions({
  defaultStateSelection,
  handleContinentalToggle,
  handleServiceAreaStates,
  isContinental,
}: {
  defaultStateSelection: { value: string; label: string }[];
  handleContinentalToggle: (value: boolean) => void;
  handleServiceAreaStates: (
    selected: MultiValue<{ value: string; label: string }>
  ) => void;
  isContinental: boolean;
}): RadioButtonGroupOption<string>[] {
  return [
    {
      key: "national",
      className: "analytics-select-service-area-national mt-5",
      label: (
        <Typography>
          My business has{" "}
          {
            <Typography component="span" emphasis>
              nationwide
            </Typography>
          }{" "}
          service area coverage
        </Typography>
      ),
      children: ({ checked }) =>
        checked ? (
          <Checkbox
            checkboxClassName="analytics-select-service-area-contiguous-us ml-5 my-3"
            name="isContinental"
            label={
              <Typography>
                Continental US only (excluding Alaska, Hawaii)
              </Typography>
            }
            onChange={(e) => handleContinentalToggle(e.target.checked)}
            checked={isContinental}
          />
        ) : null,
    },
    {
      key: "state",
      className: "analytics-select-service-area-state",
      label: (
        <Typography>
          My business has specific coverage{" "}
          <Typography component="span" emphasis>
            only in certain states
          </Typography>
        </Typography>
      ),
      children: ({ checked }) =>
        checked ? (
          <Select
            defaultValue={defaultStateSelection}
            isMulti
            isClearable={false}
            name="states"
            options={stateOptions}
            onChange={handleServiceAreaStates}
            className="mt-4"
            classNames={{
              control: (state) =>
                state.isFocused ? "border-cp-lapis-500" : "border-cp-white-300",
            }}
            styles={{
              // @ts-ignore: Type error in third party library
              control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "0.75rem",
              }),
            }}
          />
        ) : null,
    },
  ];
}
