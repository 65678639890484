import { Button, Link, Typography } from "../../library";
import { getWalkthroughLink } from "../../utils";
import { COMPANY_NAME } from "../../utils/constants";

export default function OurProductPage() {
  return (
    <div className="cp-page-container mx-auto">
      <div>
        <section id="webinar" className="max-w-[1140px] my-20 mx-auto pb-12">
          <Typography
            variant="headline"
            size="lg"
            color="brand.boldest.enabled"
            className="font-semibold"
          >
            Schedule a Walkthrough
          </Typography>
          <Typography size="sm" color="neutral.boldest.enabled">
            See how{" "}
            <Link
              size="sm"
              href="/about/public-entities"
              newWindow={false}
              underline={false}
            >
              {COMPANY_NAME}
            </Link>{" "}
            can help you save up to 20 hours each week.
          </Typography>
          <Button
            as={Link}
            className="mt-4"
            linkProps={{
              href: getWalkthroughLink("opcta", "website"),
              newWindow: true,
              underline: false,
            }}
          >
            Schedule a walkthrough
          </Button>
        </section>
      </div>
    </div>
  );
}
