import singleDocuments from "../../../img/icons/single-document.svg";

interface SingleDocumentIconProps {
  className?: string;
}
export function SingleDocumentIcon({ className }: SingleDocumentIconProps) {
  return (
    <img
      src={singleDocuments}
      alt="single contract document"
      className={className}
    />
  );
}
