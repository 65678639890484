import { Field, useField } from "formik";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { inferredEntitySignupState } from "../../../jotai/signup";
import { LabeledInputVariants } from "../../../library";
import type { SupplierInputFieldValue } from "../../../library/form/SupplierInputField";
import type { FormFieldProps } from "../../../library/form/types";

/**
 * Wraps `SupplierInputField` while subscribing to `inferredEntitySignupState`
 * and Formik field value. Sets the field's validation state accordingly.
 */
export default function InferredSupplierField({
  editable,
  supplierName,
  supplierHandle,
  ...props
}: {
  editable: boolean;
  supplierName?: string;
  supplierHandle?: string;
} & FormFieldProps) {
  const [field, { value, touched, error }, { setValue }] =
    useField<SupplierInputFieldValue>({
      name: props.name,
    });
  const [validationMessage, setValidationMessage] = useState("");
  const [variant, setVariant] = useState(LabeledInputVariants.DEFAULT);
  const inferredEntity = useAtomValue(inferredEntitySignupState);
  const [hasAutofilled, setHasAutofilled] = useState(false);

  useEffect(() => {
    // Autofill field from supplierSEOBannerV2
    if (supplierName && supplierHandle) {
      setValue({
        supplier: {
          displayName: supplierName,
          handle: supplierHandle,
        },
      });
      setHasAutofilled(true);
    }
  }, [setValue, supplierName, supplierHandle]);

  useEffect(() => {
    // Empty field after validation
    if (touched && error && value.supplier.displayName === "") {
      setValidationMessage("");
      setVariant(LabeledInputVariants.ERROR);
      return;
    }

    // TODO: https://app.shortcut.com/coprocure/story/25118/show-social-proof-on-intial-sign-up
    // "{x} of your coworkers are on Pavilion" when we have 2+ accounts
    // "Your coworkers are on Pavilion" when we have 1 user account
    // "Your entity is on Pavilion" when we have 0 accounts

    // At this point, either the user has accepted the inferred BLA or
    // selected a BLA from the dropdown, so show the success state.
    if (value.supplier.handle) {
      setValidationMessage("Your business is on Pavilion!");
      setVariant(LabeledInputVariants.SUCCESS);
    }

    // Handle inferring BLA from user email
    if (inferredEntity?.supplierDisplayName) {
      // If the field had previously been autofilled from inferredEntity,
      // but the user has since changed the field, reset the validation state
      const shouldResetField =
        hasAutofilled &&
        value.supplier.displayName !== inferredEntity.supplierDisplayName;

      if (shouldResetField) {
        setValidationMessage("");
        setVariant(LabeledInputVariants.DEFAULT);
        return;
      }

      setValue({
        supplier: {
          displayName: inferredEntity.supplierDisplayName,
          handle: inferredEntity.supplierHandle,
        },
      });
      setHasAutofilled(true);
    }
  }, [
    setValue,
    inferredEntity,
    hasAutofilled,
    touched,
    error,
    value.supplier.displayName,
    value.supplier.handle,
  ]);
  return (
    <Field
      field={field}
      validationMessage={validationMessage}
      initialVariant={variant}
      editable={editable}
      {...props}
    />
  );
}
