import clsx from "clsx";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import approvedSourcesSorting from "../../../../img/approved-sources/approvedSourcesSorting.gif";
import {
  AgencyTypeEnum,
  ApiService,
  type ApprovedSource,
  ApprovedSourceStatusEnum,
  type CreateApprovedSourceRequest,
  type StateCodeEnum,
} from "../../../generated";
import { popupState } from "../../../jotai/page";
import { buyerProfileState } from "../../../jotai/user";
import { Button, Typography } from "../../../library";
import SupportEmailLink from "../../../shared/SupportEmailLink";
import { getParam } from "../../../utils";
import { bgColorClass } from "../../../utils/colors";
import {
  AGENCY_TYPE_DISPLAY_STRING,
  CONTRACT_EMAIL_ADDRESS,
  stateLabels,
} from "../../../utils/constants";
import { PopupType } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import {
  type TrackRemoveApprovedSourceParams,
  trackAddApprovedSourceGroup,
  trackAddNewApprovedSource,
  trackCheckShowApprovedSourcesFirst,
  trackRemoveApprovedSource,
  trackRemoveApprovedSourceGroup,
  trackUpdateApprovedSourceStatus,
} from "../../../utils/tracking";
import BLAAutocompleteInput, {
  type BLAAutocompleteInputOnChange,
} from "../../BLAAutocompleteInput";
import ContactBox from "../ContactBox";
import PreferenceCheckbox, {
  type PreferenceCheckboxProps,
} from "../PreferenceCheckbox";
import ApprovedSourcesTable from "./ApprovedSourcesTable";

function OptimisticPreferenceCheckbox({
  onChange,
  checked,
  ...rest
}: Omit<PreferenceCheckboxProps, "onChange"> & {
  onChange: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <PreferenceCheckbox
      {...rest}
      disabled={loading}
      checked={loading ? !checked : checked}
      onChange={async () => {
        setLoading(true);
        await onChange();
        setLoading(false);
      }}
    />
  );
}

const EXTRA_SOURCES_FOR_AGENCY_TYPE: Partial<
  Record<AgencyTypeEnum, AgencyTypeEnum[]>
> = {
  [AgencyTypeEnum.COMMUNITY_COLLEGE]: [AgencyTypeEnum.UNIVERSITY],
};

export default function AdminApprovedSources() {
  const [buyerProfile, setBuyerProfile] = useAtom(buyerProfileState);
  const [approvedSources, setApprovedSources] = useState<ApprovedSource[]>([]);
  const setPopupState = useSetAtom(popupState);
  const [showAddSourceFromParam, setShowAddSourceFromParam] = useState(false);
  const sourceIdParam = getParam("sourceId");
  const sourceNameParam = getParam("sourceName");

  const hasAllNationalCoops = useMemo(() => {
    return !!approvedSources.find(
      ({ sourceAgencyGroupType }) =>
        sourceAgencyGroupType === AgencyTypeEnum.NATIONAL_COOPERATIVE
    );
  }, [approvedSources]);

  const hasAllSameEntityType = useMemo(() => {
    const agencyType = buyerProfile?.governmentAgency?.agencyType;
    return !!(
      agencyType &&
      approvedSources.find(
        ({ sourceAgencyGroupType }) => sourceAgencyGroupType === agencyType
      )
    );
  }, [buyerProfile, approvedSources]);

  const hasAllStateSources = useMemo(() => {
    return !!approvedSources.find(
      ({ sourceAgencyGroupState }) => !!sourceAgencyGroupState
    );
  }, [approvedSources]);

  const hasAllExtraAgencySources = useMemo(() => {
    const agencyType = buyerProfile?.governmentAgency?.agencyType;
    if (!agencyType || !EXTRA_SOURCES_FOR_AGENCY_TYPE[agencyType]) return {};
    return EXTRA_SOURCES_FOR_AGENCY_TYPE[agencyType].reduce(
      (acc, v) => {
        acc[v] = !!approvedSources.find(
          ({ sourceAgencyGroupType }) => sourceAgencyGroupType === v
        );
        return acc;
      },
      {} as Partial<Record<AgencyTypeEnum, boolean>>
    );
  }, [buyerProfile, approvedSources]);

  useEffect(() => {
    (async () => {
      try {
        const response = await ApiService.apiV1ApprovedSourcesList();
        setApprovedSources(response);
      } catch (err) {
        handleError(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!sourceIdParam || !sourceNameParam) return;
    if (approvedSources.find(({ sourceId }) => sourceId === sourceIdParam))
      return;
    setShowAddSourceFromParam(true);
  }, [sourceIdParam, sourceNameParam, approvedSources]);

  const toggleApprovedSource = async ({
    sourceAgencyGroupType,
    sourceAgencyGroupState,
  }: {
    sourceAgencyGroupType?: Maybe<AgencyTypeEnum>;
    sourceAgencyGroupState?: Maybe<StateCodeEnum>;
  }) => {
    const originalSources = [...approvedSources];
    const source = originalSources.find((s) => {
      if (sourceAgencyGroupType)
        return s.sourceAgencyGroupType === sourceAgencyGroupType;
      if (sourceAgencyGroupState)
        return s.sourceAgencyGroupState === sourceAgencyGroupState;
      return false;
    });

    if (source) {
      const trackingParams: TrackRemoveApprovedSourceParams = {
        origin: "toggle",
        sourceName: source.sourceName,
        sourceTypeLabel: source.sourceTypeLabel,
      };
      await onRemoveSource(source.id, trackingParams);
      trackRemoveApprovedSourceGroup(trackingParams);
      return;
    }

    const response = await onAddSource({
      sourceAgencyGroupState,
      sourceAgencyGroupType,
    });
    if (response) {
      trackAddApprovedSourceGroup({
        sourceName: response.sourceName,
        sourceTypeLabel: response.sourceTypeLabel,
      });
    }
  };

  async function onAddSource(source: CreateApprovedSourceRequest) {
    try {
      const response = await ApiService.apiV1ApprovedSourcesCreate(source);

      // Auto-enable sorting preference when user adds the entity's first approved source
      if (
        buyerProfile.governmentAgency &&
        !buyerProfile.governmentAgency.showOnlyApprovedSources &&
        !approvedSources.length
      ) {
        onChangePreferences("", true);
      }

      setApprovedSources((prev) => [...prev, response]);
      return response;
    } catch (err) {
      handleError(err);
    }
  }

  const onAddSourceFromInput: BLAAutocompleteInputOnChange = async (
    _name,
    agency,
    { clear }
  ) => {
    if (!agency) return;

    const response = await onAddSource({ sourceAgencyId: agency.id });
    if (!response) return;

    trackAddNewApprovedSource({
      sourceName: response.sourceName,
      sourceTypeLabel: response.sourceTypeLabel,
      sourceId: response.sourceId,
    });
    clear();
  };

  async function onAddSourceFromParam() {
    const response = await onAddSource({ sourceAgencyId: sourceIdParam });
    if (!response) return;

    trackAddNewApprovedSource({
      sourceName: response.sourceName,
      sourceTypeLabel: response.sourceTypeLabel,
      sourceId: response.sourceId,
      fromParam: true,
    });
    setShowAddSourceFromParam(false);
    setPopupState({
      analyticsClassName:
        "analytics-approved-sources-source-from-param-added-popup",
      name: PopupType.SUCCESS,
      durationSeconds: 5,
      children: (
        <Typography color="inverse">"{response.sourceName}" added</Typography>
      ),
      show: true,
    });
  }

  async function onRemoveSource(
    id: string,
    trackingParams: TrackRemoveApprovedSourceParams
  ) {
    try {
      await ApiService.apiV1ApprovedSourcesDestroy(id);
      setApprovedSources((prev) => prev.filter((source) => source.id !== id));
      trackRemoveApprovedSource(trackingParams);
    } catch (err) {
      handleError(err);
    }
  }

  async function onChangePreferences(_: string, checked: boolean) {
    if (!buyerProfile.governmentAgency) return;
    try {
      await ApiService.apiV1BuyerLeadAgenciesPartialUpdate(
        buyerProfile.governmentAgency.id,
        { showOnlyApprovedSources: checked }
      );

      const buyerProfileUpdate = {
        ...buyerProfile,
        governmentAgency: {
          ...buyerProfile.governmentAgency,
          showOnlyApprovedSources: checked,
        },
      };
      setBuyerProfile(buyerProfileUpdate);
      trackCheckShowApprovedSourcesFirst({
        checked,
      });
    } catch (err) {
      handleError(err);
    }
  }

  const updateApprovedSourceStatus = async (
    id: string,
    status: ApprovedSourceStatusEnum
  ) => {
    try {
      const updatedSource = await ApiService.apiV1ApprovedSourcesUpdate(id, {
        status,
      });
      let update: ApprovedSource[];
      if (status === ApprovedSourceStatusEnum.REJECTED) {
        update = approvedSources.filter((source) => source.id !== id);
      } else {
        update = approvedSources.map((source) => {
          return source.id !== id ? source : updatedSource;
        });
      }
      trackUpdateApprovedSourceStatus({
        sourceName: updatedSource.sourceName,
        sourceTypeLabel: updatedSource.sourceTypeLabel,
        sourceId: updatedSource.sourceId,
        newStatus: status,
      });
      setApprovedSources(update);
    } catch (err) {
      handleError(err);
    }
  };

  const agencyName = buyerProfile?.governmentAffiliationDisplayName;
  const stateCode = buyerProfile?.governmentAgency?.stateCode;
  const stateName = stateCode ? stateLabels[stateCode] : null;
  const agencyType = buyerProfile?.governmentAgency?.agencyType;
  const showEntityCheckbox =
    !!agencyType && agencyType !== AgencyTypeEnum.OTHER;
  const extraAgencyTypes =
    (agencyType && EXTRA_SOURCES_FOR_AGENCY_TYPE[agencyType]) || [];

  return (
    <div className="flex flex-col gap-10 md:gap-16 md:grid md:grid-cols-9 md:gap-x-6">
      <div className="flex flex-col md:flex-row md:grid md:grid-cols-9 md:col-span-9 md:gap-x-6">
        <div className="grid gap-8 mb-4 md:mb-0 md:col-span-6">
          {showAddSourceFromParam && (
            <div
              className={clsx(
                "flex flex-col rounded-3 p-6 gap-4",
                bgColorClass.brand.subtler.enabled
              )}
            >
              <Typography variant="body">
                Add <strong>{sourceNameParam}</strong> as a new approved source?
              </Typography>
              <div className="flex gap-2">
                <Button
                  size={Button.sizes.SMALL}
                  theme={Button.themes.PRIMARY_DARK}
                  onClick={onAddSourceFromParam}
                >
                  Confirm
                </Button>
                <Button
                  size={Button.sizes.SMALL}
                  theme={Button.themes.TERTIARY_DARK}
                  onClick={() => setShowAddSourceFromParam(false)}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          )}
          <div className="grid gap-2">
            <Typography
              variant="headline"
              size="sm"
              color="brand.boldest.enabled"
              emphasis
            >
              Manage contract sources
            </Typography>
            <Typography color="neutral.bolder.enabled">
              Customize your team's search experience by selecting approved
              contract sources. Contracts from these sources will show up at the
              top of search results for{" "}
              <span className="font-semibold">all team members</span>.
            </Typography>
          </div>
          <div className="flex flex-col gap-3">
            <OptimisticPreferenceCheckbox
              name="addNationalCooperatives"
              analyticsClassName="analytics-approved-sources-allCoop"
              label="Add all national cooperatives"
              onChange={() =>
                toggleApprovedSource({
                  sourceAgencyGroupType: AgencyTypeEnum.NATIONAL_COOPERATIVE,
                })
              }
              checked={hasAllNationalCoops}
              size="none"
            />
            <OptimisticPreferenceCheckbox
              name="addStateSources"
              analyticsClassName="analytics-approved-sources-allState"
              label={`Add all local and regional sources in ${stateName}`}
              onChange={() =>
                toggleApprovedSource({
                  sourceAgencyGroupState: stateCode,
                })
              }
              checked={hasAllStateSources}
              size="none"
            />
            {showEntityCheckbox && (
              <OptimisticPreferenceCheckbox
                name="addEntityTypeSources"
                analyticsClassName="analytics-approved-sources-allEntityType"
                label={`Add all ${buyerProfile.governmentAgency.agencyTypeLabel.toLocaleLowerCase()} sources`}
                onChange={() =>
                  toggleApprovedSource({
                    sourceAgencyGroupType: agencyType,
                  })
                }
                checked={hasAllSameEntityType}
                size="none"
              />
            )}
            {extraAgencyTypes.map((extraType) => (
              <OptimisticPreferenceCheckbox
                key={extraType}
                name={`add${extraType}Sources`}
                analyticsClassName={`analytics-approved-sources-all${extraType}`}
                label={`Add all ${AGENCY_TYPE_DISPLAY_STRING[extraType].toLowerCase()} contracts`}
                onChange={() =>
                  toggleApprovedSource({
                    sourceAgencyGroupType: extraType,
                  })
                }
                checked={hasAllExtraAgencySources[extraType] || false}
                size="none"
              />
            ))}
          </div>
          <div className="relative">
            <BLAAutocompleteInput
              analyticsClass="analytics-approved-sources-input"
              label="Add a cooperative, state, or local agency"
              sublabel="Start typing to see contract sources"
              onChange={onAddSourceFromInput}
              useAddComponent
              freeSolo={false}
              excludeCooperatives={false}
            />
          </div>
        </div>
        <div className="md:col-span-3">
          <ContactBox
            headline="Can't find the contract source you're looking for?"
            body={
              <>
                Let us know by emailing{" "}
                <SupportEmailLink
                  className="analytics-click-pro-supplier-bulk-contract-email"
                  underline
                  email={CONTRACT_EMAIL_ADDRESS}
                />
                .
              </>
            }
          />
        </div>
      </div>
      <div className="grid gap-8 md:col-span-9">
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
        >
          Approved sources for {agencyName}
        </Typography>
        <ApprovedSourcesTable
          sources={approvedSources}
          onRemoveSource={onRemoveSource}
          updateApprovedSourceStatus={updateApprovedSourceStatus}
          allowEdits
        />
      </div>
      <div className="md:grid md:col-span-9 md:grid-cols-9 md:gap-6">
        <div className="md:col-span-6">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
            emphasis
          >
            How do you want results to appear to your team in search?
          </Typography>
          <PreferenceCheckbox
            size="lg"
            name="sortApprovedSourcesFirst"
            analyticsClassName="analytics-approved-sources-sorting-preferences"
            label="Show contracts from approved sources first in search results for all team members"
            onChange={onChangePreferences}
            checked={!!buyerProfile?.governmentAgency?.showOnlyApprovedSources}
          />
        </div>
        <img
          className="hidden md:block md:col-span-3"
          src={approvedSourcesSorting}
        />
      </div>
    </div>
  );
}
