import type { FC, ReactNode } from "react";

import { Avatar, AvatarSizes, Tooltip, Typography } from "../../../library";
import ContractCTASection from "../../../shared/ContractBase/ContractCTASection";
import type { CTA } from "../../../shared/ContractBase/types";
import { SingleDocumentIcon } from "../../../shared/CustomIcons/SingleDocumentIcon";
import type { LoginWallTriggers } from "../../../utils/enums";

interface ContractCardHeaderProps {
  title: ReactNode;
  contractNumber: string;
  expiration?: string;
  ctas?: CTA[];
  IconComponent?: Maybe<FC<{ className?: string }>>;
  IconClass?: string;
  semanticContext?: ReactNode;
  isExpired?: boolean;
  loginWallTrigger?: LoginWallTriggers;
  loginWallTriggerId?: string;
}
function ContractCardHeader({
  title,
  contractNumber,
  expiration,
  ctas,
  IconComponent,
  IconClass,
  semanticContext,
  isExpired,
  loginWallTrigger,
  loginWallTriggerId,
}: ContractCardHeaderProps) {
  let header = (contractNumber || expiration) && (
    <div className="flex justify-between gap-2 items-center">
      <Typography
        variant="meta"
        size="sm"
        color="neutral.bold.enabled"
        className="line-clamp-1"
      >
        {contractNumber}
      </Typography>
      <div className="flex justify-between items-center gap-4">
        {expiration && (
          <Typography
            variant="meta"
            size="sm"
            color={
              isExpired ? "accent.persimmon.enabled" : "neutral.bold.enabled"
            }
            className="whitespace-nowrap"
          >
            {expiration}
          </Typography>
        )}
        {!!ctas?.length && (
          <ContractCTASection
            loginWallTrigger={loginWallTrigger}
            loginWallTriggerId={loginWallTriggerId}
            ctas={ctas}
          />
        )}
      </div>
    </div>
  );

  if (semanticContext) {
    header = (
      <Tooltip className="max-w-88" info={semanticContext}>
        {header}
      </Tooltip>
    );
  }

  const getAvatar = () => {
    if (IconComponent === null) return null;

    if (IconComponent === undefined) {
      IconComponent = SingleDocumentIcon;
    }

    return (
      <Avatar
        size={AvatarSizes.LARGE}
        IconComponent={IconComponent}
        bgColor="accent.persimmon.enabled"
        className={IconClass}
      />
    );
  };
  const avatar = getAvatar();

  return (
    <div className="flex gap-4">
      {avatar && <div className="w-12">{avatar}</div>}
      <div className="flex flex-col gap-1 w-full">
        {header}
        {title}
      </div>
    </div>
  );
}

export default ContractCardHeader;
