import { Button, Link, Typography } from "../../../library";
import { getWalkthroughLink } from "../../../utils";

export default function ScheduleDemoSection() {
  return (
    <div className="bg-cp-lapis-500">
      <div className="flex flex-col items-center text-center py-12 md:py-16 cp-page-container xl:px-0 m-auto">
        <Typography
          variant="headline"
          size="lg"
          className="font-homeHeader md:text-cp-display-lg"
          color="neutral.subtlest.enabled"
          component="h2"
        >
          Not sure where to start?
        </Typography>
        <Typography
          color="neutral.subtlest.enabled"
          className="mt-4 md:text-cp-body-lg"
        >
          Talk with us about how your agency could use Pavilion.
        </Typography>
        <Button
          as={Link}
          className="analytics-homepage-scheduleDemo mt-6"
          linkProps={{
            href: getWalkthroughLink("bpcta", "website"),
            newWindow: true,
            underline: false,
          }}
          theme={Button.themes.PRIMARY_LIGHT}
        >
          Schedule a walkthrough
        </Button>
      </div>
    </div>
  );
}
