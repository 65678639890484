import { useAtom } from "jotai";
import { useEffect } from "react";
import searchContracts from "../../../img/icons/contracts-search.svg";
import shoppingCart from "../../../img/icons/shoppingCart.svg";
import {
  ApiService,
  type BuyerIntentSurvey,
  SearchIntentEnum,
} from "../../generated";
import type { OnCompleteFn } from "../../hooks/useSearchIntentSurvey";
import useShowModal from "../../hooks/useShowModal";
import { userStateState } from "../../jotai/user";
import { GenericCard, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { trackClickSearchIntent } from "../../utils/tracking";

interface SearchIntentModalProps {
  hideModal: () => void;
  intentSurvey?: BuyerIntentSurvey;
  onComplete: OnCompleteFn;
}

export default function SearchIntentModal({
  hideModal,
  intentSurvey,
  onComplete,
}: SearchIntentModalProps) {
  const showSupplierFlexibility = useShowModal(
    modals.SEARCH_INTENT_SUPPLIER_FLEXIBILITY_MODAL
  );
  const showSupplierPurchaseMethod = useShowModal(
    modals.SEARCH_INTENT_SUPPLIER_PURCHASE_METHOD_MODAL
  );
  const [userState, setUserState] = useAtom(userStateState);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run this once on mount.
  useEffect(() => {
    // When viewed, update the last viewed date to avoid showing the modal frequently.
    (async () => {
      try {
        await ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", {
          lastViewedIntentSurveyAt: new Date().toISOString(),
        });
        setUserState({ ...userState, showIntentSurvey: false });
      } catch (e) {
        handleError(e);
      }
    })();
  }, []);

  async function handleClickIntent(searchIntent: SearchIntentEnum) {
    trackClickSearchIntent({ searchIntent });

    try {
      let response = null;
      if (intentSurvey?.id) {
        response = await ApiService.apiV1IntentSurveysPartialUpdate(
          intentSurvey.id,
          {
            searchIntent,
          }
        );
      } else {
        response = await ApiService.apiV1IntentSurveysCreate({
          searchIntent,
        });
      }

      if (searchIntent === SearchIntentEnum.BUYING) {
        showSupplierFlexibility({ onComplete, intentSurvey: response });
        return;
      }
      if (searchIntent === SearchIntentEnum.RESEARCHING) {
        showSupplierPurchaseMethod({ onComplete, intentSurvey: response });
        return;
      }
    } catch (e) {
      handleError(e);
      hideModal();
    }
  }

  return (
    <Modal
      modalSize={modalSizes.SMALL}
      className="search-intent-modal"
      title="What's your goal?"
      hideModal={hideModal}
      isBlocking
      // overflow-visible prevents the GenericCard dropshadow from being clipped
      contentClassName="grid grid-cols-2 gap-6 !overflow-visible"
    >
      <GenericCard
        responsive
        className="!p-4 flex flex-col gap-4 text-center items-center"
        onClick={() => handleClickIntent(SearchIntentEnum.BUYING)}
        dataTestId="intent-buying"
      >
        <img src={shoppingCart} className="w-fit h-24" />
        <Typography
          color="neutral.boldest.enabled"
          variant="headline"
          size="xs"
          emphasis
        >
          I am buying now
        </Typography>
        <Typography color="neutral.bolder.enabled" size="sm">
          Show me active, shareable contracts I can use right away.
        </Typography>
      </GenericCard>
      <GenericCard
        responsive
        className="!p-4 flex flex-col gap-4 text-center items-center"
        onClick={() => handleClickIntent(SearchIntentEnum.RESEARCHING)}
        dataTestId="intent-researching"
      >
        <img src={searchContracts} className="w-fit h-24" />
        <Typography
          color="neutral.boldest.enabled"
          variant="headline"
          size="xs"
          emphasis
        >
          I'm doing research for an RFP or future purchase
        </Typography>
        <Typography color="neutral.bolder.enabled" size="sm">
          Show me options relevant for future purchase.
        </Typography>
      </GenericCard>
    </Modal>
  );
}
