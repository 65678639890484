import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import { inferredEntitySignupState } from "../../../jotai/signup";
import { userTypeSignupState } from "../../../jotai/user";
import { Link, Typography, type TypographyVariant } from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import { loginSignupAccountTypes } from "../../../utils/enums";

export default function ChangeUserTypeMessage({
  checkInferredEntity = false,
}: { checkInferredEntity?: boolean }) {
  const inferredEntity = useAtomValue(inferredEntitySignupState);
  const [userType, setUserType] = useAtom(userTypeSignupState);
  const isSupplier = userType === loginSignupAccountTypes.SUPPLIER;

  async function toggleUserType() {
    setUserType(
      isSupplier
        ? loginSignupAccountTypes.BUYER
        : loginSignupAccountTypes.SUPPLIER
    );
  }

  const linkProps = {
    variant: "meta" as TypographyVariant,
    onClick: toggleUserType,
    newWindow: false,
    underline: false,
    emphasis: false,
  };

  if (checkInferredEntity) {
    let message = "";
    let linkText = "";
    if (isSupplier) {
      message = inferredEntity?.supplierDisplayName
        ? "We found a public entity associated with this email address."
        : "Do you work for a public entity? Pavilion helps find the best contracts for your needs.";
      linkText = "Sign up to sell on Pavilion";
    } else {
      message = inferredEntity?.buyerLeadAgencyDisplayName
        ? "We found a business associated with this email address."
        : "That doesn't look like a government email address. Are you a supplier?";
      linkText = "Sign up as a supplier";
    }
    return (
      <div
        className={clsx(bgColorClass.accent.persimmon.subtle, "p-2 rounded-4")}
      >
        <Typography variant="meta" size="md" color="neutral.bolder.enabled">
          {message}{" "}
          <Link size="md" {...linkProps}>
            {linkText}
          </Link>
        </Typography>
      </div>
    );
  }

  const message = isSupplier ? "Not a supplier?" : "Are you a supplier?";
  const linkText = isSupplier
    ? "Sign up as a government buyer"
    : "Sign up to sell on Pavilion";

  return (
    <Typography variant="meta" size="sm" color="neutral.bold.enabled">
      {message}{" "}
      <Link size="sm" {...linkProps}>
        {linkText}
      </Link>
    </Typography>
  );
}
