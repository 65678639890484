import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import CategorySection from "../components/Home/HomepageComponents/CategorySection";
import type { MegaCategories } from "../generated";
import { getDOMAnchorById } from "../utils";

import Page, { type AuthPageProps } from "./Page/Page";

interface CategoriesProps extends AuthPageProps {
  categories: MegaCategories[];
}

export default function Categories({
  categories,
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: CategoriesProps) {
  return (
    <Page
      searchSource="categories-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <CategorySection categories={categories} />
    </Page>
  );
}

const container = getDOMAnchorById("categories-page") as HTMLElement;
const data = _get(container, "dataset.categories");
if (data) {
  const root = createRoot(container);
  root.render(<Categories {...JSON.parse(data)} />);
}
