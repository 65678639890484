import type { ReactNode } from "react";

import clsx from "clsx";
import { BORDER_LABEL_CLASS } from "../Input/constants";
import Typography, { type TypographyProps } from "../Typography";
import type { TypographyVariant } from "../Typography/types";

export interface LabelProps extends Omit<TypographyProps, "variant"> {
  children: ReactNode;
  variant: LabelVariant;
  sublabel?: ReactNode;
  sublabelClassName?: string;
  textVariant?: TypographyVariant;
}

export enum LabelVariant {
  BORDER = "BORDER",
  FLOATING = "FLOATING",
  FLOATING_BLOCK = "FLOATING_BLOCK",
}

export const LABEL_VARIANTS: Record<
  LabelVariant,
  {
    labelClass: string;
    sublabelClass?: string;
    defaultTextVariant: TypographyVariant;
    defaultLabelSize: "sm" | "md";
  }
> = {
  [LabelVariant.BORDER]: {
    labelClass: clsx(
      BORDER_LABEL_CLASS,
      "group-hover:bg-gradient-to-b from-50% from-cp-white-100 to-cp-neutral-palette-50"
    ),
    defaultTextVariant: "meta",
    defaultLabelSize: "sm",
  },
  [LabelVariant.FLOATING]: {
    labelClass: "block mb-1",
    defaultTextVariant: "body",
    defaultLabelSize: "md",
  },
  [LabelVariant.FLOATING_BLOCK]: {
    labelClass: "block mb-1",
    sublabelClass: "block",
    defaultTextVariant: "body",
    defaultLabelSize: "md",
  },
};

const FLOATING_VARIANTS = [LabelVariant.FLOATING, LabelVariant.FLOATING_BLOCK];

export function isFloatingVariant(variant: LabelVariant) {
  return FLOATING_VARIANTS.includes(variant);
}

export default function Label({
  children,
  size,
  variant,
  sublabel,
  textVariant,
  className,
  ...rest
}: LabelProps) {
  const { labelClass, sublabelClass, defaultTextVariant, defaultLabelSize } =
    LABEL_VARIANTS[variant];

  const typographySize = size || defaultLabelSize;
  const typographyVariant = textVariant || defaultTextVariant;

  return (
    <Typography
      className={clsx(labelClass, className)}
      component="label"
      size={typographySize}
      variant={typographyVariant}
      {...rest}
    >
      {children}
      {sublabel && (
        <Typography
          component="span"
          size={typographySize}
          variant={typographyVariant}
          color="neutral.bold.enabled"
          className={sublabelClass}
        >
          {variant !== LabelVariant.FLOATING_BLOCK ? " " : ""}
          {sublabel}
        </Typography>
      )}
    </Typography>
  );
}
