import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import clsx from "clsx";
import {
  type ChangeEventHandler,
  type ElementType,
  type FocusEventHandler,
  type KeyboardEventHandler,
  type ReactNode,
  forwardRef,
  useEffect,
  useState,
} from "react";

import { bgColorClass, borderColorClass } from "../../../utils/colors";
import Label, { LabelVariant, isFloatingVariant } from "../../Label";
import Typography from "../../Typography";
import type { TypographyColor } from "../../Typography/types";
import type { FieldLabelProps, TextInputTypes } from "../../form/types";
import {
  INPUT_STYLE,
  type InputComponentType,
  SHARED_INPUT_BORDER_STYLE,
  TEXTAREA_SIZES,
  borderClassName,
  sizeClassname,
} from "../constants";

export enum LabeledInputVariants {
  DEFAULT = "default",
  ACTIVE = "active",
  SUCCESS = "success",
  ACTIVE_ERROR = "activeError",
  ERROR = "error",
  DISABLED = "disabled",
  READ_ONLY = "readOnly",
}

export type InputSizes = "lg" | "md" | "sm";

export const VARIANTS: Record<
  LabeledInputVariants,
  {
    containerClass?: string;
    borderClass: string;
    textColor: TypographyColor;
    bgColor?: string;
    labelColor?: TypographyColor;
    messageColor?: TypographyColor;
  }
> = {
  [LabeledInputVariants.DEFAULT]: {
    containerClass: "focus-within:outline-cp-neutral-palette-200",
    borderClass: `${borderColorClass.neutral.subtle.enabled} hover:bg-cp-neutral-palette-50`,
    textColor: "neutral.boldest.enabled",
    bgColor: bgColorClass.neutral.subtlest.enabled,
    messageColor: "neutral.bold.enabled",
  },
  [LabeledInputVariants.ACTIVE]: {
    containerClass: "focus-within:outline-cp-lapis-500",
    borderClass: `${borderColorClass.brand.bold.enabled} border-2 hover:bg-cp-neutral-palette-50`,
    textColor: "neutral.boldest.enabled",
    labelColor: "brand.default.primary.enabled",
    messageColor: "neutral.bold.enabled",
  },
  [LabeledInputVariants.SUCCESS]: {
    containerClass: "focus-within:outline-cp-leaf-400",
    borderClass: `${borderColorClass.feedback.bold.success} hover:bg-cp-neutral-palette-50`,
    textColor: "feedback.bold.success",
  },
  [LabeledInputVariants.ERROR]: {
    containerClass: "focus-within:outline-cp-red-200",
    borderClass: `${borderColorClass.feedback.bold.error} hover:bg-cp-neutral-palette-50`,
    textColor: "feedback.bold.error",
  },
  [LabeledInputVariants.ACTIVE_ERROR]: {
    containerClass: "focus-within:outline-cp-red-200",
    borderClass: `${borderColorClass.feedback.bold.error} border-2 hover:bg-cp-neutral-palette-50`,
    textColor: "feedback.bold.error",
  },
  [LabeledInputVariants.DISABLED]: {
    borderClass: borderColorClass.disabled,
    textColor: "disabled",
  },
  [LabeledInputVariants.READ_ONLY]: {
    containerClass: "focus-within:outline-cp-neutral-palette-200",
    borderClass: borderColorClass.neutral.subtle.enabled,
    textColor: "neutral.boldest.enabled",
    bgColor: bgColorClass.neutral.subtlest.hovered,
    messageColor: "neutral.bold.enabled",
  },
};

export interface LabeledInputProps extends FieldLabelProps {
  initialVariant?: LabeledInputVariants;
  validationIcons?: boolean;
  validationMessage?: string;
  component?: InputComponentType;
  id?: string;
  name?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: Maybe<string>;
  sublabel?: string;
  message?: string;
  TrailingIcon?: ElementType;
  LeadingIcon?: ElementType;
  onClickTrailingIcon?: () => void;
  iconClassName?: string;
  type?: TextInputTypes;
  placeholder?: string;
  size?: InputSizes;
  onKeyUp?: (e: KeyboardEvent) => void;
  autoComplete?: string;
  required?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  readOnly?: boolean;
  resizeNone?: boolean;
  rows?: number;
  inputClassName?: string;
  trailingText?: ReactNode;
  leadingText?: ReactNode;
  renderChildren?: () => ReactNode;
}

const LabeledInput = forwardRef(function LabeledInput(
  {
    initialVariant = LabeledInputVariants.DEFAULT,
    validationIcons,
    validationMessage,
    component = "input" as InputComponentType,
    id,
    name,
    className,
    onChange,
    onKeyDown,
    onBlur,
    value,
    label,
    labelStyle = LabelVariant.BORDER,
    labelSize,
    labelTextVariant,
    labelEmphasis = true,
    labelClassName,
    sublabel,
    message,
    type,
    placeholder,
    size = "lg",
    onKeyUp,
    autoComplete = "on",
    TrailingIcon,
    trailingText,
    onClickTrailingIcon,
    LeadingIcon,
    leadingText,
    iconClassName,
    required = false,
    dataTestId = "input",
    autoFocus = false,
    readOnly = false,
    resizeNone = false,
    inputClassName,
    rows,
    renderChildren,
  }: LabeledInputProps,
  ref
) {
  const [variant, setVariant] = useState(initialVariant);

  useEffect(() => {
    setVariant(initialVariant);
  }, [initialVariant]);

  const handleBlur: FocusEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    if (variant === LabeledInputVariants.ACTIVE) {
      setVariant(LabeledInputVariants.DEFAULT);
    }
    onBlur?.(e);
  };

  const handleFocus: FocusEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = () => {
    if (readOnly) return;
    if (variant === LabeledInputVariants.DEFAULT) {
      setVariant(LabeledInputVariants.ACTIVE);
    }
  };

  let ValidationIcon = undefined;
  if (validationIcons) {
    if (initialVariant === LabeledInputVariants.ERROR) {
      ValidationIcon = ErrorRoundedIcon;
    } else if (initialVariant === LabeledInputVariants.SUCCESS) {
      ValidationIcon = CheckCircleRoundedIcon;
    }
  }
  if (validationMessage && initialVariant === LabeledInputVariants.SUCCESS) {
    message = validationMessage;
  }

  const { textColor, labelColor, messageColor, containerClass, bgColor } =
    VARIANTS[variant];

  const isFloating = isFloatingVariant(labelStyle);
  const labelComponent = label ? (
    <Label
      htmlFor={id || name}
      variant={labelStyle}
      emphasis={labelEmphasis}
      color={labelColor || textColor}
      className={labelClassName}
      size={labelSize}
      textVariant={labelTextVariant}
      sublabel={isFloating ? sublabel : undefined}
    >
      {label}
    </Label>
  ) : null;
  return (
    <div>
      {isFloating && labelComponent}
      <div
        className={clsx(
          SHARED_INPUT_BORDER_STYLE,
          containerClass,
          bgColor,
          { [bgColorClass.neutral.subtlest.hovered]: readOnly },
          className
        )}
      >
        <div
          className={clsx(
            borderClassName(variant),
            sizeClassname(size, component),
            inputClassName
          )}
        >
          {!isFloating && labelComponent}
          {(leadingText || LeadingIcon) && (
            <Typography
              size={size}
              component={LeadingIcon || "span"}
              color={textColor}
              className={clsx("absolute w-4 left-2", iconClassName, {
                "h-4": LeadingIcon,
              })}
            >
              {leadingText}
            </Typography>
          )}
          <Typography
            component={component}
            color={textColor}
            ref={ref}
            // @ts-ignore
            id={id || name}
            name={name}
            type={type || "text"}
            className={clsx(INPUT_STYLE, {
              [TEXTAREA_SIZES[size]]: component === "textarea" && !rows,
              "resize-none": resizeNone,
              "!pr-8": trailingText || TrailingIcon,
              "!pl-6": leadingText || LeadingIcon,
              "no-input-arrows": type === "number",
            })}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autoFocus={autoFocus}
            required={required}
            readOnly={readOnly}
            disabled={variant === LabeledInputVariants.DISABLED}
            value={value}
            autoComplete={autoComplete}
            data-testid={dataTestId}
            size={size}
            rows={rows}
            onWheel={(e) => {
              // Disable browser default scroll behavior
              if (type === "number" && e.target instanceof HTMLInputElement)
                e.target.blur();
            }}
          />
          {(trailingText || TrailingIcon) && (
            <Typography
              onClick={onClickTrailingIcon}
              size={size}
              component={TrailingIcon || "span"}
              color={textColor}
              className={clsx("absolute w-4", iconClassName, {
                "cursor-pointer": onClickTrailingIcon,
                "h-4 right-[0.5rem]": TrailingIcon,
              })}
            >
              {trailingText}
            </Typography>
          )}
        </div>
        {renderChildren?.()}
        <div className="grid gap-1 mt-2 mr-2 empty:hidden">
          {(message || ValidationIcon) && (
            <div className="flex gap-1 items-center">
              {ValidationIcon && (
                <Typography
                  component={ValidationIcon}
                  variant="body"
                  color={textColor || messageColor}
                />
              )}
              {message && (
                <Typography
                  size="sm"
                  variant="meta"
                  color={textColor || messageColor}
                >
                  {message}
                </Typography>
              )}
            </div>
          )}
          {!isFloating && sublabel && (
            <Typography size="sm" variant="meta" color={messageColor}>
              {sublabel}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
});

export default LabeledInput;
