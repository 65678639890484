import clsx from "clsx";
import {
  type ElementType,
  type ForwardRefExoticComponent,
  type MouseEvent,
  type ReactNode,
  type Ref,
  forwardRef,
} from "react";

import { OUTLINE_FOCUS_CLASS } from "../../utils/constants";
import Badge, { type BadgeProps } from "../Badge";
import type { LinkProps } from "../Link";
import Typography, { type TypographyProps } from "../Typography";

export enum ButtonThemes {
  PRIMARY_DARK = "PRIMARY_DARK",
  PRIMARY_LIGHT = "PRIMARY_LIGHT",
  PRIMARY_DESTRUCTIVE = "PRIMARY_DESTRUCTIVE",
  SECONDARY_DARK = "SECONDARY_DARK",
  SECONDARY_LIGHT = "SECONDARY_LIGHT",
  SECONDARY_DESTRUCTIVE = "SECONDARY_DESTRUCTIVE",
  TERTIARY_DARK = "TERTIARY_DARK",
  TERTIARY_LIGHT = "TERTIARY_LIGHT",
  TERTIARY_DESTRUCTIVE = "TERTIARY_DESTRUCTIVE",
  OUTLINE = "OUTLINE",
  OUTLINE_SELECTED = "OUTLINE_SELECTED",
}

const stylesByTheme = {
  [ButtonThemes.PRIMARY_DARK]:
    "font-semibold text-cp-white-100 bg-cp-lapis-500 hover:bg-cp-midnight-100 active:bg-cp-midnight-200 disabled:bg-cp-neutral-50  focus-visible:outline-cp-lapis-500",
  [ButtonThemes.PRIMARY_LIGHT]:
    "font-semibold text-cp-midnight-300 disabled:text-cp-white-100 bg-cp-violet-300 hover:bg-cp-lapis-200 active:bg-cp-lapis-300 disabled:bg-cp-neutral-50  focus-visible:outline-cp-violet-300",
  [ButtonThemes.PRIMARY_DESTRUCTIVE]:
    "font-semibold text-cp-white-100 bg-cp-red-200 disabled:bg-cp-neutral-50 hover:bg-cp-red-300 active:bg-cp-red-400 focus-visible:outline-cp-red-200",
  [ButtonThemes.SECONDARY_DARK]:
    "font-semibold border border-solid border-cp-lapis-500 disabled:border-cp-neutral-palette-400 text-cp-lapis-500 hover:text-cp-white-100 active:text-cp-white-100 disabled:text-cp-neutral-palette-300 bg-transparent hover:bg-cp-lapis-500 active:bg-cp-midnight-200 focus-visible:outline-cp-lapis-500",
  [ButtonThemes.SECONDARY_LIGHT]:
    "font-semibold border border-solid border-cp-lapis-100 disabled:border-cp-neutral-palette-400 text-cp-violet-300 hover:text-cp-midnight-300 active:text-cp-midnight-300 disabled:text-cp-neutral-palette-300 bg-transparent hover:bg-cp-lapis-200 active:bg-cp-lapis-300 focus-visible:outline-cp-violet-300",
  [ButtonThemes.SECONDARY_DESTRUCTIVE]:
    "font-semibold border border-solid border-cp-red-200 disabled:border-cp-neutral-palette-400 text-cp-red-200 hover:text-cp-white-100 active:text-cp-white-100 disabled:text-cp-neutral-palette-300 bg-transparent hover:bg-cp-red-300 active:bg-cp-red-400 focus-visible:outline-cp-red-200",
  [ButtonThemes.TERTIARY_DARK]:
    "font-semibold text-cp-lapis-500 disabled:text-cp-neutral-palette-300 disabled:bg-transparent bg-transparent-dark-50 hover:bg-cp-transparent-dark-50 active:bg-cp-transparent-dark-100 focus-visible:outline-cp-lapis-500",
  [ButtonThemes.TERTIARY_LIGHT]:
    "font-semibold text-cp-violet-300 disabled:text-cp-neutral-palette-300 bg-transparent hover:bg-cp-transparent-light-50 active:bg-cp-transparent-light-100 focus-visible:outline-cp-violet-300",
  [ButtonThemes.TERTIARY_DESTRUCTIVE]:
    "font-semibold text-cp-red-200 disabled:text-cp-neutral-palette-300 bg-transparent hover:bg-cp-transparent-dark-50 active:bg-cp-transparent-dark-100 focus-visible:outline-cp-red-200",
  [ButtonThemes.OUTLINE]:
    "border border-solid text-cp-neutral-palette-900 hover:text-cp-lapis-500 active:text-cp-lapis-500 disabled:text-cp-neutral-50 bg-transparent hover:bg-cp-violet-100 active:bg-cp-violet-100 focus-visible:border-cp-lapis-500 active:border-cp-lapis-500",
  [ButtonThemes.OUTLINE_SELECTED]:
    "border border-solid text-cp-lapis-500 disabled:text-cp-neutral-50 bg-cp-violet-100 border-cp-lapis-500",
};

export enum ButtonSizes {
  SMALL = "SMALL",
  LARGE = "LARGE",
  XS = "XS",
}

const BASE_BUTTON_STYLES = `group inline-flex items-center justify-center duration-200
  transition-colors relative`;

export interface ButtonProps {
  as?: ElementType;
  name?: string;
  className?: string;
  children?: ReactNode;
  onClick?: (e?: MouseEvent) => void;
  badgeProps?: BadgeProps;
  linkProps?: LinkProps;
  disabled?: boolean;
  type?: string;
  size?: ButtonSizes;
  theme?: ButtonThemes;
  width?: string;
  rounded?: "right" | "full" | "circle";
  dataTestId?: string;
}

type ButtonType = ForwardRefExoticComponent<ButtonProps> & {
  sizes: typeof ButtonSizes;
  themes: typeof ButtonThemes;
};

const Button: ButtonType = forwardRef(function Button(
  {
    as = "button",
    name,
    className,
    onClick,
    children,
    badgeProps,
    linkProps,
    disabled = false,
    type,
    size = ButtonSizes.LARGE,
    theme = ButtonThemes.PRIMARY_DARK,
    rounded = "full",
    dataTestId = "button",
  }: ButtonProps,
  ref: Ref<HTMLElement>
) {
  const As = as;

  return (
    <As
      ref={ref}
      type={type}
      name={name}
      className={clsx(
        BASE_BUTTON_STYLES,
        OUTLINE_FOCUS_CLASS,
        className,
        {
          "cursor-pointer": !disabled,
          "pointer-events-none hover:bg-inherit bg-cp-neutral-50": disabled,
          "px-6 py-4 text-cp-cta-md": size === ButtonSizes.LARGE,
          "px-3 py-2 text-cp-cta-sm": size === ButtonSizes.SMALL,
          "px-1 py-1 text-cp-cta-sm": size === ButtonSizes.XS,
          "rounded-r-xl focus-visible:outline-0": rounded === "right",
          "rounded-xl": rounded === "full",
          "rounded-3xl": rounded === "circle",
        },
        stylesByTheme[theme]
      )}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
      {...linkProps}
    >
      {badgeProps?.Icon ? <Badge {...badgeProps}>{children}</Badge> : children}
    </As>
  );
}) as ButtonType;

Button.sizes = ButtonSizes;
Button.themes = ButtonThemes;

export default Button;

interface TextButtonProps extends ButtonProps {
  children: ReactNode;
  textProps: TypographyProps;
}

export const TextButton = ({
  children,
  textProps,
  ...props
}: TextButtonProps) => (
  <Button {...props}>
    <Typography {...textProps}>{children}</Typography>
  </Button>
);
