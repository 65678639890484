import { useAtomValue } from "jotai/index";
import listify from "listify";
import { useMemo } from "react";

import { supplierScopeState } from "../../../jotai/supplierScope";
import SummarySection from "../../../shared/SummarySection";
import { getSentenceCase } from "../../../utils";

export default function SupplierOfferingsSummary({
  offerings,
  supplierName,
  query,
}: { offerings: string[]; supplierName: string; query?: string }) {
  const { scope } = useAtomValue(supplierScopeState);

  const sortedOfferings = useMemo(() => {
    if (!offerings.length) return [];
    if (!scope.supplierOfferings?.length) return offerings;

    const firstRelevantOffering = scope.supplierOfferings[0].offering;
    const remainingOfferings = offerings.filter(
      (o) => o !== firstRelevantOffering
    );
    return [firstRelevantOffering, ...remainingOfferings];
  }, [offerings, scope]);

  const renderText = () => {
    if (!sortedOfferings.length) return null;

    // Get the most semantically relevant offerings, then primary, then alphabetical
    let offeringsText = listify(
      sortedOfferings
        .filter(Boolean)
        .slice(0, 3)
        .map((v) => `"${getSentenceCase(v)}"`),
      {
        finalWord: sortedOfferings.length <= 3 ? "and" : "",
      }
    );

    if (sortedOfferings.length > 3) offeringsText += ", and more";

    return `${supplierName} offers ${offeringsText}.`;
  };

  const offeringsToShow = useMemo(() => {
    return (
      scope.supplierOfferings
        ? scope.supplierOfferings.map((v) => v.offering)
        : offerings
    ).map(getSentenceCase);
  }, [offerings, scope]);

  return (
    <SummarySection
      query={query}
      renderText={renderText}
      items={offeringsToShow}
      linkClassName="analytics-scope-summary-keywords-toggle"
      expandText="supplier offerings"
    />
  );
}
