import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import SupplierEditForm from "../components/supplier/SupplierEditForm/SupplierEditForm";
import type { Supplier } from "../components/supplier/SupplierEditForm/types";
import { getDOMAnchorById } from "../utils";

import Page, { type AuthPageProps } from "./Page/Page";

interface SupplierEditFormPageProps extends AuthPageProps {
  supplier: Supplier;
}

export default function SupplierEditFormPage({
  supplier,
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: SupplierEditFormPageProps) {
  return (
    <Page
      searchSource="supplier-claim-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <SupplierEditForm supplier={supplier} />
    </Page>
  );
}

const container = getDOMAnchorById("supplier-edit") as HTMLElement;
if (_get(container, "dataset.supplier")) {
  const supplier = JSON.parse(container.dataset.supplier || "");
  const root = createRoot(container);
  root.render(<SupplierEditFormPage supplier={supplier} />);
}
