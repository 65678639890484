import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import {
  ConfirmSupplierContactPage,
  type ConfirmSupplierContactPageProps,
} from "../components/ConfirmSupplierContactPage";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface ConfirmSupplierContactProps
  extends ConfirmSupplierContactPageProps,
    AuthPageProps {}

export default function ConfirmSupplierContact({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
  supplier,
  contact,
  sharedLinkId,
}: ConfirmSupplierContactProps) {
  return (
    <Page
      pageType={PageType.CONFIRM_SUPPLIER_CONTACT}
      pageId={`${contact.email}-${supplier.handle}`}
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      showHeader={false}
      context={context}
      isSSR={isSSR}
    >
      <ConfirmSupplierContactPage
        supplier={supplier}
        contact={contact}
        sharedLinkId={sharedLinkId}
      />
    </Page>
  );
}

const container = getDOMAnchorById(
  "confirm-supplier-contact-page"
) as HTMLElement;
const data = _get(container, "dataset.unclaimedSupplier");
if (data) {
  const props = JSON.parse(data);
  const root = createRoot(container);
  root.render(<ConfirmSupplierContact {...props} />);
}
