import clsx from "clsx";

import supplierImg from "../../../img/welcome/supplierBanner.svg";
import { bgColorClass } from "../../utils/colors";
import { SUPPLIER_BANNER_CLASS, SUPPLIER_BANNER_HEIGHT } from "./constants";

/**
 * Note that this components loads a fair number of SVG's and should use
 * React.lazy when importing so it's not contained in the main bundle.
 **/

export default function SupplierBanner() {
  return (
    <div
      className={clsx(
        "flex justify-end overflow-x-hidden",
        SUPPLIER_BANNER_CLASS,
        SUPPLIER_BANNER_HEIGHT,
        bgColorClass.brand.subtler.pressed
      )}
    >
      <img
        src={supplierImg}
        alt="Welcome banner"
        className={clsx("mr-0 ml-auto w-full", SUPPLIER_BANNER_HEIGHT)}
      />
    </div>
  );
}
