import clsx from "clsx";
import { useState } from "react";
import noAnalytics from "../../../../img/icons/noAnalytics.svg";
import useSendEmailVerification from "../../../hooks/useSendEmailVerification";
import useShowModal from "../../../hooks/useShowModal";
import { Button, Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import { MODAL_SOURCE, modals } from "../../../utils/enums";
import {
  BLURRED_CONTAINER_STYLES,
  SAMPLE_SUPPLIER_DATA,
  SAMPLE_VIEW_ANALYTICS,
  UNBLURRED_BANNER_STYLES,
} from "../constants";
import BuyerInsights from "./BuyerInsights";

export default function UnverifiedEmailSection() {
  const [error, setError] = useState("");
  const showVerificationSuccess = useShowModal(
    modals.EMAIL_VERIFICATION_SUCCESS
  );
  const sendEmail = useSendEmailVerification({
    source: MODAL_SOURCE.WELCOME_PAGE,
    onError: setError,
  });

  return (
    <div className={BLURRED_CONTAINER_STYLES}>
      <BuyerInsights
        viewAnalytics={SAMPLE_VIEW_ANALYTICS}
        supplierData={SAMPLE_SUPPLIER_DATA}
        isLoading={false}
        className="blur select-none pointer-events-none"
      />
      <div
        className={clsx(
          "analytics-unverified-supplier-email-banner pb-0",
          UNBLURRED_BANNER_STYLES,
          bgColorClass.brand.subtler.enabled
        )}
      >
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
          className="text-center"
        >
          Buyer activity will be hidden until you verify your email.
        </Typography>
        <Button
          className="w-fit self-center"
          onClick={() =>
            sendEmail(() => showVerificationSuccess({ showCta: false }))
          }
        >
          Verify email
        </Button>
        <img
          src={noAnalytics}
          alt="paper plane"
          className="h-[130px] self-center"
        />
        {error && (
          <Typography
            variant="meta"
            color="feedback.bold.error"
            className="pb-6"
          >
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
}
