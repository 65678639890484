import multipleDocuments from "../../../img/icons/multiple-documents.svg";

interface MultipleDocumentsIconProps {
  className?: string;
}

export function MultipleDocumentsIcon({
  className,
}: MultipleDocumentsIconProps) {
  return (
    <img
      src={multipleDocuments}
      alt="stack of multiple contracts"
      className={className}
    />
  );
}
