import { Field, useField } from "formik";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { inferredEntitySignupState } from "../../../jotai/signup";
import { LabeledInputVariants } from "../../../library";
import type { BuyerLeadAgencyFieldValue } from "../../../library/form/BuyerLeadAgencyField";
import type { FormFieldProps } from "../../../library/form/types";

/**
 * To be used with component `BuyerLeadAgencyField`.
 * Subscribes and updates field values with `inferredEntitySignupState`.
 */
export default function InferredBuyerLeadAgencyField(props: FormFieldProps) {
  const [field, { value, error, touched }, { setValue }] =
    useField<BuyerLeadAgencyFieldValue>({
      name: props.name,
    });
  const [validationMessage, setValidationMessage] = useState("");
  const [variant, setVariant] = useState(LabeledInputVariants.DEFAULT);
  const inferredEntity = useAtomValue(inferredEntitySignupState);
  const [hasAutofilled, setHasAutofilled] = useState(false);

  useEffect(() => {
    // Empty field after validation
    if (touched && error && value.governmentAffiliationDisplayName === "") {
      setValidationMessage("");
      setVariant(LabeledInputVariants.ERROR);
      return;
    }

    // TODO: https://app.shortcut.com/coprocure/story/25118/show-social-proof-on-intial-sign-up
    // "{x} of your coworkers are on Pavilion" when we have 2+ accounts
    // "Your coworkers are on Pavilion" when we have 1 user account
    // "Your entity is on Pavilion" when we have 0 accounts
    if (value.governmentAgencyId) {
      setValidationMessage("Your entity is on Pavilion!");
      setVariant(LabeledInputVariants.SUCCESS);
      return;
    }

    // Handle inferring BLA from user email
    if (inferredEntity?.buyerLeadAgencyDisplayName) {
      const shouldResetField =
        hasAutofilled &&
        value.governmentAffiliationDisplayName !==
          inferredEntity.buyerLeadAgencyDisplayName;

      if (shouldResetField) {
        setValidationMessage("");
        setVariant(LabeledInputVariants.DEFAULT);
        return;
      }
      // If the field had previously been autofilled from inferredEntity,
      // but the user has since changed the field, reset the validation state
      setValue({
        governmentAgencyId: inferredEntity.buyerLeadAgencyId,
        governmentAffiliationDisplayName:
          inferredEntity.buyerLeadAgencyDisplayName,
      });
      setHasAutofilled(true);
    }
  }, [
    setValue,
    inferredEntity,
    hasAutofilled,
    touched,
    error,
    value.governmentAffiliationDisplayName,
    value.governmentAgencyId,
  ]);

  return (
    <Field
      field={field}
      validationMessage={validationMessage}
      initialVariant={variant}
      editable
      {...props}
    />
  );
}
