import clsx from "clsx";
import { Field } from "formik";
import type { ReactNode } from "react";
import { CardContainer, CardContainerVariant, Typography } from "../../library";
import type { FormFieldProps } from "../../library/form/types";
import { elevationClass } from "../../utils/designTokens";

interface CostCalculatorFormCardProps {
  title: string;
  subtitle?: string;
  fields: FormFieldProps[];
  children?: ReactNode;
}

export default function CostCalculatorFormCard({
  title,
  subtitle,
  fields,
  children,
}: CostCalculatorFormCardProps) {
  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "grid gap-5 p-5 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Typography
        variant="headline"
        className="font-homeHeader"
        color="brand.boldest.enabled"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography size="sm" color="neutral.bold.enabled">
          {subtitle}
        </Typography>
      )}
      {fields.map((props) => (
        <Field key={props.name} {...props} />
      ))}
      {children}
    </CardContainer>
  );
}
