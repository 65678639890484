import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { captureException } from "@sentry/browser";
import { useAtomValue, useSetAtom } from "jotai";
import _range from "lodash/range";
import { useEffect, useMemo, useState } from "react";
import type {
  SupplierContactPhoneNumber,
  UserSupplierContact,
} from "../../../generated";
import useLoginWall from "../../../hooks/useLoginWall";
import { popupState } from "../../../jotai/page";
import { isAuthenticatedState } from "../../../jotai/user";
import { Button, DropdownPicker, Typography } from "../../../library";
import {
  LoginWallTriggers,
  SupplierContactSourceType,
} from "../../../utils/enums";
import { formatPhoneNumber } from "../../../utils/format";
import {
  getContactDisplayName,
  groupContactsByTitle,
} from "../SupplierEditForm/SupplierEditSection/helpers";
import { ContactNavigationButtons } from "./SupplierContactNavigationButtons";
import useFetchContactNumberCallback from "./useFetchContactNumberCallback";

function PhoneNumber({
  number,
  contact,
}: {
  number: SupplierContactPhoneNumber | null;
  contact: UserSupplierContact;
}) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const requireLogin = useLoginWall();

  if (!isAuthenticated) {
    return (
      <Button
        size={Button.sizes.SMALL}
        theme={Button.themes.TERTIARY_DARK}
        onClick={() =>
          requireLogin({
            triggerId: contact.id.toString(),
            triggerType:
              LoginWallTriggers.SOLICITATION_PAGE_VIEW_PHONE_NUMBER_CLICK,
            onComplete: () => {},
          })
        }
        className="whitespace-nowrap w-fit"
        badgeProps={{
          Icon: LocalPhoneRoundedIcon,
          className: "gap-1",
        }}
      >
        View phone number
      </Button>
    );
  }

  if (!number) return null;

  return (
    <Typography size="sm" color="neutral.bolder.enabled">
      {formatPhoneNumber(number)}
    </Typography>
  );
}

export default function SupplierContactInfo({
  supplierId,
  supplierName,
  contacts,
}: {
  supplierId: number;
  supplierName: string;
  contacts: UserSupplierContact[];
}) {
  const requireLogin = useLoginWall();
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const setPopupState = useSetAtom(popupState);
  // The currently selected title. When null, we show the supplier's first contact.
  // When a title is selected, we show all the contacts with this title.
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);

  // Index for the current deduped contact, which may have multiple phone numbers.
  // We keep track of this so we can call `getSupplierContact` for one contact
  // at a time, rather than all of them at once.
  const [contactIndex, setContactIndex] = useState(0);
  const [number, setNumber] = useState<SupplierContactPhoneNumber | null>(null);
  const fetchContactNumber = useFetchContactNumberCallback();

  const contactsByTitle = useMemo(
    () => groupContactsByTitle(contacts),
    [contacts]
  );
  const selectedContactSet = selectedTitle
    ? contactsByTitle[selectedTitle]
    : contacts;
  const contactState = selectedContactSet[contactIndex];

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      setNumber(null);
      setNumber(
        await fetchContactNumber(
          supplierId,
          selectedContactSet[contactIndex].id
        )
      );
    })();

    // Fetch the next 5 contacts' phone numbers pre-emptively to make scrolling
    // between them faster.
    _range(contactIndex + 1, contactIndex + 6).forEach((ix) => {
      if (ix >= selectedContactSet.length) return;
      fetchContactNumber(supplierId, selectedContactSet[ix].id)?.catch((e) =>
        captureException(e)
      );
    });
  }, [
    isAuthenticated,
    supplierId,
    contactIndex,
    selectedContactSet,
    fetchContactNumber,
  ]);

  async function onClickBack() {
    setPopupState(null);
    if (contactIndex <= 0) return;
    setContactIndex(contactIndex - 1);
  }

  async function onClickNext() {
    requireLogin({
      triggerId: contactState.id.toString(),
      triggerType: LoginWallTriggers.SOLICITATION_PAGE_NEXT_CONTACT_CLICK,
      onComplete: async () => {
        setPopupState(null);
        if (contactIndex + 1 >= contacts.length) return;
        setContactIndex(contactIndex + 1);
      },
    });
  }

  const displayName = getContactDisplayName(contactState, supplierName);
  const isVerified =
    contactState.source === SupplierContactSourceType.MANUAL_ENTRY;
  const verifiedText = isVerified ? " (verified)" : null;

  const titles = Object.keys(contactsByTitle);
  const titleOptions = titles.map((title) => ({
    key: title,
    value: title,
    label: <Typography>{title}</Typography>,
  }));

  const dropdownOptions = [
    {
      key: "all-contacts",
      value: "",
      label: "All contacts",
    },
    ...titleOptions,
  ];

  return (
    <div className="flex flex-col gap-4 h-fit">
      {titleOptions.length > 1 && (
        <DropdownPicker
          initialValue={""}
          options={dropdownOptions}
          onChange={(value) => setSelectedTitle(value)}
          className="w-full"
          buttonClassName="!px-3"
        />
      )}
      <div className="flex flex-col gap-1">
        <Typography
          emphasis
          color="neutral.boldest.enabled"
          className="capitalize"
        >
          {displayName}
          <Typography
            component="span"
            emphasis={false}
            color="neutral.bolder.enabled"
          >
            {verifiedText}
          </Typography>
        </Typography>
        {(contactState.firstName || contactState.lastName) &&
          contactState.title && (
            <Typography color="neutral.bolder.enabled" size="sm">
              {contactState.title}
            </Typography>
          )}
        <PhoneNumber contact={contactState} number={number} />
      </div>
      {selectedContactSet.length > 1 && (
        <ContactNavigationButtons
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          isBackDisabled={contactIndex === 0}
          isNextDisabled={!selectedContactSet[contactIndex + 1]}
          contactIndex={contactIndex}
          contactsCount={selectedContactSet.length}
        />
      )}
    </div>
  );
}
