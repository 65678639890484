import _get from "lodash/get";
import { useMemo } from "react";
import {
  matchesForContractHit,
  matchesFromList,
  styleSearchResponseText,
} from "../components/ContractSearch/utils";
import {
  type ContractHit,
  MatchLevelEnum,
  type MatchResult,
  type RelevantContract,
} from "../generated";
import type { TypographyColor } from "../library/Typography/types";
import { textColorClass } from "../utils/colors";
import { truncateAroundTargetWord } from "../utils/format";

const maxWords = 8;
const maxCharacters = 200;

function formatScopeMatches({
  matches,
  limit,
  sentenceCase = true,
  color = "neutral.bold.enabled",
}: {
  matches: MatchResult[];
  limit: number;
  sentenceCase?: boolean;
  color?: TypographyColor;
}) {
  const filteredMatches =
    matches.length > 1
      ? matches.filter(
          ({ matchLevel }) => matchLevel !== MatchLevelEnum.SEMANTIC
        )
      : matches;

  // Early break for when we have no match information.
  if (!filteredMatches.length) {
    return null;
  }

  const scopeElements = filteredMatches.slice(0, limit).map((match) => {
    if (match.matchLevel === MatchLevelEnum.SEMANTIC) {
      return <span key={match.value}>{match.value}</span>;
    }
    const value = truncateAroundTargetWord(
      match.value,
      "<em>",
      maxWords,
      maxCharacters
    );

    const colorClass = _get(textColorClass, color);
    return styleSearchResponseText(value, {
      highlightClassName: `${colorClass} font-semibold`,
      sentenceCase,
    });
  });

  return scopeElements;
}

export function useScopeMatchElements({
  hit,
  limit,
}: {
  hit: ContractHit | RelevantContract;
  limit: number;
}) {
  const matches = useMemo(() => matchesForContractHit(hit), [hit]);
  return formatScopeMatches({ matches, limit });
}

export function useOfferingsMatchElements({
  hit,
  limit,
}: {
  hit: ContractHit | RelevantContract;
  limit: number;
}) {
  const matches = useMemo(
    () =>
      matchesFromList({
        matchResults: hit.offerings,
        key: "contractOfferings",
      }),
    [hit]
  );
  return formatScopeMatches({
    matches,
    limit,
    sentenceCase: false,
    color: "neutral.boldest.enabled",
  });
}
