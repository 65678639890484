import CostCalculatorFormCard from "./CostCalculatorFormCard";
import PurchaseTypeNotice from "./PurchaseTypeNotice";
import { type FieldHookProps, usePurchaseDetailsFields } from "./constants";

export default function PurchaseDetailsCard(props: FieldHookProps) {
  const fields = usePurchaseDetailsFields(props);

  return (
    <CostCalculatorFormCard title="Your purchase" fields={fields}>
      <PurchaseTypeNotice />
    </CostCalculatorFormCard>
  );
}
