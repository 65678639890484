import { createRoot } from "react-dom/client";

import NoSearchResults from "../../components/NoSearchResults";
import { Typography } from "../../library";
import { getDOMAnchorsByClass } from "../../utils";
import { SUPPORT_EMAIL_ADDRESS } from "../../utils/constants";

function Embed404() {
  return (
    <div className="mt-6 text-center w-5/12 m-auto">
      <NoSearchResults className="mx-auto mb-6 w-0 md:w-auto md:max-h-1/4-screen" />
      <Typography variant="headline" size="lg" emphasis color="brand.bold">
        Embed not found
      </Typography>
      <Typography color="subtler" className="mt-6 mb-6">
        Contact {SUPPORT_EMAIL_ADDRESS} to reset this embed
      </Typography>
    </div>
  );
}

const containers = getDOMAnchorsByClass("iframe-404");
if (containers && containers.length > 0) {
  for (let i = 0; i < containers.length; i++) {
    const root = createRoot(containers[i]);
    root.render(<Embed404 />);
  }
}
