import { useAtomValue } from "jotai";
import { useMemo } from "react";

import {
  BuyerProfileTypeEnum,
  type GovernmentAgency,
} from "../../../generated";
import {
  buyerProfileTypeState,
  governmentAgencyState,
} from "../../../jotai/user";
import type { LinkProps } from "../../../library/Link";
import {
  type ShowInviteTeamModalFn,
  useShowInviteTeamModal,
} from "../../../modals/InviteTeamModal";
import { TagVariants } from "../../../shared/Tag";
import { SUPPORT_EMAIL_ADDRESS } from "../../../utils/constants";
import { INVITE_TEAM_SOURCE } from "../../../utils/enums";

type QuickLink = {
  label: string;
  tag?: { variant: TagVariants; label: string };
} & Omit<
  LinkProps,
  "children" | "underline" | "emphasis" | "color" | "newWindow"
>;

type GetQuickLinksFn = (props: {
  governmentAgency: GovernmentAgency | null;
  showInviteTeamModal: ShowInviteTeamModalFn;
}) => QuickLink[];

const HOW_TO = {
  label: "How to use Pavilion",
  href: "/about/resources/pavilion-product-walkthrough",
  className: "analytics-quick-link-how-to",
};
const LEARN_MORE = {
  label: "Learn more about cooperative purchasing",
  href: "/about/resources/how-can-public-entities-share-contracts-a-cooperative-purchasing-overview",
  className: "analytics-quick-link-learn-more",
};
const CALCULATOR = {
  label: "Calculate procurement cost",
  href: "/calculator",
  className: "analytics-quick-link-calculator flex items-center",
  tag: { label: "New", variant: TagVariants.NEW_FEATURE_DARK },
};
const CALCULATOR_LEADERSHIP = {
  ...CALCULATOR,
  label: "Compare RFP vs. coop cost",
};
const UPLOAD_CONTRACTS = {
  label: "Upload contracts",
  href: "/entity-contracts/update",
  className: "analytics-quick-link-upload-contracts",
};
const CONFIGURE_ENTITY = {
  label: "Configure Pavilion for your entity",
  href: "/profile#team-management",
  className: "analytics-quick-link-configure-entity",
};
const PREFERENCES: QuickLink = {
  label: "Edit account preferences",
  href: "/profile",
  className: "analytics-quick-link-preferences",
};
const GET_HELP = {
  label: "Get help from our support team",
  href: `mailto:${SUPPORT_EMAIL_ADDRESS}`,
  className: "analytics-quick-link-get-help",
};

function getPurchaseGuide(governmentAgency: GovernmentAgency | null) {
  return governmentAgency?.purchasingGuide
    ? [
        {
          label: "Review your entity's purchasing guide",
          href: governmentAgency.purchasingGuide,
          className: "analytics-quick-link-purchase-guide",
        },
      ]
    : [];
}

function getInviteTeam(showInviteTeamModal: ShowInviteTeamModalFn) {
  return [
    {
      label: "Invite team members",
      className: "analytics-quick-link-invite",
      onClick: () =>
        showInviteTeamModal({ inviteSource: INVITE_TEAM_SOURCE.WELCOME_PAGE }),
    },
  ];
}

const getDefaultQuickLinks: GetQuickLinksFn = ({
  governmentAgency,
  showInviteTeamModal,
}) => {
  return [
    HOW_TO,
    ...getPurchaseGuide(governmentAgency),
    CALCULATOR,
    ...getInviteTeam(showInviteTeamModal),
    PREFERENCES,
    GET_HELP,
  ];
};

const QUICK_LINKS_BY_ROLE: Partial<
  Record<BuyerProfileTypeEnum, GetQuickLinksFn>
> = {
  [BuyerProfileTypeEnum.END_USER]: ({ governmentAgency }) => [
    HOW_TO,
    ...getPurchaseGuide(governmentAgency),
    LEARN_MORE,
    CALCULATOR,
    PREFERENCES,
    GET_HELP,
  ],
  [BuyerProfileTypeEnum.LEADERSHIP]: ({ showInviteTeamModal }) => [
    ...getInviteTeam(showInviteTeamModal),
    CALCULATOR_LEADERSHIP,
    UPLOAD_CONTRACTS,
    CONFIGURE_ENTITY,
    PREFERENCES,
    GET_HELP,
  ],
};

export default function useQuickLinks(): QuickLink[] {
  const buyerProfileType = useAtomValue(buyerProfileTypeState);
  const governmentAgency = useAtomValue(governmentAgencyState) || null;
  const showInviteTeamModal = useShowInviteTeamModal();

  return useMemo(() => {
    if (!buyerProfileType || !QUICK_LINKS_BY_ROLE[buyerProfileType]) {
      return getDefaultQuickLinks({ governmentAgency, showInviteTeamModal });
    }

    return QUICK_LINKS_BY_ROLE[buyerProfileType]({
      governmentAgency,
      showInviteTeamModal,
    });
  }, [buyerProfileType, governmentAgency, showInviteTeamModal]);
}
