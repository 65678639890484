import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import ProjectDetailPage from "../components/Project/ProjectDetailPage";
import type { ProjectDetailData } from "../components/Project/types";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface ProjectDetailProps extends AuthPageProps {
  data: ProjectDetailData;
}
export default function ProjectDetail({
  data,
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: ProjectDetailProps) {
  return (
    <Page
      searchSource="projectdetail-header-search-bar"
      isAuthenticated={is_authenticated}
      pageType={PageType.PROJECT}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <ProjectDetailPage data={data} />
    </Page>
  );
}

const container = getDOMAnchorById("project-detail-page");
if (_get(container, "dataset.projectDetail")) {
  const data = JSON.parse(
    (container as HTMLElement).dataset.projectDetail as string
  );
  const root = createRoot(container as HTMLElement);
  root.render(<ProjectDetail data={data} />);
}
