import { useSetAtom } from "jotai";
import { redirectOnComplete } from "../../components/Account/helpers";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { PopupType, accountModals } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/split";

import { sessionPopupState } from "../../jotai/page";
import CompleteSupplierAccountForm from "./CompleteSupplierAccountForm";
import RedesignedSignupBase, { SignupThemes } from "./RedesignedSignupBase";
import RedesignedSupplierSignupForm from "./RedesignedSignupBase/RedesignedSupplierSignupForm";
import { WindowType } from "./types";

interface SupplierSignupModalProps {
  hideModal: () => void;
  goBack?: () => void;
  supplierName?: string;
  handle?: string;
  isBlocking?: boolean;
}

export default function SupplierSignupModal({
  hideModal,
  supplierName = "",
  handle = "",
  isBlocking,
  goBack,
}: SupplierSignupModalProps) {
  const showSignupSurvey = useShowSignupSurvey();
  function onComplete(redirectUrl: string) {
    showSignupSurvey({ onComplete: () => redirectOnComplete(redirectUrl) });
  }
  const setSessionPopupState = useSetAtom(sessionPopupState);
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");

  if (hasRedesign) {
    const successMessage =
      "🎉 Account creation complete! You can now optimize your profile on Pavilion";

    return (
      <Modal
        hideModal={hideModal}
        modalSize={modalSizes.LARGE}
        className="analytics-signup-modal overflow-hidden"
        isBlocking={isBlocking}
        goBack={goBack}
        ignorePadding
      >
        <RedesignedSignupBase
          theme={SignupThemes.BUILDING}
          accountModal={accountModals.SUPPLIER_SIGN_UP}
          headline="Win more government business—for free"
          text={
            "10,000 public sector buyers find suppliers on Pavilion.\n\nGet in front of public agencies looking to buy and get leads that close, fast—all for free."
          }
        >
          <RedesignedSupplierSignupForm
            onComplete={(redirectUrl: string) => {
              hideModal();
              setSessionPopupState({
                analyticsClassName: "analytics-supplier-signup-success",
                name: PopupType.SUCCESS,
                children: successMessage,
                show: true,
              });
              redirectOnComplete(redirectUrl);
            }}
            parentWindow={WindowType.Modal}
          />
        </RedesignedSignupBase>
      </Modal>
    );
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      goBack={goBack}
      className="analytics-signup-modal inline-flex items-center"
      // Allow scrolling on smaller screens so user can select dropdown items
      contentClassName="no-scrollbar"
      title="Welcome to Pavilion!"
      subtitle="We just need a little bit more information before we can create your account. This allows us to give you a tailored experience on Pavilion."
      modalSize={modalSizes.SMALL}
      showLogoHeader={false}
    >
      <CompleteSupplierAccountForm
        onComplete={onComplete}
        supplierName={supplierName}
        handle={handle}
        hideModal={hideModal}
        isBlocking={isBlocking}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
