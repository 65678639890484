import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import clsx from "clsx";
import quoteTeam from "../../../img/team-icons/quote-team.png";
import { Badge } from "../../library";

export default function QuoteRequestRepsOnline({
  size = "md",
}: { size?: "sm" | "md" }) {
  return (
    <div className="flex flex-none flex-col items-center">
      <img
        className={clsx("object-scale-down", {
          "h-12": size === "sm",
          "h-16": size === "md",
        })}
        src={quoteTeam}
      />
      <Badge
        Icon={FiberManualRecordIcon}
        size={size === "md" ? "sm-md" : size}
        className="gap-1"
        iconClass="text-cp-limeade-700"
      >
        Pavilion reps online
      </Badge>
    </div>
  );
}
