import clsx from "clsx";
import noProjects from "../../../../img/icons/noProjects.png";
import { Typography } from "../../../library";
import { bgColorClass, borderColorClass } from "../../../utils/colors";
import CreateProjectButton from "../../Project/CreateProjectButton";
import { ProjectCreationSource } from "../../Project/types";

export default function NoProjectsPlaceholder() {
  return (
    <div
      className={clsx(
        "pt-6 px-6 border border-solid rounded-2xl text-center flex flex-col gap-4 items-center",
        borderColorClass.neutral.subtle.enabled,
        bgColorClass.neutral.subtlest.enabled
      )}
    >
      <Typography color="neutral.bold.enabled">
        Create a project to save your contract research, collaborate with
        colleagues, and more.
      </Typography>
      <div className="flex flex-col sm:flex-row items-center gap-4 mb-4 sm:mb-0">
        <img
          className="max-w-[15rem] md:max-w-[26rem]"
          src={noProjects}
          alt="contracts folder"
        />
        <CreateProjectButton
          source={ProjectCreationSource.BUYER_PROFILE_PAGE}
        />
      </div>
    </div>
  );
}
