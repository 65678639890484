import type { SupplierDiversityCert } from "../../generated";
import useRequestID from "../../hooks/useRequestID";
import { Link, Typography } from "../../library";
import { expirationText } from "../../shared/ContractBase";
import { isFeatureEnabled } from "../../utils/split";
import { trackViewSupplierDiversityCredentials } from "../../utils/tracking";

interface DiversityCertificationsProps {
  diversityCertifications: SupplierDiversityCert[];
  contractId?: Maybe<string>;
  supplierId: number;
  handle: Maybe<string>;
}

interface CertificationMetadataSection {
  certificationNumber: Maybe<string>;
  expiration: Maybe<string>;
}

export default function DiversityCertifications({
  diversityCertifications,
  contractId,
  handle,
  supplierId,
}: DiversityCertificationsProps) {
  const requestID = useRequestID();

  if (!diversityCertifications?.length) return null;

  return (
    <div className="flex flex-col">
      <Typography
        variant="headline"
        size="xs"
        emphasis
        color="brand.default.secondary.enabled"
        className="mb-4"
      >
        Diversity credentials
      </Typography>
      {diversityCertifications.map((cert) => (
        <DiversityCertification
          key={`${cert.number}-${cert.id}`}
          {...cert}
          onClick={() => {
            trackViewSupplierDiversityCredentials({
              destinationUrl: cert.sourceUrl,
              diversityCertificationId: cert.id,
              supplierId,
              supplierHandle: handle,
              requestId: requestID,
              contractId,
            });
          }}
        />
      ))}
    </div>
  );
}

export function DiversityCertification({
  sourceUrl,
  name,
  entityName,
  number,
  expiration,
  onClick,
}: SupplierDiversityCert & { onClick: () => void }) {
  return (
    <div>
      <div className="inline-flex">
        {sourceUrl ? (
          <Link href={sourceUrl} emphasis={false} onClick={onClick}>
            {name}
          </Link>
        ) : (
          <Typography>{name}</Typography>
        )}
        {entityName && (
          <Typography color="neutral.bolder.enabled">
            &nbsp;from {entityName}
          </Typography>
        )}
      </div>
      <CertificationMetadataSection
        certificationNumber={number}
        expiration={expiration}
      />
    </div>
  );
}

function CertificationMetadataSection({
  certificationNumber,
  expiration,
}: CertificationMetadataSection) {
  if (!expiration && !certificationNumber) return null;
  const showNewStyle = isFeatureEnabled("scannableSupplierProfile");

  const formatType = showNewStyle ? "M/d/yyyy" : undefined;

  const formattedCertification = certificationNumber
    ? `Cert ID ${certificationNumber}`
    : "";

  const text = [
    formattedCertification,
    expirationText(expiration, { formatType, showUnknown: true }),
  ]
    .filter(Boolean)
    .join(" · ");
  if (!text) return null;

  if (showNewStyle) {
    return (
      <Typography variant="meta" color="neutral.bold.enabled">
        {text}
      </Typography>
    );
  }
  return (
    <Typography
      size="sm"
      color="neutral.default.secondary.enabled"
      className="py-2"
    >
      {text}
    </Typography>
  );
}
