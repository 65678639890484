import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useMemo } from "react";
import type { ContractSupplierHit, RelevantContract } from "../../generated";
import { useContractMatchesFilters } from "../../hooks/search/useContractMatchesFilters";
import { useMatchingSource } from "../../hooks/search/useMatchingSource";
import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import useIsDebug from "../../hooks/useIsDebug";
import useLoginWall from "../../hooks/useLoginWall";
import useTrackContractClick from "../../hooks/useTrackContractClick";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import useTrackSupplierClick from "../../hooks/useTrackSupplierClick";
import {
  requestIDState,
  useAddRecentSuccessfulSearch,
} from "../../jotai/search";
import {
  contractSourceFilterState,
  expirationFilterState,
} from "../../jotai/searchFilters";
import {
  buyerProfileState,
  profileTypeState,
  userZipState,
} from "../../jotai/user";
import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  ButtonSizes,
  ButtonThemes,
  ClampedText,
  Link,
  LogoSizes,
  Typography,
} from "../../library";
import { GenericCardTheme } from "../../library/GenericCard";
import {
  BorderedCardSection,
  getContractUrl,
  getTags,
} from "../../shared/ContractBase";
import Tag, { TagVariants } from "../../shared/Tag";
import { goToURL } from "../../utils";
import { borderColorClass, iconColorClass } from "../../utils/colors";
import { CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW } from "../../utils/constants";
import { parseDate } from "../../utils/date";
import {
  LoginWallTriggers,
  ProfileType,
  ViewContractRankCTA,
  pageNavigationSourceTypes,
  resultTypes,
} from "../../utils/enums";
import { getMessageSupplierUrl, getSupplierUrlPath } from "../../utils/format";
import { MessageSupplierSource } from "../MessageSupplierPage/types";
import { SupplierProfileAnchorLink } from "../supplier/SupplierProfile/SupplierProfile";
import { MinimalContractCard } from "./MinimalContractCard";
import ProductSection from "./ProductSection";
import {
  getSupplierOfferingsFromHit,
  matchesForKey,
  matchesFromList,
  styleSearchResponseText,
} from "./utils";

type TrackingFnData = Pick<
  RelevantContract,
  | "docid"
  | "RankingInfo"
  | "matchTier"
  | "buyerLeadAgencyId"
  | "buyerLeadAgency"
  | "cooperativeAffiliation"
> & {
  tagCopyList: string[];
  tagVariantList: string[];
  ctaType: ViewContractRankCTA;
};

function ContractResult({
  result,
  query,
  theme,
  className,
  trackContractClick,
  trackSerpClick,
}: {
  result: RelevantContract;
  query: string;
  theme: GenericCardTheme;
  className?: string;
  trackContractClick: (data: TrackingFnData) => void;
  trackSerpClick: (data: TrackingFnData) => void;
}) {
  const buyerProfile = useAtomValue(buyerProfileState);
  const userZip = useAtomValue(userZipState);
  const isDebug = useIsDebug();
  const expirationDate = parseDate(result.expirationTimestamp);
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const expirationFilter = useAtomValue(expirationFilterState);
  const sourcesFilter = useAtomValue(contractSourceFilterState);
  const matchingSource = useMatchingSource();
  const requestID = useAtomValue(requestIDState);

  const { contractTagElements, tagVariantList, tagCopyList } = getTags({
    contractTagData: result.contractTags,
    expiration_ts: result.expirationTimestamp,
    expiration_date: expirationDate,
    isCooperative: result.cooperativeLanguage ?? false,
    matchTier: result.matchTier,
    blaState: result.buyerLeadAgencyState,
    blaType: result.buyerLeadAgencyType,
    blaRank: result.buyerLeadAgencyRank,
    filterScore: result.RankingInfo.filters,
    semanticScore: result.RankingInfo.semanticScore,
    proBoost: result.RankingInfo.proBoost,
    geoBoost: result.RankingInfo.geoBoost,
    scaledBoost: result.RankingInfo.scaledBoost,
    productBoost: result.RankingInfo.productBoost,
    contractQualityBoost: result.RankingInfo.contractQualityBoost,
    localSupplierBoost: result.RankingInfo.localSupplierBoost,
    supplierConfirmedStatesServedBoost:
      result.RankingInfo.supplierConfirmedStatesServedBoost,
    supplierConfirmedAgencyTypesServedBoost:
      result.RankingInfo.supplierConfirmedAgencyTypesServedBoost,
    supplierResponsivenessBoost: result.RankingInfo.supplierResponsivenessBoost,
    isOwnBla:
      !!buyerProfile.governmentAgency &&
      result.buyerLeadAgencyId === buyerProfile.governmentAgency.id,
    isDebug,
    transparent: true,
    size: "md",
  });

  const data: TrackingFnData = {
    ...result,
    tagVariantList,
    tagCopyList,
    ctaType: ViewContractRankCTA.VIEW_CONTRACT,
  };

  const source = matchingSource(result);

  function handleClick() {
    const offeringMatches = matchesFromList({
      matchResults: result.offerings,
      key: "contractOfferings",
    });
    const autoExtractedOfferingsMatches = matchesForKey(
      result.HighlightResult,
      "autoExtractedOfferingsList"
    );

    addRecentSuccessfulSearch(query);
    trackContractClick(data);
    goToURL(
      getContractUrl(
        result.solicitationId,
        result.docid,
        query,
        userZip,
        autoExtractedOfferingsMatches.length > 0,
        offeringMatches.length > 0,
        expirationFilter,
        sourcesFilter,
        pageNavigationSourceTypes.SEARCH as string,
        requestID
      )
    );
  }

  const titleTooltip =
    isDebug && result.RankingInfo.semanticContext
      ? result.RankingInfo.semanticContext
      : "";

  return (
    <MinimalContractCard
      docId={result.docid}
      title={result.title}
      titleTooltip={titleTooltip}
      source={source}
      contractTagElements={contractTagElements}
      theme={theme}
      className={className}
      loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
      ctaAnalyticsClass="analytics-serp-view-contract"
      dataTestId="serp-supplier-result-contract-card"
      onClick={handleClick}
      trackSerpClick={() => trackSerpClick(data)}
      hit={result}
    />
  );
}

export default function SearchCardSupplierResult({
  hit,
  diversityPreferences = [],
  query,
  visibleRank,
  primary,
  requestID,
}: {
  hit: ContractSupplierHit;
  diversityPreferences?: Maybe<string>[];
  query: string;
  visibleRank: number;
  primary: boolean;
  requestID: Maybe<string>;
}) {
  const trackContractClick = useTrackContractClick();
  const trackSupplierClick = useTrackSupplierClick();
  const trackSerpClick = useTrackSerpClick();
  const userZip = useAtomValue(userZipState);
  const profileType = useAtomValue(profileTypeState);
  const requireLogin = useLoginWall();
  const contractMatchesFilters = useContractMatchesFilters();
  const supplierHasDiversityOverlap =
    hit.supplierDiversityCertificationIds?.some((diversityId) =>
      diversityPreferences.includes(diversityId)
    );
  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId: hit.supplierId,
    supplierName: hit.supplierDisplayName,
  });

  const { supplierTagElements, tagVariantList, tagCopyList } = getTags({
    supplierTagData: hit.supplierTags || [],
    matchesDiversity: supplierHasDiversityOverlap,
    transparent: true,
    size: "md",
  });

  const getTrackingData = (data: TrackingFnData) => ({
    contractId: data.docid,
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    semanticScore: data.RankingInfo.semanticScore,
    absoluteDepth: hit.rank,
    relativeDepth: visibleRank,
    matchTier: data.matchTier,
    buyerLeadAgencyId: data.buyerLeadAgencyId,
    buyerLeadAgency: data.buyerLeadAgency,
    cooperativeAffiliation: data.cooperativeAffiliation,
    filterScore: data.RankingInfo.filters,
    groupingType: hit.groupingType,
    requestID,
    searchQuery: query,
    displayTag: data.tagVariantList,
    displayTagCopy: data.tagCopyList,
    ctaType: data.ctaType,
  });

  const onSupplierClick = (hash?: Maybe<string>) => {
    const data = {
      ...getTrackingData({
        ...hit.relevantSuppliers[0],
        tagVariantList,
        tagCopyList,
        ctaType: ViewContractRankCTA.SUPPLIER_PROFILE,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      resultType: resultTypes.SUPPLIER_RESULT,
      hasSimilarEntities: false,
      numEntitiesInState: 0,
      numEntitiesInCategory: 0,
    };
    trackSerpClick(data);
    requireLogin({
      triggerId: hit.supplierHandle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_SUPPLIER_CLICK,
      onComplete: () => {
        trackSupplierClick(data);
        goToURL(
          getSupplierUrlPath(
            hit.supplierHandle,
            query,
            userZip,
            pageNavigationSourceTypes.SEARCH,
            requestID,
            hash
          )
        );
      },
    });
  };

  const onContactSupplierClick = (handle: string) => {
    const data = {
      ...getTrackingData({
        ...hit.relevantSuppliers[0],
        tagVariantList,
        tagCopyList,
        ctaType: ViewContractRankCTA.MESSAGE_SUPPLIER,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      resultType: resultTypes.SUPPLIER_RESULT,
    };
    trackSerpClick(data);
    requireLogin({
      triggerId: handle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK,
      onComplete: () => {
        trackContractClick(data);
        goToURL(
          getMessageSupplierUrl({
            handle,
            query,
            zip: userZip,
            requestID: requestID || "",
            messageSupplierSource: MessageSupplierSource.SUPPLIER_SERP,
          })
        );
      },
    });
  };

  const supplierOfferings = useMemo(() => {
    return getSupplierOfferingsFromHit(hit);
  }, [hit]);

  return (
    <div
      className={clsx(
        "rounded-6 border border-solid p-4 grid gap-4 max-w-[49.5rem] bg-white",
        borderColorClass.neutral.subtle.enabled
      )}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-row gap-3">
          {hit.supplierLogoUrl ? (
            <Avatar
              size={AvatarSizes.LARGE}
              logoImageSrc={hit.supplierLogoUrl}
              logoSize={LogoSizes.XS}
            />
          ) : (
            <Avatar
              size={AvatarSizes.LARGE}
              variant={AvatarVariant.CIRCLE}
              IconComponent={StorefrontOutlinedIcon}
              textColor="brand.subtle.enabled"
              iconClassName={iconColorClass.brand.subtle.enabled}
              bgColor="brand.subtlest.enabled"
            />
          )}
          <div className="grid gap-1">
            <div className="flex flex-wrap gap-x-2 items-center">
              <Link
                onClick={() => onSupplierClick()}
                variant="headline"
                size="sm"
                emphasis
                color="brand.boldest.enabled"
                underline={false}
                className="hover:underline"
              >
                {hit.supplierDisplayName}
              </Link>
              {exactSupplierMatchAlias && (
                <Typography size="sm" color="subtler">
                  Also known as {exactSupplierMatchAlias}
                </Typography>
              )}
            </div>
            <Link
              onClick={() =>
                onSupplierClick(SupplierProfileAnchorLink.CONTRACT_LIST)
              }
              size="sm"
              color="neutral.bold.enabled"
              className="hover:underline"
              underline={false}
              emphasis={false}
            >
              {hit.activeShareableContractCount} active{" "}
              {pluralize("contract", hit.activeShareableContractCount)}
            </Link>
          </div>
        </div>
        {profileType !== ProfileType.SUPPLIER && (
          <Button
            className="h-fit flex-none analytics-serp-contact-supplier"
            theme={ButtonThemes.SECONDARY_DARK}
            size={ButtonSizes.SMALL}
            onClick={() => {
              onContactSupplierClick(hit.supplierHandle);
            }}
          >
            Request quote
          </Button>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 empty:hidden">
        {supplierTagElements}
      </div>
      <div className="grid gap-2 empty:hidden">
        {(hit.supplierSummary || hit.supplierAbout) &&
          (hit.supplierSummary ? (
            <Typography size="sm" color="neutral.boldest.enabled">
              {hit.supplierSummary}
            </Typography>
          ) : (
            <ClampedText
              size="sm"
              color="neutral.boldest.enabled"
              clampColor="neutral.boldest.enabled"
              inline
            >
              {hit.supplierAbout}
            </ClampedText>
          ))}
        {supplierOfferings.length > 0 && (
          <div className="flex flex-wrap gap-2 max-h-[26px] overflow-hidden empty:hidden">
            {supplierOfferings.map(({ value }, ix) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: This list is static so index is ok.
              <Tag key={ix} variant={TagVariants.SUBTLE} size="sm">
                <p>
                  {styleSearchResponseText(value, {
                    sentenceCase: true,
                    highlightClassName: "font-semibold",
                  })}
                </p>
              </Tag>
            ))}
          </div>
        )}
        <ProductSection products={hit.productHits || []} />
        <BorderedCardSection sectionClass="p-1">
          {hit.relevantSuppliers
            .filter((result) => !primary || contractMatchesFilters(result))
            .slice(0, CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW)
            .map((result) => (
              <ContractResult
                key={result.docid}
                result={result}
                query={query}
                trackSerpClick={(data) => trackSerpClick(getTrackingData(data))}
                trackContractClick={(data) =>
                  trackContractClick(getTrackingData(data))
                }
                theme={GenericCardTheme.BORDERLESS_UNPADDED}
              />
            ))}
        </BorderedCardSection>
      </div>
    </div>
  );
}
