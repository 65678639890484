import { createRoot } from "react-dom/client";

import CompleteSupplierSignupPage from "../../components/Account/CompleteSupplierSignup";
import type { InferredSupplier } from "../../modals/SignupSteps/types";
import { getDOMAnchorById } from "../../utils";
import { PageType } from "../../utils/enums";
import Page from "../Page/Page";

interface CompleteSupplierSignupProps {
  email: string;
  inferred_supplier: InferredSupplier;
  isSSR: boolean;
}
export default function CompleteSupplierSignup({
  email,
  inferred_supplier,
  isSSR,
}: CompleteSupplierSignupProps) {
  return (
    <Page
      pageId=""
      profileType={null}
      activeAgreements={null}
      pageType={PageType.ACCOUNT}
      showHeader={false}
      isAuthenticated={false}
      isSSR={isSSR}
    >
      <CompleteSupplierSignupPage
        sessionEmail={email}
        inferredSupplier={inferred_supplier}
      />
    </Page>
  );
}

const container = getDOMAnchorById("complete-supplier-signup-container");
if (container) {
  const props = JSON.parse(container.dataset.signupData || "");
  const root = createRoot(container);
  root.render(<CompleteSupplierSignup {...props} />);
}
