import { useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import useLoginWall from "../../../hooks/useLoginWall";

import { useAddActiveTooltip } from "../../../jotai/page";
import {
  isAuthenticatedState,
  userInitializedState,
  userStateState,
  userZipState,
} from "../../../jotai/user";
import { Button, FeatureHighlightTooltip } from "../../../library";
import { ArrowPosition } from "../../../library/FeatureHighlightTooltip/FloatingTooltip";
import { getParam, getRequestID } from "../../../utils";
import {
  LoginWallTriggers,
  SupplierProfileContactSource,
} from "../../../utils/enums";
import {
  getMessageSupplierOnSolicitationUrl,
  getMessageSupplierUrl,
} from "../../../utils/format";
import type { TrackContactSupplierClickFn } from "../SupplierProfile/types";

const TOOLTIP_DETAILS = {
  contactSuppliers: {
    tagText: "Tip",
    title: "Contact this supplier directly",
    body: "Email suppliers directly from Pavilion and get their replies in your inbox. We’ll make sure you reach the best contact and follow up to get you a fast response.",
  },
  supplierHasVerifiedContact: {
    tagText: "New",
    title: "This contact is verified",
    body: "This contact information has been recently verified as active.",
  },
};

function MessageSupplierCTA({
  contractId,
  handle,
  solicitationId,
  supplierName,
  hasContractCatalog,
  trackClick,
}: {
  contractId?: string;
  handle: string;
  solicitationId?: Maybe<string>;
  supplierName: string;
  hasContractCatalog?: boolean;
  trackClick: TrackContactSupplierClickFn;
}) {
  const requireLogin = useLoginWall();
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const ctaText = !isAuthenticated
    ? `Sign in to email ${supplierName}`
    : `Email ${supplierName}`;

  const userZip = useAtomValue(userZipState);

  const onClick = () => {
    requireLogin({
      triggerId: handle,
      onComplete: async () => {
        trackClick({ source: SupplierProfileContactSource.CONTACT_CTA });

        let url: URL;
        const query = getParam("query", undefined);
        const requestID = getRequestID();
        if (solicitationId && contractId) {
          url = getMessageSupplierOnSolicitationUrl({
            solicitationId,
            contractId,
            zip: userZip,
            query,
            requestID,
          });
        } else {
          url = getMessageSupplierUrl({ handle, query, zip: null, requestID });
        }
        window.open(url, "_blank");
      },
      triggerType: LoginWallTriggers.MESSAGE_SUPPLIER_CLICK,
    });
  };

  return (
    <Button
      className="w-full"
      theme={
        hasContractCatalog
          ? Button.themes.SECONDARY_DARK
          : Button.themes.PRIMARY_DARK
      }
      size={hasContractCatalog ? Button.sizes.SMALL : Button.sizes.LARGE}
      onClick={onClick}
      dataTestId="message-supplier-cta"
    >
      {ctaText}
    </Button>
  );
}

export interface SupplierCTAProps {
  handle: string;
  name: string;
  solicitationId?: Maybe<string>;
  contractId?: string;
  hasConfirmedContact?: boolean;
  hasContractCatalog?: boolean;
  trackClick: TrackContactSupplierClickFn;
}

export default function SupplierCTA({
  handle,
  name,
  solicitationId,
  contractId,
  hasConfirmedContact,
  hasContractCatalog,
  trackClick,
}: SupplierCTAProps) {
  const isInitialized = useAtomValue(userInitializedState);
  const userState = useAtomValue(userStateState);
  const addActiveTooltip = useAddActiveTooltip();
  const tooltipKey = getTooltipKey();
  const ref = useRef<HTMLDivElement>(null);
  // Returns undefined or 0 if the element is not visible
  const isVisible = ref.current?.clientHeight;

  useEffect(() => {
    // For responsiveness, the parent `SupplierCTAContainer` is rendered in two places
    // on each page, but only one is shown (depending on the window size breakpoint).
    // We should only deal with tooltips when this component is actually visible.
    if (tooltipKey && isVisible) {
      addActiveTooltip(tooltipKey);
    }
  }, [isVisible, tooltipKey, addActiveTooltip]);

  function getTooltipKey() {
    if (isInitialized) {
      if (!userState.hasContactedSupplier) {
        return "contactSuppliers";
      }
      if (hasConfirmedContact) {
        return "supplierHasVerifiedContact";
      }
    }
    return null;
  }

  const component = (
    <MessageSupplierCTA
      supplierName={name}
      trackClick={trackClick}
      handle={handle}
      contractId={contractId}
      solicitationId={solicitationId}
      hasContractCatalog={hasContractCatalog}
    />
  );
  return (
    <div ref={ref}>
      {/* Only render the tooltip when this component is visible
       * to avoid sending the "feature-tooltip-shown" event twice. */}
      {tooltipKey && isVisible ? (
        <FeatureHighlightTooltip
          tooltipKey={tooltipKey}
          userStateKey="hasDismissedContactSuppliersTooltip"
          className="hidden md:grid z-2"
          placement="left"
          arrowPosition={ArrowPosition.CENTER}
          title={TOOLTIP_DETAILS[tooltipKey].title}
          tagText={TOOLTIP_DETAILS[tooltipKey].tagText}
          body={TOOLTIP_DETAILS[tooltipKey].body}
        >
          {component}
        </FeatureHighlightTooltip>
      ) : (
        component
      )}
    </div>
  );
}
