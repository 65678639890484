import { createRoot } from "react-dom/client";

import DocumentExamplesPage from "../components/DocumentExamplesPage";
import { PageSection } from "../library";
import { getDOMAnchorById } from "../utils";

import Page, { type AuthPageProps } from "./Page/Page";

export default function DocumentExamples({
  context,
  is_authenticated,
  profile_type,
  active_agreements,
  isSSR,
}: AuthPageProps) {
  return (
    <Page
      pageId=""
      showHeader={false}
      searchSource="document-examples-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <PageSection className="pt-9 pb-22">
        <DocumentExamplesPage />
      </PageSection>
    </Page>
  );
}

const container = getDOMAnchorById("document-examples");
if (container) {
  const root = createRoot(container);
  root.render(<DocumentExamples />);
}
