import clsx from "clsx";
import { useState } from "react";

import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Tooltip,
  Typography,
} from "../../library";
import {
  LabeledInputVariants,
  VARIANTS,
} from "../../library/Input/LabeledInput";
import {
  INPUT_STYLE,
  SHARED_INPUT_BORDER_STYLE,
  borderClassName,
} from "../../library/Input/constants";
import { copyTextToClipboard } from "../../shared/CopyButton";

interface CopyLinkInputProps {
  link: string;
  message?: string;
  trackCopyLink?: () => void;
}

export default function CopyLinkInput({
  link,
  message,
  trackCopyLink,
}: CopyLinkInputProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    copyTextToClipboard(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
    trackCopyLink?.();
  };

  return (
    <Tooltip
      className="rounded"
      info={copied ? "Copied!" : "Copy to clipboard"}
      placement="top"
    >
      <div className="w-full flex flex-col gap-2">
        <div
          className={clsx(
            "w-full cursor-pointer",
            SHARED_INPUT_BORDER_STYLE,
            VARIANTS[LabeledInputVariants.DEFAULT].containerClass
          )}
          onClick={handleCopy}
        >
          <div
            className={clsx(
              borderClassName(LabeledInputVariants.DEFAULT),
              INPUT_STYLE
            )}
          >
            <div className="w-full flex justify-between items-center gap-2">
              <Typography className="flex-1 truncate">{link}</Typography>
              <Button
                theme={ButtonThemes.TERTIARY_DARK}
                size={ButtonSizes.SMALL}
                className="h-8 self-end whitespace-nowrap"
              >
                Copy link
              </Button>
            </div>
          </div>
        </div>
        {message && (
          <Typography
            color="neutral.default.tertiary.enabled"
            variant="meta"
            size="sm"
          >
            {message}
          </Typography>
        )}
      </div>
    </Tooltip>
  );
}
