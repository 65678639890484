import clsx from "clsx";

import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { type SocialProvider, SocialProviders } from "../../utils/social";

import { accountModals } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/split";
import PushToLoginForm from "./PushToLoginForm";
import RedesignedSignupBase, { SignupThemes } from "./RedesignedSignupBase";
import { WindowType } from "./types";

interface PushToLoginModalProp {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  socialLoginSource: SocialProvider | string;
}

export default function PushToLoginModal({
  hideModal,
  onComplete,
  isBlocking = false,
  goBack,
  socialLoginSource,
}: PushToLoginModalProp) {
  const isSocialLogin = SocialProviders.includes(
    socialLoginSource as SocialProvider
  );
  const analyticsClassName = clsx("account", {
    "analytics-push-to-sociallogin-modal": isSocialLogin,
    "analytics-push-to-login-modal": !isSocialLogin,
  });
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");
  if (hasRedesign) {
    return (
      <Modal
        hideModal={hideModal}
        isBlocking={isBlocking}
        className="analytics-login-modal account overflow-hidden"
        contentClassName="no-scrollbar"
        formID="analytics-login-modal-submit"
        modalSize={modalSizes.LARGE}
        ignorePadding
      >
        <RedesignedSignupBase
          theme={SignupThemes.BLANK}
          headline="Welcome back to Pavilion!"
          bullets={[
            "100,000+ contracts from Texas sources, Sourcewell, OMNIA, NASPO ValuePoint, and more.",
            "Responsive suppliers and easy quotes",
            "24/7 personal support",
          ]}
          accountModal={accountModals.PUSH_TO_LOGIN}
        >
          <PushToLoginForm
            onComplete={onComplete}
            socialLoginSource={socialLoginSource}
            parentWindow={WindowType.Modal}
            hasRedesign={hasRedesign}
          />
        </RedesignedSignupBase>
      </Modal>
    );
  }
  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className={analyticsClassName}
      formID="analytics-push-to-login-modal-submit"
      modalSize={modalSizes.SMALL}
      showLogoHeader={false}
      title="Welcome back!"
      goBack={goBack}
    >
      <PushToLoginForm
        onComplete={onComplete}
        socialLoginSource={socialLoginSource}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
