import { useField } from "formik";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import {
  complexPurchaseCategoriesAtom,
  complexPurchaseThresholdAtom,
} from "../../jotai/costCalculator";
import type { CostCalculatorFormValues } from "./constants";

export default function useIsComplexPurchase() {
  const [purchaseCategory] = useField("purchaseCategory");
  const [purchaseRequiresApproval] = useField("purchaseRequiresApproval");
  const [purchaseCost] = useField("purchaseCost");
  const cb = useIsComplexPurchaseCallback();
  return cb({
    purchaseCategory: purchaseCategory.value,
    purchaseRequiresApproval: purchaseRequiresApproval.value,
    purchaseCost: purchaseCost.value,
  });
}

export function useIsComplexPurchaseCallback() {
  const threshold = useAtomValue(complexPurchaseThresholdAtom);
  const categories = useAtomValue(complexPurchaseCategoriesAtom);

  return useCallback(
    ({
      purchaseCategory,
      purchaseRequiresApproval,
      purchaseCost,
    }: Pick<
      CostCalculatorFormValues,
      "purchaseCategory" | "purchaseRequiresApproval" | "purchaseCost"
    >) => {
      return (
        categories.includes(purchaseCategory) ||
        purchaseRequiresApproval ||
        purchaseCost >= threshold
      );
    },
    [threshold, categories]
  );
}
